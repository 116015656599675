import { makeStyles } from "@material-ui/core";

const MuiStylesCustomCardHeader = makeStyles((theme) => ({
	avatar: {
		height: "120px",
		width: "120px",
		alignSelf:"center",
	},
	header: {
		display: "flex",
		justifyContent: "end",
		
	},
	title: {
		color: "#474747",
		fontSize: "19px",
		textAlign: "center",
		marginTop:"10px"
	},
	titleWithoutBackground: {
		color: "#474747",
	},
	iconButton: {
		backgroundColor: "#ffffff",
	},
	iconButtonSvg: {
		fill: "#630094",
	},
}));

export default MuiStylesCustomCardHeader;
