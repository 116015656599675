import Constants from "~/helpers/enums/Constants";

const initialState = {
	recipient_id: null,
	services: [],
};

const scheduleReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.SAVE_SCHEDULE_DATA:
			return {
				...state,
				...action.payload,
			};
		case Constants.CLEAR_SCHEDULE_DATA:
			return {
				...state,
				...initialState,
			};
		default:
			return state;
	}
};

export default scheduleReducer;
