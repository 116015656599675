import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { Typography, Box, Card, IconButton, TextField, Button, InputAdornment, Snackbar } from "@material-ui/core";
import MuiStylesIndicationsPage from "~/components/indicationsPage/MuiStylesIndicationsPage";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Footer from "~/components/common/footerContainer/FooterContainer";

import { getMyEarnings } from "~/services/referearnService";
import { Formik, Form, Field } from "formik";
import { getAllBankList, getBankAccount, postBankAccountData } from "~/services/bankAccountService";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Autocomplete } from "@material-ui/lab";
import { me } from "~/services/checkoutServices";
import { postGenerateTransfer } from "~/services/referearnService";
import { useSelector } from "react-redux";
import ErrorDialog from "~/components/common/dialog";
import { useTranslation } from "react-i18next";

const YourEarnings = (props) => {
	const classes = MuiStylesIndicationsPage();

	const { t } = useTranslation();

	const [bankAccount, setBankAccount] = useState();
	const [bankList, setBankList] = useState();
	const [user, setUser] = useState();

	const handleBackClick = () => {
		props.history.push("/indicacoes");
	};

	const initialFormValues = {
		bankId: bankAccount?.bank?.bank_id || "",
		agencyNumber: bankAccount?.agency || "",
		agencyDigit: bankAccount?.agency_digit || "",
		accountNumber: bankAccount?.account || "",
		accountDigit: bankAccount?.account_digit || "",
		accountType: bankAccount?.account_type || "",
		legalNumber: bankAccount?.legal_number || "",
		accountHolderName: bankAccount?.name || "",
	};

	const [editMode, setEditMode] = useState(false);
	const [earningsValues, setEarningsValues] = useState();
	const [loading, setLoading] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [openError, setOpenError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const { business } = useSelector((store) => store.business);

	const handleEditClick = () => {
		setEditMode(true);
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);

			const userId = localStorage.getItem("@prit-web/userId");

			const promises = [me(), getAllBankList(), getBankAccount(userId), getMyEarnings(userId)];

			try {
				const [meResponse, bankListResponse, bankAccountResponse, earningsResponse] = await Promise.all(promises);

				setBankList(bankListResponse.data);
				setBankAccount(bankAccountResponse.data);
				setEarningsValues(earningsResponse.data);
				setUser(meResponse.data);
			} catch (error) {
				console.error("Erro", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	const handleGenerateTransfer = async () => {
		const hash = props?.location?.state?.hash;
		try {
			await postGenerateTransfer(hash, business.id);
		} catch (err) {
			setOpenError(!openError);
			setErrorMessage(err.response.data.message);
		}
	};

	const handleSubmit = async (values, actions) => {
		try {
			const partners = {
				name: values.accountHolderName,
				document_number: values.legalNumber,
				email: user.email,
			};

			const updatedBankAccountData = {
				bank_id: values.bankId,

				agency: values.agencyNumber,
				agency_digit: values.agencyDigit,
				account: values.accountNumber,
				email: user.email,
				recipient_id: user.id,

				account_digit: values.accountDigit,
				account_type: values.accountType,
				legal_number: values.legalNumber,
				name: values.accountHolderName,
				phone: user.phone,
				company_name: values.accountHolderName,
				is_user_consumer: true,
				managing_partners: [partners],
			};

			delete updatedBankAccountData.bank;
			delete updatedBankAccountData.corporate_name;
			delete updatedBankAccountData.type;

			setLoading(true);

			await postBankAccountData(updatedBankAccountData);
			setSnackbarMessage("Dados enviados com sucesso!");
			setSnackbarOpen(true);
			setLoading(false);

			setTimeout(() => {
				props.history.push("/indicacoes");
			}, 2000);
		} catch (error) {
			let errorMessage = "Ocorreu um erro ao enviar os dados. Por favor revise-os";

			// if (error.response) {
			// 	errorMessage = error.response.data.message;
			// }

			setLoading(false);

			setSnackbarMessage(errorMessage);
			setSnackbarOpen(true);
		}
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarOpen(false);
	};

	const footer = () => {
		return <Footer section="indicacoes" />;
	};

	if (editMode) {
		return (
			<TitleContainer loading={loading} headerStyle={{ boxShadow: "0", padding: "0" }} footer={footer()}>
				<IconButton onClick={handleBackClick} style={{ marginTop: "0rem", padding: "0", color: "#000" }}>
					<ArrowBackIosIcon />
				</IconButton>
				<Box style={{ marginTop: "24px", padding: "12px", textAlign: "left", color: "#000" }}>
					<Formik initialValues={initialFormValues} onSubmit={handleSubmit}>
						{({ setFieldValue, values }) => (
							<Form>
								<Typography variant="p" style={{ fontWeight: "normal", fontSize: "1.1rem" }}>
									{t("Bank data")}
								</Typography>
								<Autocomplete
									options={bankList}
									getOptionLabel={(option) => option.description}
									value={bankList.find((bank) => bank.bank_id === values.bankId) || null}
									onChange={(event, value) => {
										setFieldValue("bankId", value ? value.bank_id : "");
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											label={
												<span style={{ color: "black", fontWeight: "normal", fontSize: "1.1rem" }}>Nome do Banco</span>
											}
											margin="normal"
											InputProps={{
												...params.InputProps,
												style: { color: "#B6B6B6" },
											}}
										/>
									)}
								/>
								<Box display="flex" justifyContent="space-between" marginBottom="normal">
									<Box flex="1" marginRight="10px">
										<Field
											as={TextField}
											name="agencyNumber"
											className={classes.digitBeforeInput}
											InputProps={{
												style: { width: "108px", color: " #B6B6B6" },
											}}
											label={
												<span style={{ color: "black", fontWeight: "normal", minWidth: "180px" }}>
													{t("Angency number")}
												</span>
											}
										/>

										<Field
											as={TextField}
											name="agencyDigit"
											className={classes.digitInput}
											style={{ width: "20px", marginLeft: "12px", textAlign: "center" }}
											InputProps={{
												style: { color: " #B6B6B6" },
											}}
											label={<span style={{ color: "black", fontWeight: "normal", width: "20px" }}></span>}
										/>
									</Box>
									<Box flex="1" marginRight="10px">
										<Field
											as={TextField}
											name="accountNumber"
											className={classes.digitBeforeInput}
											InputProps={{
												style: { width: "108px", color: "grey" },
											}}
											label={
												<span style={{ color: "black", fontWeight: "normal", minWidth: "180px" }}>Número da Conta</span>
											}
										/>

										<Field
											as={TextField}
											name="accountDigit"
											className={classes.digitInput}
											style={{ width: "20px", marginLeft: "12px", textAlign: "center" }}
											InputProps={{
												style: { color: " #B6B6B6" },
											}}
											label={
												<span
													style={{ color: "black", fontWeight: "normal", fontSize: "1.1rem", width: "20px" }}></span>
											}
										/>
									</Box>
								</Box>
								<Field
									as={TextField}
									name="accountType"
									select
									label={
										<span style={{ color: "black", fontWeight: "normal", fontSize: "1.1rem" }}>Tipo de Favorecido</span>
									}
									fullWidth
									margin="normal"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<ArrowDropDownIcon style={{ color: "black" }} />
											</InputAdornment>
										),
									}}
									SelectProps={{
										style: { textAlign: "left" },
									}}>
									<MenuItem value="corrente">{t("Current account")}</MenuItem>
									<MenuItem value="corrente_conjunta">{t("Couple Current account")}</MenuItem>
									<MenuItem value="poupanca">{t("Saving account")}</MenuItem>
									<MenuItem value="poupanca_conjunta">{t("Couple saving account")}</MenuItem>
								</Field>
								<Field
									as={TextField}
									name="legalNumber"
									fullWidth
									margin="normal"
									label={<span style={{ color: "black", fontWeight: "normal", fontSize: "1.1rem" }}>CNPJ/CPF</span>}
									InputProps={{
										style: { color: " #B6B6B6" },
									}}
								/>
								<Field
									as={TextField}
									name="accountHolderName"
									label={
										<span style={{ color: "black", fontWeight: "normal", fontSize: "1.1rem" }}>
											{t("Name of titular")}
										</span>
									}
									InputProps={{
										style: { color: " #B6B6B6" },
									}}
									fullWidth
									margin="normal"
								/>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									style={{ marginTop: "1rem", width: "100%" }}
									className={classes.shareLinkButton}>
									{t("Confirm")}
								</Button>

								<Snackbar
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "left",
									}}
									open={snackbarOpen}
									autoHideDuration={6000}
									onClose={handleSnackbarClose}
									message={snackbarMessage}
								/>
							</Form>
						)}
					</Formik>
				</Box>
			</TitleContainer>
		);
	}
	return (
		<Box>
			<TitleContainer loading={loading} headerStyle={{ boxShadow: "0", padding: "0" }} footer={footer()}>
				<IconButton onClick={handleBackClick} style={{ marginTop: "0rem", padding: "0", color: "#000" }}>
					<ArrowBackIosIcon />
				</IconButton>
				<Typography className={classes.title} style={{ marginTop: "1.5rem", fontSize: "1.3rem" }}>
					{t("Your earns")}
				</Typography>
				{/* <Typography
					className={classes.subTitle}
					style={{ marginTop: "1.5rem", marginBottom: "1rem", fontWeight: "normal" }}>
					Dados bancários cadastrados:
				</Typography>

				<div>
					{!bankAccount ? (
						<Card
							style={{
								boxShadow: "0px 0px 6px 2px #00000033",

								padding: "16px",
								margin: "16px 0",
								borderRadius: "8px",
							}}>
							<Typography variant="body2" style={{ color: "#4C4C4C", fontSize: "0.95rem" }}>
								Não há informação de pagamento
							</Typography>
							<Typography variant="body2" style={{ color: "#949494", margin: "0.6rem 0", fontSize: "0.95rem" }}>
								Só podemos processar o seu pagamento depois que você fornecer uma forma de pagamento.
							</Typography>
							<Typography
								variant="body2"
								style={{ color: "#F06D6C", cursor: "pointer", fontSize: "0.95rem" }}
								onClick={() => handleEditClick()}>
								Fornecer informações de pagamento!
							</Typography>
						</Card>
					) : (
						<Card className={classes.bankDetailsCard}>
							<div>
								<Typography variant="body2">{`Agência: ${bankAccount?.agency || 0} - ${
									bankAccount?.agency_digit || 0
								}`}</Typography>
								<Typography variant="body2">{`Conta: ${bankAccount?.account || 0} - ${
									bankAccount?.account_digit || 0
								}`}</Typography>
								<Typography className={classes.editLink} onClick={() => handleEditClick()}>
									Editar
								</Typography>
							</div>
						</Card>
					)}
				</div> */}

				<Box className={classes.earningBlock} style={{ marginTop: "3rem" }}>
					<Typography className={classes.bankDataText}>
						{(earningsValues?.my_earnings || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
					</Typography>
					<Typography className={classes.description}>Pago</Typography>
				</Box>
				<Box className={classes.earningBlock} style={{ marginTop: "2rem" }}>
					<Typography className={classes.bankDataText}>
						{(
							earningsValues?.potential_earnings - earningsValues?.my_earnings - earningsValues?.to_receive || 0
						).toLocaleString("pt-BR", {
							style: "currency",
							currency: "BRL",
						})}
					</Typography>
					<Typography className={classes.description}>Ganhos em potencial</Typography>
				</Box>

				<Box className={classes.earningBlock} style={{ marginTop: "2rem" }}>
					<Typography className={classes.bankDataText}>
						{(earningsValues?.to_receive || 0).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
					</Typography>
					<Typography className={classes.description}>A receber</Typography>
				</Box>

				<Box style={{ marginTop: "10rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
					{earningsValues?.to_receive > 0 && (
						<Button
							style={{ width: "100%" }}
							fullWidth
							variant="contained"
							onClick={() => {
								handleGenerateTransfer();
							}}
							className={classes.shareLinkButton}>
							Solicitar Saque
						</Button>
					)}
				</Box>
				<ErrorDialog
					onOpen={openError}
					message={errorMessage}
					onOk={() => {
						setOpenError(false);
					}}
					title={t("Attention")}
				/>
			</TitleContainer>
		</Box>
	);
};
export default withRouter(YourEarnings);
