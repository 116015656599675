import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	// Dialog Sucess
	message: {
		textAlign: "center",
		color: "#3689ea",
		fontWeight: 500,
	},
	img: {
		width: "40px",
		height: "40px",
	},
	imgContainer: {
		border: "2px solid #3689ea",
		padding: "20px 23px",
		borderRadius: "50px",
		width: "90px",
	},
	// Dialog Cancel Confirm
	hours: {
		height: "18px",
		margin: "0 30px 2px 0",
		fontSize: "14px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.31px",
		color: " #888888",
	},
	nameService: {
		height: "18px",
		margin: "2px 0 0",
		fontSize: "16px",
		fontWeight: "500",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.36px",
		color: " #474747",
	},
	values: {
		margin: "10px 0",
		fontSize: "12px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		color: "rgba(71, 71, 71, 0.5)",
	},
	type: {
		height: "12px",
		fontSize: "10px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		color: " #474747",
		float: "right",
		textAlign: "right",
		margin: "-23px 0",
	},
	backValue: {
		height: "14px",
		margin: "10px 0",
		fontSize: "14px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		color: "#3689ea",
	},
	avatar: {},
	actions: {
		justifyContent: "center",
	},
	button: {
		color: "#3689ea",
		textTransform: "none",
	},
	
	// Dialog Cancel
	imgContainerCancel: {
		border: "2px solid #FF3B30",
		padding: "20px 23px",
		borderRadius: "50px",
		width: "90px",
	},
	messageCancel: {
		textAlign: "center",
		color: "#FF3B30",
		fontWeight: 500,
	},
	text: {
		textAlign: "center",
	},
	//dialog Add Card
	root: {
		backgroundColor: "#3689ea",
		color: "#ffffff",
		height: 44,
		width: 355,
		margin: "0 3px 6px",
		fontSize: 12,
		fontWeight: "normal",
		textTransform: "none",
		borderRadius: 20,
		"&:hover": {
			backgroundColor: "#106ba3",
		},
		border: "solid 1px #3689ea",
	},
	//dialog
	container: {
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
		margin: "0 0 11px",
	},
}));
