import Constants from "~/helpers/enums/Constants";

const initialState = {
	loading: false,
	telephoneWithCountryCode: "",
	telephone: "",
	confirmation: {},
	email: "",
	name: "",
	title: "",
	message: "",
	open: false,
	titleError: "",
	messageError: "",
	openError: false,
};

const loginReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.SET_TELEPHONE:
			return {
				...state,
				telephone: action.payload.telephone,
			};
		case Constants.SET_EMAIL:
			return {
				...state,
				email: action.payload.email,
			};
		case Constants.SET_NAME:
			return {
				...state,
				name: action.payload.name,
			};
		case Constants.LOADING_AUTHENTICATION:
			return {
				...state,
				loading: action.payload.loading,
			};
		case Constants.LOGIN_DIALOG_ERROR:
			return {
				...state,
				openError: action.payload.openError,
				messageError: action.payload.messageError,
				titlteError: action.payload.titlteError,
			};
		case Constants.LOGIN_DIALOG:
			return {
				...state,
				open: action.payload.open,
				message: action.payload.message,
				title: action.payload.title,
			};
		case Constants.TELEPHONE_COUNTRY_CODE:
			return {
				...state,
				telephoneWithCountryCode: action.payload.telephoneWithCountryCode,
			};
		case Constants.GET_CONFIRMATION:
			return {
				...state,
				confirmation: action.payload.confirmation,
			};
		case Constants.CLEAN_LOGIN_REDUCER:
			return {
				...state,
				loading: initialState.loading,
				telephone: initialState.telephone,
				telephoneWithCountryCode: initialState.telephoneWithCountryCode,
				confirmation: initialState.confirmation,
				email: initialState.email,
				name: initialState.name,
				open: initialState.open,
				message: initialState.message,
				title: initialState.title,
				openError: initialState.openError,
				messageError: initialState.messageError,
				titlteError: initialState.titleError,
			};
		case Constants.CLEAN_LOGIN_EMAIL_REDUCER:
			return {
				...state,
				loading: initialState.loading,
				email: initialState.email,
				name: initialState.name,
				open: initialState.open,
				message: initialState.message,
				title: initialState.title,
				openError: initialState.openError,
				messageError: initialState.messageError,
				titlteError: initialState.titleError,
			};
		default:
			return state;
	}
};

export default loginReducer;
