import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	text404: {
		fontSize: 180,
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		paddingTop: "50%",
		color: "white",
		fontWeight: "bold",

		transition: theme.transitions.create(["color", "textShadow"], {
			duration: theme.transitions.duration.complex,
		}),
		textShadow: "#9776CB 0 -2px",
		animation: "$expand 4.5s cubic-bezier(.215,.61,.355,1.000) both",
	},
	"@keyframes expand": {
		"0%": { letterSpacing: "-.5em", opacity: 0 },
		"40%": { opacity: 0.6 },
		"100%": { opacity: 0.9 },
	},

	adviser: {
		fontSize: 30,
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		color: "white",
		animation: "$appear 2s cubic-bezier(.25,.46,.45,.94) both",
	},

	adviserSubtitle: {
		fontSize: 14,
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		textAlign: "center",
		color: "white",
		animation: "$appear 4s cubic-bezier(.25,.46,.45,.94) both",
	},
	"@keyframes appear": {
		"0%": { transform: `translateY(-600px) rotateX(-30deg) scale(0)`, transformOrigin: `50% 100`, opacity: 0 },
		"100%": { transform: `translateY(0) rotateX(0) scale(1)`, transformOrigin: `50% 1400px`, opacity: 0.9 },
	},
	background: {
		background: "linear-gradient(90deg, rgba(57,173,196,1) 0%, rgba(126,107,194,1) 50%, rgba(177,70,203,1) 100%)",
		height: "100vh",
	},
}));
