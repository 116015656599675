import { createMuiTheme } from "@material-ui/core";

const MuiThemeApp = createMuiTheme({
	typography: {
		fontFamily: "CircularStd",
	},
	// spacing: (factor) => `${2 * factor}vw`,
});

export default MuiThemeApp;
