import { makeStyles } from "@material-ui/core";

const MuiStylesCardReservations = makeStyles((theme) => ({
	rectangle: {
		width: "335px",
		height: "130px",
		margin: "20px 20px 12px",
		padding: "8px 16px",
		borderRadius: "5px",
		backgroundColor: "#39376208",
	},
	date: {
		width: "auto",
		height: "15px",
		margin: "0 7px 8px 0",
		fontSize: "12px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.27px",
		color: "#888888",
	},
	hours: {
		height: "15px",
		margin: "9px 50px 4px 0",
		fontSize: "12px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.27px",
		color: "#888888",
		float: "left",
	},
	status: {
		height: "20px",
		margin: "8px 0 8px 6px",
		fontSize: "12px",
		fontWeight: 500,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.36px",
		textAlign: "right",
		color: " #d30000",
	},
	nameService: {
		height: "20px",
		margin: "4px 97px 8px 0",
		fontSize: "12px",
		fontWeight: 500,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.36px",
		color: "#474747",
	},
	value: {
		height: "20px",
		margin: "-28px 0 8px 6px",
		fontSize: "16px",
		fontWeight: 500,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.36px",
		textAlign: "right",
		color: "#3689ea",
	},
	payment: {
		height: "14px",
		margin: "7px 3px 0 80px",
		fontSize: "12px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		color: "#474747",
		float: "left",
	},
	total: {
		height: "14px",
		margin: "7px 0 0 6px",
		fontSize: "12px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "right",
		color: "#474747",
	},
	verificationText: {
		color: "#47474778",
		marginTop: "61px",
		fontSize: "16px",
		fontWeight: 500,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.36px",
		textAlign: "center",
	},
	button: {
		// width: "30%",
		height: "36px",
		margin: "15px 5px 15px 0",
		padding: "9px 13.5px 5px 14.5px",
		borderRadius: "18px",
		border: "solid 1px #3689ea",
	},
	buttonName: {
		height: "18px",
		fontSize: "12px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "center",
		color: "#3689ea",
	},
}));

export default MuiStylesCardReservations;
