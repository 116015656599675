import DayJsUtils from "@date-io/dayjs";
import { CssBaseline, Paper, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ClearCache from "react-clear-cache";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { MixpanelProvider } from "react-mixpanel-browser";
import MuiStylesApp from "~/components/app/MuiStylesApp";
import MuiThemeApp from "~/components/app/MuiThemeApp";
import dayjs from "~/config/dayjs";
import authService from "~/services/authService";
import Routers from "~/views/app/routers/Routers";
import { Firebase } from "~/services/firebase";
import authAction from "~/store/actions/authAction";
import "./App.css";

const App = () => {
	const dispatch = useDispatch();
	const classes = MuiStylesApp();

	useEffect(() => {
		return Firebase.onIdTokenChanged(async (user) => {
			if (!user) {
				authService.authenticateAnonymousUser();
			} else {
				dispatch(authAction.setUser(user));
			}
		});
	}, [dispatch]);

	useEffect(() => {
		const handle = setInterval(async () => {
			const user = Firebase.currentUser;
			if (user) await user.getIdToken(true);
		}, 10 * 60 * 1000);

		(function (c, l, a, r, i, t, y) {
			c[a] =
				c[a] ||
				function () {
					(c[a].q = c[a].q || []).push(arguments);
				};
			t = l.createElement(r);
			t.async = 1;
			t.src = "https://www.clarity.ms/tag/" + i;
			y = l.getElementsByTagName(r)[0];
			y.parentNode.insertBefore(t, y);
		})(window, document, "clarity", "script", process.env.REACT_APP_CLARITY_ID);

		return () => clearInterval(handle);
	}, []);

	return (
		<MixpanelProvider>
			<ClearCache>
				{({ isLatestVersion, emptyCacheStorage }) => {
					if (!isLatestVersion) {
						emptyCacheStorage();
					}
					return (
						<ThemeProvider theme={MuiThemeApp}>
							<CssBaseline />

							<Paper className={classes.mainPaper}>
								<MuiPickersUtilsProvider libInstance={dayjs} utils={DayJsUtils}>
									<Routers />
								</MuiPickersUtilsProvider>
							</Paper>
						</ThemeProvider>
					);
				}}
			</ClearCache>
		</MixpanelProvider>
	);
};

export default App;
