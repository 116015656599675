import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	header: {
		height: "150px",
		boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.08)",
		backgroundColor: "#fafafa",
		padding: 17,
	},
	title: {
		color: "rgba(71, 71, 71, 0.82)",
		fontSize: 20,
		paddingTop: 15,
	},
	subTitle: {
		fontSize: 12,
		color: "rgba(71, 71, 71, 0.5)",
	},
	icon: {
		cursor: "pointer",
		color: "#474747",
	},
	image: {
		height: "40px",
		width: "40px",
		borderRadius: "20px",
		border: "1px solid white",
	},
	headerCard: {
		padding: "20px 20px 0 20px",
		display: "flex",
		alignContent: "center",
		justifyContent: "flex-start",
	},
	professionalTitle: {
		color: "#474747",
		width: "100%",
		fontSize: 14,
		fontWeight: "bold",
		alignSelf: "center",
	},
	bookingDiv: {
		display: "flex",
		width: "100%",
		justifyContent: "flex-end",
	},
	bookingTitle: {
		alignSelf: "center",
		fontSize: 14,
		color: "rgba(71, 71, 71, 0.5)",
	},
	servicesDiv: {
		padding: "5px 20px 0 20px",
		display: "flex",
		alignContent: "center",
		justifyContent: "flex-start",
	},
	serviceTitle: {
		color: "#474747",
		fontSize: 12,
		marginRight: "8px",
	},
	duration: {
		fontSize: 12,
		width: "100%",
		color: "rgba(71, 71, 71, 0.5)",
	},
	price: {
		fontSize: 14,
		color: "#3689ea",
		fontWeight: "bold",
	},
	priceDetailBlue: {
		color: "#3689ea",
		fontSize: 12,
		fontWeight: " bold",
	},
	priceDetail: {
		color: "rgba(71, 71, 71, 0.5)",
		fontSize: 12,
		fontWeight: " bold",
	},
	paymentDetail: {
		color: "#393762",
		fontSize: 12,
	},
	root: {
		backgroundColor: "#1f264c",
		color: "#ffffff",
		height: 44,
		width: 335,
		fontSize: 12,
		fontWeight: "normal",
		textTransform: "none",
		borderRadius: 20,
		"&:hover": {
			backgroundColor: "#1f264cdb",
		},
	},

	// Policy
	policyWrapper: {
		color: '#CCC',
		textAlign: 'center',
		justifyContent: 'center',
		fontSize: 11,
		fontWeight: 'bold',
	}
}));