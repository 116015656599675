import Constants from "~/helpers/enums/Constants";

const initialState = {
	business: null,
	availableDates: null,
	notifications: null,
	businessId: null,
};

const businessReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.GET_BUSINESS:
			return {
				...state,
				business: action.payload.business,
			};
		case Constants.GET_AVAILABLE_DATES:
			return {
				...state,
				availableDates: action.payload.availableDates,
			};
		case Constants.GET_NOTIFICATIONS:
			return {
				...state,
				notifications: action.payload.notifications,
			};
		case Constants.GET_BUSINESS_ID:
			return {
				...state,
				businessId: action.payload.businessId,
			};
		default:
			return state;
	}
};

export default businessReducer;
