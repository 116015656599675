import api from "~/services/api";

const CONTROLLER_NAME = "/business";
const CONTROLLER_NAME_NOTIFICATIONS = "/feed";
const CONTROLLER_NAME_SECTOR = "/sector";
const CONTROLLER_NAME_BUSINESS_SERVICE = "/business_service";

export const getBusinessList = () => {
	return api.get(CONTROLLER_NAME);
};

export const getBusiness = (id) => {
	return api.get(`${CONTROLLER_NAME}/${id}`);
};

export const getSectors = (ids) => {
	const categorylIdString = ids.join("&category_ids[]=");
	return api.get(`${CONTROLLER_NAME_SECTOR}/?category_ids[]=${categorylIdString}`);
};
// business service
export const getBusinessService = (id, categoryId,language, species, race, feature_items) => {

	return api.get(`${CONTROLLER_NAME_BUSINESS_SERVICE}/business/${id}/new`, {
		params: {
			category_id: !false ? categoryId : null,
			language: language,
			species: species,
			race: race,
			//feature_items: feature_items,
		},
	});
};
// notifications
export const getBusinessNotifications = (page) => {
	return api.get(`${CONTROLLER_NAME_NOTIFICATIONS}`, {
		params: {
			page: page,
			per_page: 15,
		},
	});
};

export const getAvailableDates = (businessId, services) => {
	return api.post(`${CONTROLLER_NAME}/${businessId}/available_dates`, { services });
};

export const getContract = (businessId) => {
	return api.get(`${process.env.REACT_APP_URL_API_PAYOUT}recipient/contract/${businessId}`);
};

export default {
	getBusinessList,
	getBusiness,
	getSectors,
	getBusinessService,
	getAvailableDates,
	getContract,
	getBusinessNotifications,
};
