import Constants from "~/helpers/enums/Constants";

const initialState = {
	hours: [],
};

const hoursReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.HOURS_SELECTED:
			return {
				...state,
				...action.payload.hours,
			};
		default:
			return state;
	}
};

export default hoursReducer;
