import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		backgroundColor: "#630094",
		color: "#ffffff",
		height: 44,
		width: 335,
		fontSize: 12,
		fontWeight: "normal",
		textTransform: "none",
		borderRadius: 20,
		"&:hover": {
			backgroundColor: "#63009486",
		},
	},
	namePet: {
		width: "-webkit-fill-available",
		padding: 30,
		fontFamily: "CircularStd",
		fontSize: "14px",
		fontStyle: "normal",
		color: " #b1b0b0",
		float: "left",
		fontWeight: "bold",
	},
	newPet: {
		width: "-webkit-fill-available",
		fontFamily: "CircularStd",
		fontSize: "14px",
		fontStyle: "normal",
		color: " #00000083",
		float: "left",
		fontWeight: "bold",
	},
	containerAddPet: {
		textAlign: "center",
	},
}));
