import React from "react";
import CodePage from "~/components/login/CodePage";
import PersonalInformationPage from "~/components/login/PersonalInformationPage";
import TelephonePage from "~/components/login/TelephonePage";
import EmailSignUp from "~/components/login/EmailSignUp";
import EmailSignIn from "~/components/login/EmailSignIn";
import CheckoutPage from "~/views/checkoutPage";
import NotFound from "~/views/notFound/NotFound";
import AppAccessPage from "~/views/schedulingApp/AppAccess";
import ConfirmationPage from "~/views/schedulingConfirmation/SchedulingConfirmation";
import SelectScheduleDate from "~/views/selectScheduleDate/SelectScheduleDate";
import SelectScheduleTime from "~/views/selectScheduleTime/SelectScheduleTime";
import ServiceScheduling from "~/views/ServiceScheduling";
import VendorPage from "~/views/vendorPage/VendorPage";
import Indications from "~/views/indications/Indications";
import BookingScreen from "~/views/bookingScreen/BookingScreen";
import Register from "~/views/registerPage";
import Notifications from "~/views/notificationsPage";
import RegisteredPet from "~/views/registeredPet";
import AddPet from "~/views/addPet";
import Coupon from "~/components/coupon";
import CategorieScheduling from "~/views/CategorieScheduling";
import YourEarnings from "~/views/indications/yourEarnings/YourEarnings";
import YourReferrals from "~/views/indications/yourReferrals/YourReferrals";
import HowIndicationsWork from "~/views/indications/howIndicationsWork/HowIndicationsWork";
import OptionsLogin from "~/components/login/optionsLogin";
import PersonalInformationNumber from "~/components/login/PersonalInformationNumber";

const routes = [
	{
		path: "/download/:id",
		exact: true,
		view: () => <AppAccessPage />,
	},
	{
		path: "/endereco/:id",
		exact: true,
		view: () => <Register />,
	},
	{
		path: "/business/:id",
		exact: true,
		view: () => <VendorPage />,
	},
	{
		path: "/reservas/:id",
		exact: true,
		view: () => <BookingScreen />,
	},
	{
		path: "/business/:id/bookingTransactionId/:bookingTransactionId",
		exact: true,
		view: () => <BookingScreen />,
	},
	{
		path: "/selecionar-categoria/:id",
		exact: true,
		view: () => <CategorieScheduling />,
	},
	{
		path: "/selecionar-servicos/:id",
		exact: true,
		view: () => <ServiceScheduling />,
	},
	{
		path: "/notificacoes/:id",
		exact: true,
		view: () => <Notifications />,
	},
	{
		path: "/notificacoes/:id/bookingTransactionId/:bookingTransactionId",
		exact: true,
		view: () => <Notifications />,
	},
	{
		path: "/pets-cadastrados/:id",
		exact: true,
		view: () => <RegisteredPet />,
	},
	{
		path: "/adicionar-pet/:id",
		exact: true,
		view: () => <AddPet />,
	},
	{
		path: "/cupons/:id",
		exact: true,
		view: () => <Coupon />,
	},
	{
		path: "/selecionar-data/:id",
		exact: true,
		view: () => <SelectScheduleDate />,
	},
	{
		path: "/selecionar-horarios/:id",
		exact: true,
		view: () => <SelectScheduleTime />,
	},
	{
		path: "/checkout/:id",
		exact: true,
		view: () => <CheckoutPage />,
	},

	{
		path: "/confirmation/:id",
		exact: true,
		view: () => <ConfirmationPage />,
	},
	{
		path: "/login",
		exact: true,
		view: () => <TelephonePage />,
	},
	{
		path: "/login/options",
		exact: true,
		view: () => <OptionsLogin />,
	},
	{
		path: "/login/code",
		exact: true,
		view: () => <CodePage />,
	},
	{
		path: "/login/personal-information",
		exact: true,
		view: () => <PersonalInformationPage />,
	},
	{
		path: "/login/personal-information-number",
		exact: true,
		view: () => <PersonalInformationNumber />,
	},
	{
		path: "/login/email-sign-up",
		exact: true,
		view: () => <EmailSignUp />,
	},
	{
		path: "/login/email-sign-in",
		exact: true,
		view: () => <EmailSignIn />,
	},
	{
		path: "/not-found",
		exact: true,
		view: () => <NotFound />,
	},
	{
		path: "/indicacoes",
		exact: true,
		view: () => <Indications />,
	},

	{
		path: "/indicacoes/seus-ganhos",
		exact: true,
		view: () => <YourEarnings />,
	},

	{
		path: "/indicacoes/suas-indicacoes",
		exact: true,
		view: () => <YourReferrals />,
	},

	{
		path: "/indicacoes/como-funciona",
		exact: true,
		view: () => <HowIndicationsWork />,
	},
	{
		path: "/robots.txt",
		exact: true,
		view: () => <></>,
	},
	{
		path: "/sitemap.xml",
		exact: true,
		view: () => <></>,
	},
];

export default routes;
