import { createMuiTheme } from "@material-ui/core";

const MuiThemeCustomTabs = (theme) =>
	createMuiTheme({
		...theme,
		overrides: {
			MuiTabs: {
				indicator: {
					backgroundColor: "#474747",
					//width:"50% !important",
					//bottom: "9px"
				},
			},
			MuiTab: {
				root: {
					padding: "6px",
				},
				wrapper: {
					width: "auto",
				},
				textColorPrimary: {
					"&$selected": {
						color: "#474747",
					},
					color: "rgba(71, 71, 71, 0.5)",
					fontSize: "14px",
					fontWeight: "bold",
					textTransform: "capitalize",
					minWidth: "0px",
				},
			},
		},
	});

export default MuiThemeCustomTabs;
