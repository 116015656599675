import api from "~/services/api";

export const getBankAccount = (id) => {
	return api.get(`${process.env.REACT_APP_URL_API_PAYOUT}recipient/bank-account/consumer/${id}`);
};

export const getAllBankList = () => {
	return api.get(`${process.env.REACT_APP_URL_API_PAYOUT}bank/all`);
};

export const postBankAccountData = (values) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}recipient/bank-account/save`, { ...values });
};

export default {
	getBankAccount,
	postBankAccountData,
	getAllBankList,
};
