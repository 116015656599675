import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import Footer from "~/components/common/footerContainer/FooterContainer";
import TitleContainer from "../common/titleContainer/TitleContainer";
import businessAction from "~/store/actions/businessAction";
import EmojiFlagsIcon from "@material-ui/icons/EmojiFlags";
import { Typography, Divider, Button, Dialog } from "@material-ui/core";
import useStyles from "./styles";
import bookingTransactionAction from "~/store/actions/bookingTransactionAction";
import checkoutServices from "~/services/checkoutServices";
import SpinnerLoading from "../common/spinnerLoading/SpinnerLoading";
import contractAction from "~/store/actions/contractAction";
import { useTranslation } from "react-i18next";
import { DialogBuilder } from "~/utils/DialogProvider";

const Notifications = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { id, bookingTransactionId } = useParams();
	const { i18n, t } = useTranslation();

	const { user } = useSelector((store) => store.auth);
	const { notifications } = useSelector((store) => store.business);
	const { business } = useSelector((store) => store.business);
	const { bookingTransaction } = useSelector((store) => store.bookingTransaction);

	const [openPayment, setOpenPayment] = useState(true);

	const [newsCount, setNewsCount] = useState(0);
	const [lastReminderCheckDate, setLastReminderCheckDate] = useState(new Date(0));

	const [selectedReminder, setSelectedReminder] = useState(null);

	const [selectedBooking, setSelectedBooking] = useState(null);

	const [message, setMessage] = useState("");

	const [openMessage, setOpenMessage] = useState(false);

	const [loading, setLoading] = useState(true);

	var token = user?.accessToken;

	const dialog = useContext(DialogBuilder);

	useEffect(() => {
		if (props?.location?.state?.paymentSuccess) {
			dialog
				.withTitle(t("Payment made"))
				.withMessage(t("Your subsequent payment was made successfully!"))
				.withAction(() => props.history.replace("/notificacoes/" + business?.id))
				.show();
		}
	}, []);

	useEffect(() => {
		if (business) {
			if (business?.language === "portuguese") {
				i18n.changeLanguage("ptBR");
			} else {
				i18n.changeLanguage("en");
			}
		}
	}, [business, i18n]);

	useEffect(() => {
		if (user !== null && id) {
			dispatch(businessAction.getBusiness(id, token));
		}
	}, [dispatch, id, user, token]);

	useEffect(() => {
		setLoading(false);
		if (user && business && (user.isAnonymous || !user.uid)) {
			localStorage.setItem("@prit-web/coupon", bookingTransactionId || true);
			props.history.push(`/login`, {
				goBackRoute: "/notificacoes/" + business.id,
				goBackRouteProps: props.location.state,
			});
		}
	}, [bookingTransactionId, business, props.history, props.location.state, user]);

	useEffect(() => {
		var token = user?.accessToken;
		if (token) {
			dispatch(businessAction.getBusinessNotifications(1, token));
		}
	}, [dispatch, user]);

	const footer = () => {
		return <Footer section={"notificações"} />;
	};

	const elapsedTime = (date) => {
		let time = Date.now();

		time -= new Date(date);

		if (time < 3600000) {
			return Math.floor(time / 60000) + t("minuto(s) atrás");
		}

		if (time >= 3600000 && time < 3600000 * 24) {
			return Math.floor(time / 3600000) + t("hora(s) atrás");
		}

		return Math.floor(time / (3600000 * 24)) + t("dia(s) atrás");
	};

	useEffect(() => {
		let lastCheck = localStorage.getItem("@prit-web/lastReminderCheck");
		if (lastCheck !== null) {
			setLastReminderCheckDate(new Date(lastCheck));
			let count = 0;
			notifications.forEach((element) => {
				if (lastReminderCheckDate < new Date(element.createdAt)) {
					count++;
				}
			});
			setNewsCount(count);
		}
	}, [lastReminderCheckDate, notifications]);

	useEffect(() => {
		let count = 0;
		if (notifications) {
			if (bookingTransactionId !== null) {
				if (openPayment) {
					clickLinkPayment();
				}
			}
			notifications.forEach((element) => {
				if (lastReminderCheckDate < new Date(element.createdAt)) {
					count++;
				}
			});
			setNewsCount(count);
		}
	}, [notifications]);

	const handleCheckAllReminders = () => {
		localStorage.setItem("@prit-web/lastReminderCheck", Date.now());
		setLastReminderCheckDate(Date.now());
		setNewsCount(0);
	};

	useEffect(() => {
		setTimeout(() => setLoading(false), 1000);
	}, []);

	useEffect(() => {
		if (
			selectedReminder &&
			selectedReminder?.data?.status !== "active" &&
			selectedReminder?.data?.status !== "reminder"
		) {
			dispatch(contractAction.getContract(selectedReminder?.data?.recipientId, token));
		}
	}, [dispatch, selectedReminder, token]);

	const getSelectedBooking = (reminder) => {
		if (reminder) {
			dispatch(bookingTransactionAction.getBookingTransaction(reminder?.userId, token));
		}
	};

	const clickLinkPayment = () => {
		setOpenPayment(false);
		const array = notifications;
		if (bookingTransaction)
			bookingTransaction.forEach((booking) => {
				booking.bookings.forEach((element) => {
					if (element.bookingTransactionId === bookingTransactionId) {
						for (let index = 0; index < array.length; index++) {
							const elem = array[index];
							if (elem && elem.bookingTransactionId === bookingTransactionId) {
								setSelectedBooking(elem);
								return;
							}
						}
					}
				});
			});
	};

	useEffect(() => {
		if (bookingTransaction) {
			bookingTransaction.forEach((booking) => {
				booking.bookings.forEach((element) => {
					if (element.bookingTransactionId === selectedReminder?.bookingTransactionId) {
						setSelectedBooking(element);
					}
				});
			});
		}
	}, [bookingTransaction, bookingTransactionId, selectedReminder]);

	const styleCateg = (item) => {
		let style = {};
		if (item.bookingTransactionId === bookingTransactionId && item.data.template === "payment") {
			return (style = { WebkitBoxShadow: "14px 4px 74px 10px rgb(54 139 237) " });
		}
		return style;
	};

	useEffect(() => {
		if (selectedBooking) {
			if (selectedReminder?.data?.status === "active") {
				props.history.push(`/reservas/${selectedBooking?.business?.id}`, { autoOpenModal: selectedBooking.id });
			} else if (selectedReminder?.data?.status === "reminder") {
				props.history.push(`/reservas/${selectedBooking?.business?.id}`);
			}
		}
	}, [bookingTransactionId, props.history, selectedBooking, selectedReminder]);

	const fetchPaymentRequest = async (item) => {
		const meData = JSON.parse(localStorage.getItem("@prit-web/meData"));
		checkoutServices.fetchPaymentRequest(meData.id).then((res) => {
			const pay = res?.data;
			if (pay) {
				const paymentRequestToPay = pay.find((payment) => payment.payment_request_id === item.data.paymentRequestId);
				if (!paymentRequestToPay) {
					setLoading(false);
					setOpenMessage(true);
					setMessage("Pagamento já foi efetuado ou essa solicitação de pagamento foi cancelada pelo estabelecimento.");
				} else {
					checkoutServices
						.fetchPaymentAnticipatedAmountByBookTransaction({
							recipient_id: item?.data?.recipientId,
							payment_request_id: item?.data?.paymentRequestId,
						})
						.then((res) => {
							if (res.data) {
								const postPayment = {
									booking_external_id: item?.bookingTransactionId,
									recipient_id: item?.data?.recipientId,
									payment_request_id: item?.data?.paymentRequestId,
									checkoutInformation: res.data,
									amount: paymentRequestToPay.amount,
								};
								props.history.push(`/checkout/${business?.id}`, { postPayment, services: [] });
								setLoading(false);
							} else {
								setLoading(false);
								setMessage(
									"Pagamento já foi efetuado ou essa solicitação de pagamento foi cancelada pelo estabelecimento."
								);
								setOpenMessage(true);
							}
						})
						.catch((err) => {
							setLoading(false);
							setOpenMessage(true);
							setMessage(
								"Pagamento já foi efetuado ou essa solicitação de pagamento foi cancelada pelo estabelecimento."
							);
						});
				}
			} else {
				setLoading(false);
				setMessage("Pagamento já foi efetuado ou essa solicitação de pagamento foi cancelada pelo estabelecimento.");
				setOpenMessage(true);
			}
		});
	};

	return (
		<>
			<TitleContainer footer={footer()}>
				<Typography className={classes.you}>{t("You")}</Typography>
				<div className={classes.header}>
					<div className={classes.news}>
						<Typography className={classes.newsLabel}>{t("Novas")}</Typography>
						<Typography className={classes.count}>{newsCount}</Typography>
					</div>
					<Button className={classes.button} onClick={handleCheckAllReminders}>
						{t("Mark everything as read")}
					</Button>
				</div>
				<Divider></Divider>
				{notifications?.length !== 0 && notifications != null
					? notifications.map((item) => (
							<>
								<div
									style={styleCateg(item)}
									className={classes.reminder}
									onClick={() => {
										setLoading(true);
										fetchPaymentRequest(item);
										getSelectedBooking(item);
										setSelectedReminder(item);
									}}>
									<EmojiFlagsIcon className={classes.icon}></EmojiFlagsIcon>
									<div className={classes.info}>
										<Typography className={classes.title}>{item.title}</Typography>
										<Typography className={classes.message}>{item.message}</Typography>
										<Typography className={classes.time}>{elapsedTime(item.createdAt)}</Typography>
									</div>
									{lastReminderCheckDate < new Date(item.createdAt) && (
										<Typography className={classes.notify}>o</Typography>
									)}
								</div>
								<Divider></Divider>
							</>
					  ))
					: !loading && <Typography className={classes.noNotifications}>Você não tem notificações</Typography>}
			</TitleContainer>
			{/* <WhatsAppButton bottom={"80px"} /> */}
			<SpinnerLoading loading={loading} />
			<Dialog
				onClick={() => setOpenMessage(false)}
				open={openMessage}
				PaperProps={{
					style: { borderRadius: 20, maxWidth: 312 },
				}}>
				<Typography className={classes.messageError}>{message}</Typography>
				<Divider></Divider>
				<Button className={classes.messageButton}>OK</Button>
			</Dialog>
			<SpinnerLoading loading={loading} />
		</>
	);
};

export default withRouter(Notifications);
