//import { gapi } from 'gapi-script'

var gapi = window.gapi;

var CLIENT_ID = "964307259443-a2pb8bp7cf42voju2fk85luih9baqu2k.apps.googleusercontent.com";
var API_KEY = "AIzaSyDfjx5rrkTajaH7IGUQdt6a47kBP8P1EK4";
var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
var SCOPES = "https://www.googleapis.com/auth/calendar.events";

export const googleSignIn = (sucess) => {
	gapi.load("client:auth2", () => {
		gapi.client.init({
			apiKey: API_KEY,
			clientId: CLIENT_ID,
			discoveryDocs: DISCOVERY_DOCS,
			scope: SCOPES,
		});

		gapi.client.load("calendar", "v3", () => {
			gapi.auth2
				.getAuthInstance()
				.signIn()
				.then(() => {
					sucess();
					localStorage.setItem("@prit-web/isSignedOnGoogleAgenda", true);
				});
		});
	});
};

export const googleSignOut = () => {
	gapi.load("client:auth2", () => {
		gapi.client.init({
			apiKey: API_KEY,
			clientId: CLIENT_ID,
			discoveryDocs: DISCOVERY_DOCS,
			scope: SCOPES,
		});

		gapi.client.load("calendar", "v3", () => {
			gapi.auth2.getAuthInstance().signOut();
			localStorage.setItem("@prit-web/isSignedOnGoogleAgenda", false);
		});
	});
};

export const scheduleOnGoogleCalendar = (newEvent, businessName) => {
	if (localStorage.getItem("@prit-web/isSignedOnGoogleAgenda")) {
		gapi.load("client:auth2", () => {
			gapi.client.init({
				apiKey: API_KEY,
				clientId: CLIENT_ID,
				discoveryDocs: DISCOVERY_DOCS,
				scope: SCOPES,
			});

			gapi.client.load("calendar", "v3", () => {
				var event = {
					summary: newEvent.name + " em " + businessName,
					location: newEvent.business.addressStreet,
					description: "Sua reserva Prit!",
					start: {
						dateTime: newEvent.startTime,
						timeZone: newEvent.business.timeZone,
					},
					end: {
						dateTime: newEvent.endTime,
						timeZone: newEvent.business.timeZone,
					},
					attendees: [{ email: "appprit@gmail.com" }],
					reminders: {
						useDefault: false,
						overrides: [
							{ method: "email", minutes: 24 * 60 },
							{ method: "popup", minutes: 10 },
						],
					},
				};

				var request = gapi.client.calendar.events.insert({
					calendarId: "primary",
					resource: event,
				});

				// const retry = (res) => {
				// 	if (res.code === 403) {
				// 		request.execute(retry);
				// 	} else {
				// 	}
				// };

				request.execute();
			});
		});
	}
};

export const isSignedOnGoogle = (sucess) => {
	gapi.load("client:auth2", () => {
		gapi.client.init({
			apiKey: API_KEY,
			clientId: CLIENT_ID,
			discoveryDocs: DISCOVERY_DOCS,
			scope: SCOPES,
		});
		let isSignedOnGoogleAgenda = JSON.parse(localStorage.getItem("@prit-web/isSignedOnGoogleAgenda"))
		if (isSignedOnGoogleAgenda) {
			sucess();
		}
	});
};
