import React from "react";
import { Carousel } from "react-responsive-carousel";
import "~/assets/styles/CustomCarousel.scss";

const CustomCarousel = (props) => {
	const carouselProps = {
		centerMode: true,
		showThumbs: false,
		showStatus: false,
		showIndicators: false,
		centerSlidePercentage: 50,
	};
	return <Carousel {...carouselProps}>{props.children}</Carousel>;
};

export default CustomCarousel;
