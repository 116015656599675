import { TextField } from "@material-ui/core";
import { Field } from "formik";
import React from "react";
import useStyles from "./styles";

export const TextFieldComponent = ({ name, type, label, id, inputProps, value, ref, codeInput, ...props }) => {
	const classes = useStyles({ codeInput });
	return (
		<Field
			value={value}
			ref={ref}
			inputProps={inputProps}
			className={classes.field}
			component={TextField}
			type={type}
			label={label}
			name={name}
			id={id}
			{...props}
		/>
	);
};

export const FieldComponent = ({ name, type, label, id, inputProps, value, ref, ...props }) => {
	const classes = useStyles();
	return (
		<Field
			value={value}
			inputProps={inputProps}
			className={classes.codeInput}
			type={type}
			label={label}
			name={name}
			id={id}
			{...props}
		/>
	);
};
