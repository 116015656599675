import Constants from "~/helpers/enums/Constants";

const initialState = {
	isAuthenticated: false,
	user: null,
};

const authReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.LOGIN:
			return {
				isAuthenticated: action.payload.isAuthenticated,
				user: action.payload.user,
			};
		case Constants.LOGOUT:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default authReducer;
