import api from "~/services/api";

export const putUserUpdate = (id, data) => {
	return api.put(`user/${id || ""}`, data); 
};

export const pachUserUpdate = (id, data) => {
	return api.put(`user/${id}`, data); 
};

export const getUser = (id) => {
	return api.get(`user/${id}`); 
};
export default {
	putUserUpdate,
	pachUserUpdate,
	getUser,
};
