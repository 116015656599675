import { Button, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import React, { Fragment, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import InputMask from "react-input-mask";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import useStyles from "./styles";
import ErrorDialog from "~/components/common/dialog/index";
import { states } from "~/utils/states";
// import ViaCep from 'react-via-cep';
import userUpdateAction from "~/store/actions/userUpdateAction";
import { Formik, Form } from "formik";
import FormError from "~/components/common/formError";
import { useTranslation } from "react-i18next";

const Register = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { business } = useSelector((store) => store.business);
	const { user } = useSelector((store) => store.auth);
	const { userUpdate } = useSelector((store) => store.userUpdate);
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);
	const [neighborhood, setNeighborhood] = useState("");

	localStorage.setItem("@prit-web/neighborhood", neighborhood);
	const userId = localStorage.getItem("@prit-web/userId");

	const reservationUpdate = props?.location?.state?.reservationUpdate;
	const dados = props?.location?.state?.dados;
	const payload = useMemo(() => {
		return props?.location?.state?.payload;
	}, [props.location]);
	const pix = props?.location?.state?.pix;
	const answer = props?.location?.state?.answer;

	const handleOk = () => {
		setOpen(!open);
	};

	const handleScheduleClick = (values) => {
		dispatch(
			userUpdateAction.getUserUpdate(userId, {
				id: userId,
				name: user?.displayName,
				email: user?.email,
				addressPostalCode: values.addressPostalCode,
				addressStreet: values.addressStreet,
				addressNumber: values.addressNumber,
				// addressUnit: values.addressUnit,
				addressCity: values.addressCity,
				addressNeighborhood: values.addressNeighborhood,
				addressState: values.addressState,
			})
		);
		if (
			values?.addressCity &&
			values?.addressNeighborhood &&
			values?.addressNumber &&
			values?.addressPostalCode &&
			values?.addressStreet &&
			values?.addressState
		) {
			if (props?.set) {
				props.set({
					addressPostalCode: userUpdate?.addressPostalCode || values?.addressPostalCode,
					addressNeighborhood: userUpdate?.addressNeighborhood || values?.addressNeighborhood,
					addressStreet: userUpdate?.addressStreet || values?.addressStreet,
					// complement: userUpdate?.addressUnit || values?.addressUnit,
					addressCity: userUpdate?.addressCity || values?.addressCity,
					addressNumber: userUpdate?.addressNumber || values?.addressNumber,
					addressState: userUpdate?.addressState || values?.addressState,
				});
				if (props.onClose) {
					props.onClose();
				}
			} else {
				props.history.push(`/checkout/${business?.id}`, {
					payload,
					reservationUpdate: reservationUpdate,
					dados: dados,
					cep: userUpdate?.addressPostalCode || values?.addressPostalCode,
					neighborhood: userUpdate?.addressNeighborhood || values?.addressNeighborhood,
					address: userUpdate?.addressStreet || values?.addressStreet,
					// complement: userUpdate?.addressUnit || values?.addressUnit,
					city: userUpdate?.addressCity || values?.addressCity,
					numberA: userUpdate?.addressNumber || values?.addressNumber,
					currency: userUpdate?.addressState || values?.addressState,
					pix: pix,
					values,
					paymentCheck: true,
					answer: answer,
				});
			}
		} else {
			setOpen(!open);
		}
	};

	const footer = (submitForm) => {
		return (
			<Fragment>
				<div style={{ padding: "0 20px 20px 20px", width: "auto" }}>
					<Grid container justifyContent="center">
						<Grid item xs={12} container justify="center">
							<Button type="submit" className={classes.root} onClick={submitForm}>
								Salvar alterações
							</Button>
						</Grid>
					</Grid>
				</div>
			</Fragment>
		);
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				displayName: user?.displayName,
				email: user?.email,
				phoneNumber: user?.phoneNumber,
				addressPostalCode: userUpdate?.addressPostalCode,
				addressNeighborhood: userUpdate?.addressNeighborhood,
				addressStreet: userUpdate?.addressStreet,
				addressNumber: userUpdate?.addressNumber,
				// addressUnit: userUpdate?.addressUnit,
				addressCity: userUpdate?.addressCity,
				addressState: userUpdate?.addressState || "",
			}}
			validate={(values) => {
				const errors = {};

				if (!values.addressPostalCode) {
					errors.addressPostalCode = "Informe o CEP";
				} else if (!/^\d{5}-\d{3}$/i.test(values.addressPostalCode)) {
					errors.addressPostalCode = "CEP inválido";
				}

				return errors;
			}}
			onSubmit={(values) => {
				handleScheduleClick(values);
			}}>
			{({ submitForm, errors, touched, values, setFieldValue }) => (
				<div>
					<TitleContainer
						subtitle={
							<>
								<Typography className={classes.title}>Editar Cadastro</Typography>
								<Typography className={classes.subTitle}>Trocar Foto</Typography>
							</>
						}
						handleClick={() => setOpen(!open)}
						footer={footer(submitForm)}>
						<Form noValidate autoComplete="off" style={{ padding: "0 20px 20px 20px", width: "auto" }}>
							<div style={{ padding: "0 0 20px 0 " }}>
								<TextField
									name="displayName"
									value={user?.displayName || ""}
									type="text"
									fullWidth
									onChange={(e) => setFieldValue("displayName", e.target.value)}
								/>
							</div>
							<div style={{ padding: "0 0 20px 0 " }}>
								<TextField
									type="email"
									defaultValue={user?.email || ""}
									name="email"
									fullWidth
									onChange={(e) => setFieldValue("email", e.target.value)}
								/>
							</div>
							<div style={{ padding: "0 0 20px 0 " }}>
								<TextField
									type="text"
									name="phoneNumber"
									value={user?.phoneNumber || ""}
									fullWidth
									onChange={(e) => setFieldValue("phoneNumber", e.target.value)}
								/>
							</div>
							<div style={{ padding: "0 0 20px 0 " }}>
								<InputMask
									mask="99999-999"
									maskChar=" "
									defaultValue={userUpdate?.addressPostalCode}
									onChange={(e) => setFieldValue("addressPostalCode", e.target.value)}>
									{() => (
										<TextField
											type="text"
											name="addressPostalCode"
											label="CEP"
											style={{ width: "50%", padding: "0 10px 0 0" }}
										/>
									)}
								</InputMask>
								<TextField
									style={{ width: "50%" }}
									id="standard-required"
									label="Bairro"
									name="addressNeighborhood"
									defaultValue={userUpdate?.addressNeighborhood}
									type="text"
									// fullWidth
									onChange={(e) => {
										setFieldValue("addressNeighborhood", e.target.value);
										setNeighborhood(e.target.value);
									}}
								/>
							</div>
							<div style={{ padding: "0 0 0 0 " }}>
								<div>
									<FormError errors={errors.addressPostalCode} touched={touched.addressPostalCode} />
								</div>
								<div></div>
							</div>
							<div style={{ padding: "0 0 20px 0 " }}>
								<TextField
									id="standard-required"
									label="Endereço"
									type="text"
									name="addressStreet"
									defaultValue={userUpdate?.addressStreet}
									fullWidth
									onChange={(e) => setFieldValue("addressStreet", e.target.value)}
								/>
							</div>
							<div style={{ padding: "0 0 20px 0 " }}>
								<TextField
									style={{ width: "50%", padding: "0 10px 0 0" }}
									id="standard-required"
									label="Número"
									type="number"
									name="addressNumber"
									defaultValue={userUpdate?.addressNumber}
									// fullWidth
									onChange={(e) => setFieldValue("addressNumber", e.target.value)}
								/>
								<TextField
									style={{ width: "50%" }}
									id="standard-required"
									label="Cidade"
									type="text"
									fullWidth
									name="addressCity"
									defaultValue={userUpdate?.addressCity}
									onChange={(e) => setFieldValue("addressCity", e.target.value)}
								/>
							</div>
							<div style={{ padding: "0 0 20px 0 " }}>
								<TextField
									id="standard-select-currency"
									select
									name="addressState"
									label="Estado"
									defaultValue={userUpdate?.addressState}
									fullWidth
									onChange={(e) => setFieldValue("addressState", e.target.value)}>
									{states.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
								</TextField>
							</div>
						</Form>
						<ErrorDialog
							onOpen={open}
							title={t("Fill in the data correctly")}
							message={t("Fill in all fields")}
							onOk={handleOk}
						/>
					</TitleContainer>
				</div>
			)}
		</Formik>
	);
};

export default withRouter(Register);
