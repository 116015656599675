import Constants from "~/helpers/enums/Constants";

const initialState = {
	businessServices: null,
};

const businessServicesReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.GET_BUSINESS_SERVICE:
			return {
				...state,
				businessServices: action.payload.businessServices,
			};
		default:
			return state;
	}
};

export default businessServicesReducer;
