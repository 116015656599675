import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import VendorAddPet from "~/views/VendorAddPet";
import VendorCategorieScheduling from "~/views/VendorCategorieScheduling";
import VendorRegisteredPet from "~/views/VendorRegisteredPet";
import VendorServiceScheduling from "~/views/VendorServiceScheduling";

const VendorCategoryServices = ({ business }) => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const serviceId = queryParams.get("serviceId");
	const categoryId = queryParams.get("categoryId");
	const [selectedCategory, setSelectedCategory] = useState(
		categoryId
			? {
					categoty: {
						id: categoryId,
					},
			  }
			: null
	);
	const [showPetRegister, setShowPetRegister] = useState(false);
	const [selectedPet, setSelectedPet] = useState();

	return (
		<>
			{business &&
				(business?.sectorId !== process.env.REACT_APP_PET_SECTOR_ID || selectedPet ? (
					selectedCategory ? (
						<VendorServiceScheduling
							preSelectedServiceId={serviceId}
							selectedCategorie={selectedCategory}
							goBack={() => setSelectedCategory(null)}
							business={business}
						/>
					) : (
						<VendorCategorieScheduling
							selectCategory={(category) => setSelectedCategory(category)}
							selectedPet={selectedPet}
							business={business}
						/>
					)
				) : showPetRegister ? (
					<VendorAddPet goToPetSelection={() => setShowPetRegister(false)} />
				) : (
					<VendorRegisteredPet
						selectPet={(pet) => {
							setSelectedPet(pet);
						}}
						goToPetRegister={() => setShowPetRegister(true)}
					/>
				))}
		</>
	);
};
export default withRouter(VendorCategoryServices);
