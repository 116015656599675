import { Button, withStyles } from "@material-ui/core";

const ColorButton = withStyles((theme) => ({
	root: {
		color: "#ffffff",
		borderRadius: 25.5,
		width: 150,
		height: 44,
		fontSize: 12,
		position: "flex",
		backgroundColor: "#630094",
		"&:hover": {
			backgroundColor: "#7c00B9",
		},
	},
}))(Button);

export default ColorButton;
