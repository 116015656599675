import api from "~/services/api";
import dayjs from "~/config/dayjs";

const CONTROLLER_NAME_SERVICE_COUPONS = "coupons";
const CONTROLLER_NAME = "/business";
const CONTROLLER_NAME_COUPON = "/schedules";


export const getCouponBusiness = (id) => {
	return api.get(`${process.env.REACT_APP_URL_API_PAYOUT}${CONTROLLER_NAME_SERVICE_COUPONS}/business/${id}?page=1&length=15`);
};


export const getBusinessServiceCoupons = ( id, categoryId, couponId,feature_items) => {
	return api.get(`${process.env.REACT_APP_URL_API_PAYOUT}${CONTROLLER_NAME_SERVICE_COUPONS}/business/${id}/services`, {
		params: {
			category_id: categoryId,
			feature_items: feature_items,
			coupon_id: couponId,
		},
	});
};

export const getAvailableDatesCoupon = (businessId, services, coupon_id) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}${CONTROLLER_NAME_SERVICE_COUPONS}${CONTROLLER_NAME}/${businessId}/available_dates`, { services, coupon_id });
};

const INITIAL_PARAMS = {
	start_time: dayjs().startOf("day").utc().format(),
	end_time: dayjs().endOf("day").utc().format(),
	services: [],
	business_id: "",
};

export const postSearchCoupon = (params = INITIAL_PARAMS) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}coupons/business/${params.business_id}${CONTROLLER_NAME_COUPON}`, { ...params });
};
export default {
	getCouponBusiness,
	getBusinessServiceCoupons,
	getAvailableDatesCoupon,
	postSearchCoupon,
};
