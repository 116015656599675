import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { WhatsAppIcon } from "~/components/common/icons/Icons";

const WhatsAppButton = ({ bottom, top, rigth }) => {
	const { business } = useSelector((store) => store.business);
	return (
		<Fragment>
			{business && (new Date(Date.now()) - new Date(business.activatedAt)) / (1000 * 3600 * 24) < 15 && (
				<div
					style={{
						position: "sticky",
						bottom: bottom,
						top: top,
						width: "100%",
						display: "flex",
						justifyContent: "end",
						padding: "0 " + (rigth || "16px"),
					}}>
					<div
						onClick={() => {
							window.location = `${business.virtualAssistantLink}`;
						}}
						style={{
							width: "50px",
							height: "50px",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							borderRadius: "30px",
							color: "#fff",
							backgroundColor: "#4dc247",
							padding: "3px",
							fill: "white",
						}}>
						<WhatsAppIcon />
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default WhatsAppButton;
