import { Typography } from "@material-ui/core";
import React from "react";

const ScheduleProgress = (props) => {
	const { progress } = props;

	const a = [1, 2, 3, 4];

	return (
		<div style={{ display: "flex", alignItems: "center", margin: "0 20px" }}>
			{a.map((n) => {
				return (
					<>
						<div
							style={{
								backgroundColor: progress >= n ? "#630094" : "gray",
								padding: "4px 0",
								borderRadius: "100%",
								width: "30px",
								height: "30px",
								textAlign: "center",
								display: "flex",
								alignItems: "center",
							}}>
							<Typography style={{ fontSize: "18px", color: "white", textAlign: "center",width:"100%" }}>{n}</Typography>
						</div>
						{n != 4 && <div style={{ backgroundColor: progress >= n+1 ? "#630094" : "gray", height: "2px", flex: 1 }} />}
					</>
				);
			})}
		</div>
	);
};
export default ScheduleProgress;
