import Constants from "~/helpers/enums/Constants";

const initialState = {
	sectors: null,
};

const sectorsReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.GET_SECTORS:
			return {
				...state,
				sectors: action.payload.sectors,
			};
		default:
			return state;
	}
};

export default sectorsReducer;
