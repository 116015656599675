import { Button, Grid, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ErrorDialog from "~/components/common/dialog/index";
import FormError from "~/components/common/formError";
import { TextFieldComponent } from "~/components/common/formItem";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import loginActions from "~/store/actions/loginAction";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

function EmailSignIn(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [emailForget, setEmailForget] = useState("");

	const { business } = useSelector((store) => store.business);

	const { loading, openError, messageError, open, message, title } = useSelector((store) => store.login);

	const { t } = useTranslation();

	useEffect(() => {
		dispatch(loginActions.cleanLoginEmail());
	}, [dispatch]);

	const onClickGoBack = () => {
		props.history.goBack();
	};

	const handleOk = () => {
		dispatch(loginActions.setOpenDialog(false));
	};

	const handleOkError = () => {
		dispatch(loginActions.setOpenDialogError(false));
	};

	const goToEmailSignUp = () => {
		props.history.push("/login/email-sign-up");
	};

	const forgetPassword = () => {
		dispatch(loginActions.forgotLoginPassword(emailForget));
	};

	const signUpEmail = () => {
		return (
			<Grid item container justify="space-evenly" style={{ textAlign: "center", paddingTop: 20 }}>
				<Button className={classes.email} onClick={goToEmailSignUp}>
					<Typography component="body" style={{ fontSize: " 12px", color: "#3689ea" }}>
						<strong>Criar conta</strong>
					</Typography>
				</Button>
				<Button className={classes.email} onClick={forgetPassword}>
					<Typography component="body" style={{ fontSize: " 12px", color: "#3689ea" }}>
						<strong>Esqueceu a senha</strong>
					</Typography>
				</Button>
			</Grid>
		);
	};

	const footer = (submitForm) => {
		return (
			<>
				<Grid item style={{ paddingTop: 10, textAlign: "center" }}>
					<Button id="send-number" type="submit" className={classes.button} onClick={submitForm}>
						{t("go")}
					</Button>
				</Grid>
			</>
		);
	};

	return (
		<Formik
			initialValues={{ email: "", password: "" }}
			validate={(values) => {
				const errors = {};
				if (!values.email) {
					errors.email = "Preencha o e-mail";
				}
				if (!values.password) {
					errors.password = "Preencha a senha";
				}
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(false);
				dispatch(loginActions.loginEmailToAuthentication(values.email, values.password, business, props.history));
			}}>
			{({ submitForm, errors, touched, setFieldValue }) => (
				<TitleContainer
					loading={loading}
					handleClick={onClickGoBack}
					headerStyle={{ boxShadow: "none", paddingBottom: "0" }}
					footer={footer(submitForm)}>
					<Grid container direction="column" spacing={3}>
						<Grid item xs={8}>
							<Typography className={classes.title}>
								<strong>{t("Login with Email")}</strong>
							</Typography>
						</Grid>

						<>
							<Grid item style={{ margin: "10,0, 0,10", width: "100%", textAlign: "center" }}>
								<TextFieldComponent
									style={{ width: "70%", paddingLeft: "5px" }}
									name="email"
									placeholder="Digite seu email"
									onChange={(e) => {
										setEmailForget(e.target.value);
										dispatch(loginActions.setEmail(e.target.value));
										setFieldValue("email", e.target.value);
									}}
								/>
								<FormError errors={errors.email} touched={touched.email} />
							</Grid>
							<Grid item style={{ width: "100%", textAlign: "center" }}>
								<TextFieldComponent
									style={{ width: "70%", paddingLeft: "5px" }}
									name="password"
									type="password"
									placeholder="Digite sua senha"
									onChange={(e) => {
										setFieldValue("password", e.target.value);
									}}
								/>
								<FormError errors={errors.password} touched={touched.password} />
							</Grid>
						</>
					</Grid>
					{signUpEmail()}
					<Grid item style={{ textAlign: "center", paddingTop: 20 }}>
						<Typography
							style={{
								borderRadius: 11,
								paddingTop: 3,
								paddingLeft: 3,
								paddingRight: 3,
								paddingBottom: 3,
								backgroundColor: "#00000014",
								fontSize: " 14px",
							}}>
							Valide seu e-mail antes do login
						</Typography>
					</Grid>
					<Grid item style={{ textAlign: "center", paddingTop: 20 }}>
						<Typography
							component="body"
							style={{
								borderRadius: 11,
								paddingTop: 3,
								paddingBottom: 3,
								paddingLeft: 3,
								paddingRight: 3,
								backgroundColor: "#00000014",
								fontSize: " 14px",
							}}>
							Verifique se o e-mail está no lixo eletrônico ou spam
						</Typography>
					</Grid>
					<ErrorDialog onOpen={openError} message={messageError} onOk={handleOkError} />
					<ErrorDialog title={title} onOpen={open} message={message} onOk={handleOk} />
				</TitleContainer>
			)}
		</Formik>
	);
}

export default withRouter(EmailSignIn);
