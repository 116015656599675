import Constants from "~/helpers/enums/Constants";

const initialState = {
	userUpdate: null,
};

const userUpdateReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.USER_UPDATE:
			return {
				...state,
				userUpdate: action.payload.userUpdate,
			};
		default:
			return state;
	}
};

export default userUpdateReducer;
