import api from "~/services/api";

const CONTROLLER_NAME_FILTERED_FEATURE_ITEMS = "/filtered_feature_item";

//filtered_feature_item list
export const getFilteredFeatureItemList = (categoryId) => {
	return api.get(`${CONTROLLER_NAME_FILTERED_FEATURE_ITEMS}`, {
		params: {
			category_id: categoryId,
		},
	});
};

//filtered_feature_item add Pet
export const postFilteredFeatureItem = (categoryId, name, sex, feature_items) => {
	return api.post(`${CONTROLLER_NAME_FILTERED_FEATURE_ITEMS}`, {
		category_id: categoryId,
		name: name,
		sex: sex,
		feature_items: feature_items,
	});
};

//filtered_feature_item delete
export const delFilteredFeatureItem = (idPet) => {
	return api.delete(`${CONTROLLER_NAME_FILTERED_FEATURE_ITEMS}/${idPet}`);
};

export default {
	postFilteredFeatureItem,
	getFilteredFeatureItemList,
	delFilteredFeatureItem,
};
