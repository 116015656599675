//AuthReducer
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

//User update
export const USER_UPDATE = "USER_UPDATE";

//BusinessReducer
export const GET_BUSINESS = "GET_BUSINESS";
export const GET_BUSINESS_SERVICE = "GET_BUSINESS_SERVICE";
export const GET_SECTORS = "GET_SECTORS";
export const GET_FEATURE = "GET_FEATURE";
export const GET_FEATURE_ITEMS = "GET_FEATURE_ITEMS";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_CONTRACT = "GET_CONTRACT";
export const GET_BUSINESS_ID = "GET_BUSINESS_ID";
export const SET_BUSINESS = "SET_BUSINESS";

//ConfirmationReducer
export const GET_BOOKING_TRANSACTION = "GET_BOOKING_TRANSACTION";

//Coupon
export const GET_COUPON = "GET_COUPON";
export const GET_COUPON_SERVICE = "GET_COUPON_SERVICE";
export const GET_FEATURE_ITEMS_COUPON = "GET_FEATURE_ITEMS_COUPON";
export const GET_AVAILABLE_DATES_COUPON = "GET_AVAILABLE_DATES_COUPON";
export const GET_SEARCH_COUPON = "GET_SEARCH_COUPON";


//CheckoutReducer
export const GET_AMOUNT_CHECKOUT = "GET_AMOUNT_CHECKOUT";

//LoginReducer
export const SET_TELEPHONE = "SET_TELEPHONE";
export const SET_EMAIL = "SET_EMAIL";
export const SET_NAME = "SET_NAME";
export const LOADING_AUTHENTICATION = "LOADING_AUTHENTICATION";
export const CLEAN_LOGIN_REDUCER = "CLEAN_LOGIN_REDUCER";
export const CLEAN_LOGIN_EMAIL_REDUCER = "CLEAN_LOGIN_EMAIL_REDUCER";
export const LOGIN_DIALOG = "LOGIN_DIALOG";
export const LOGIN_DIALOG_ERROR = "LOGIN_DIALOG_ERROR";
export const TELEPHONE_COUNTRY_CODE = "TELEPHONE_COUNTRY_CODE";
export const GET_CONFIRMATION = "GET_CONFIRMATION";

//Cancellation
export const GET_CANCELLATION_POLICY = "GET_CANCELLATION_POLICY";

//ScheduleReducer
export const SAVE_SCHEDULE_DATA = "SAVE_SCHEDULE_DATA";
export const CLEAR_SCHEDULE_DATA = "CLEAR_SCHEDULE_DATA";

// Account bank
export const GET_BANK_ACCOUNT = "GET_BANK_ACCOUNT";
// PAYMENT-LIST
export const GET_PAYMENT_LIST = "GET_PAYMENT_LIST";

// Payment
export const GET_PAYMENT_PAY = "GET_PAYMENT_PAY";

export const GET_AVAILABLE_DATES = "GET_AVAILABLE_DATES";
export const GET_SEARCH = "GET_SEARCH";
export const BOOKING_TRANSACTION_STORE = "BOOKING_TRANSACTION_STORE";
export const BOOKING_TRANSACTION_STORE_FAILURE = "BOOKING_TRANSACTION_STORE_FAILURE";
export const BOOKING_TRANSACTION_STORE_LOAD = "BOOKING_TRANSACTION_STORE_LOAD";
export const HOURS_SELECTED = "HOURS_SELECTED";
export const CANCELLATION_REQUEST_STORE = "CANCELLATION_REQUEST_STORE";
export const GET_AMOUNT_CHECKOUT_ANTECIPATED = "GET_AMOUNT_CHECKOUT_ANTECIPATED";
export const ME = "ME";

// List Pet
export const GET_FILTERED_FEATURE_ITEM = "GET_FILTERED_FEATURE_ITEM";
export const GOOGLE_RESERVE_POST_SUCCESS = "GOOGLE_RESERVE_POST_SUCCESS"
export const GOOGLE_RESERVE_POST_FAILURE = "GOOGLE_RESERVE_POST_FAILURE"

export default {
	ME,
	LOGIN,
	LOGOUT,
	GET_BUSINESS,
	GET_BUSINESS_SERVICE,
	GET_FEATURE,
	GET_FEATURE_ITEMS,
	GET_CONTRACT,
	GET_SECTORS,
	GET_AMOUNT_CHECKOUT,
	GET_BOOKING_TRANSACTION,
	SET_TELEPHONE,
	SET_EMAIL,
	SET_NAME,
	LOADING_AUTHENTICATION,
	CLEAN_LOGIN_REDUCER,
	CLEAN_LOGIN_EMAIL_REDUCER,
	LOGIN_DIALOG,
	LOGIN_DIALOG_ERROR,
	TELEPHONE_COUNTRY_CODE,
	GET_CONFIRMATION,
	GET_CANCELLATION_POLICY,
	SAVE_SCHEDULE_DATA,
	CLEAR_SCHEDULE_DATA,
	GET_BANK_ACCOUNT,
	GET_PAYMENT_LIST,
	GET_PAYMENT_PAY,
	GET_AVAILABLE_DATES,
	GET_SEARCH,
	BOOKING_TRANSACTION_STORE,
	BOOKING_TRANSACTION_STORE_LOAD,
	BOOKING_TRANSACTION_STORE_FAILURE,
	USER_UPDATE,
	HOURS_SELECTED,
	CANCELLATION_REQUEST_STORE,
	GET_NOTIFICATIONS,
	GET_FILTERED_FEATURE_ITEM,
	GET_COUPON,
	GET_COUPON_SERVICE,
	GET_SEARCH_COUPON,
	GET_AVAILABLE_DATES_COUPON,
	GET_FEATURE_ITEMS_COUPON,
	GET_AMOUNT_CHECKOUT_ANTECIPATED,
	GET_BUSINESS_ID,
	GOOGLE_RESERVE_POST_SUCCESS,
	GOOGLE_RESERVE_POST_FAILURE
};
