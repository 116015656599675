import React from "react";
import { withRouter } from "react-router-dom";
import Confirmation from "~/components/confirmation";

function ConfirmationPage() {
    return (
            <Confirmation />
    );
}

export default withRouter(ConfirmationPage);
