import { IconButton, MuiThemeProvider, Typography } from "@material-ui/core";
import { ArrowBack, ArrowBackIos, ArrowForward, ArrowForwardIos } from "@material-ui/icons";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TimeOptionsDiv = ({ options, onSelect, selected, professional, professionalIndex, service, timeZone }) => {
	const [startIndex, setStartIndex] = useState(0);
	const { t } = useTranslation();

	useEffect(()=>{
		setStartIndex(0);
	},[options])

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "start",
				width: "100%",
				gap: "2%",
			}}>
			{!(startIndex == 0) && (
				<IconButton
					onClick={() => {
						if (startIndex > 0) {
							setStartIndex(startIndex - 4);
						}
					}}
					style={{
						color: "darkgray",
						backgroundColor: "#dddddd",
						borderRadius: "5px",
						width: "30px",
						height: "30px",
						display: "flex",
						justifyContent: "center",
					}}>
					<ArrowBackIos style={{ position: "relative", left: "3px", color: "#333333", padding: "4px" }} />
				</IconButton>
			)}

			{options.map((item, i) => {
				
				let isSelected = professional.id == selected?.professional?.id && item[0]?.start === selected?.time?.start;
				
				if (i >= startIndex && i < startIndex + 4) {
					return (
						<div
							onClick={() => {
								onSelect({ time: item[0], professional: professional, service: service });
							}}
							style={{
								color: isSelected ? "white" : "#333333",
								backgroundColor: isSelected ? "#450067" : "#dddddd",
								borderRadius: "5px",
								alignItems: "center",
								width: "20%",
								display: "flex",
								justifyContent: "center",
								height: "30px",
							}}
							// key={item.start[0]}
						>
							{dayjs(item[0]?.start).format("HH:mm")}
						</div>
					);
				}
			})}

			<IconButton
				onClick={() => {
					if (startIndex + 4 <= options.length) {
						setStartIndex(startIndex + 4);
					}
				}}
				style={{
					color: "darkgray",
					backgroundColor: "#dddddd",
					borderRadius: "5px",
					width: startIndex == 0 ? "70px" : "30px",
					height: "30px",
					display: startIndex + 4 > options.length - 1 ? "none" : "flex",
					justifyContent: "center",
				}}>
				{startIndex == 0 && <Typography style={{ color: "#333333", paddingLeft: "4px" }}>{t("More")}</Typography>}
				<ArrowForwardIos style={{ color: "#333333", padding: "4px" }} />
			</IconButton>
		</div>
	);
};

export default TimeOptionsDiv;
