import React, { useState } from "react";
import { Box, TextField, InputAdornment, IconButton, Typography, Card } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MuiStylesIndicationsPage from "~/components/indicationsPage/MuiStylesIndicationsPage";
import { withRouter } from "react-router-dom";
import Footer from "~/components/common/footerContainer/FooterContainer";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { useEffect } from "react";
import { getIndications } from "~/services/referearnService";

const IndicationCard = ({ name, status }) => {
	const classes = MuiStylesIndicationsPage();

	const statusColorClass = status === "Pagamento Realizado" ? classes.approved : classes.processing;

	return (
		<Card className={classes.indicationCard}>
			<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
				{name}
			</Typography>
			<Typography variant="body2">Bônus de indicação de R$30</Typography>
			<Typography variant="body2" className={statusColorClass}>
				{status}
			</Typography>
		</Card>
	);
};

const YourReferrals = (props) => {
	const classes = MuiStylesIndicationsPage();
	const [searchTerm, setSearchTerm] = useState("");
	const [indications, setIndications] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchUserIndications = async () => {
			setLoading(true);
			const userId = localStorage.getItem("@prit-web/userId");

			try {
				const response = await getIndications(userId);
				setIndications(response.data);
				setLoading(false);
			} catch (error) {
				setLoading(false);

				console.error("Erro ao buscar o hash gerado", error);
			}
		};

		fetchUserIndications();
	}, []);

	const handleBackClick = () => {
		props.history.push("/indicacoes");
	};

	const footer = () => {
		return <Footer section="indicacoes" />;
	};

	const filteredReferrals = (indications || [])?.filter((referral) =>
		referral.name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<Box>
			<TitleContainer loading={loading} headerStyle={{ boxShadow: "0", padding: "0" }} footer={footer()}>
				<IconButton onClick={handleBackClick} style={{ marginTop: "0.5rem", padding: "0", color: "#000" }}>
					<ArrowBackIosIcon />
				</IconButton>
				<Typography className={classes.title} style={{ marginTop: "1.5rem", fontSize: "1.3rem" }}>
					Suas indicações
				</Typography>
				<TextField
					className={classes.searchField}
					placeholder="Buscar indicações"
					variant="outlined"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton style={{ color: "#000" }}>
									<SearchIcon />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				{filteredReferrals?.length > 0 ? (
					filteredReferrals?.map((referral) => (
						<IndicationCard key={referral.name} name={referral.name} status={referral.payment_status} />
					))
				) : (
					<Typography style={{ marginTop: "1rem" }}>Não foram encontradas indicações para seu usuário</Typography>
				)}
			</TitleContainer>
		</Box>
	);
};

export default withRouter(YourReferrals);
