import api from "~/services/api";

export const getGeneratedHash = (userId) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}referearn/generate-hash`, { user_id: userId, is_user_id: true });
};
export const postGenerateTransfer = (hash, businessId) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}referearn/generate-transfer`, { hash_user_id: hash, recipient_id: businessId });
};
export const getIndications = (userId) => {
	return api.get(`${process.env.REACT_APP_URL_API_PAYOUT}referearn/indications/${userId}?isUserId=true`);
};

export const getMyEarnings = (userId) => {
	return api.get(`${process.env.REACT_APP_URL_API_PAYOUT}referearn/my-earnings/${userId}?isUserId=true`);
};

export default {
	getGeneratedHash,
	getIndications,
	postGenerateTransfer,
};
