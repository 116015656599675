import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
    actions: {
        justifyContent: 'center'
    },
    root: {
        color: "#3689ea",
        height: 44,
        margin: '0 3px 6px',
        fontSize: 12,
        fontWeight: "normal",
        textTransform: "none",
    },
    message: {
        fontSize: 14,
        lineHeight: "normal",
        letterSpacing: -0.31,
        color: "rgba(71, 71, 71, 0.5)",
        height: 88,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        textAlign: 'justify',
    },
    information: {
        height: "12px",
        margin: "20px 34px 0 0",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "rgba(71, 71, 71, 0.5)",
        float: "left",
    },
    antecipedPayment: {
        height: "12px",
        margin: "20px 34px 0 0",
        fontSize: "14px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#393762",
        float: "left",
    },
    value: {
        height: "12px",
        margin: "20px 20px 0 57px",
        fontSize: "12px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "right",
        color: "rgba(71, 71, 71, 0.5)",
        alignItems:"center"
    },
    businessName: {
        // height: "20px",
        margin: "4px 0 1px 55px",
        fontSize: "12px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "-0.27px",
        color: "#474747",
    },

    titlePoli: {
        textAlign: "center",
        fontSize: "10px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "-0.27px",
        color: '#f94040d9',
    },

    businessAddress: {
        margin: "0 0 1px 55px",
        fontSize: "12px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "-0.27px",
        color: "#888888",
    },

    finalValue: {
        height: "14px",
        margin: "20px 97px 0 0",
        fontSize: "10px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#3689ea",
        float: "left",
    },

    duration: {
		fontSize: 12,
		width: "100%",
		color: "rgba(71, 71, 71, 0.5)",
	},
	price: {
		fontSize: 14,
		color: "#3689ea",
		fontWeight: "bold",
	},
	priceDetailBlue: {
		color: "#3689ea",
		fontSize: 12,
		fontWeight: " bold",
	},
	priceDetail: {
		color: "rgba(71, 71, 71, 0.5)",
		fontSize: 12,
		fontWeight: " bold",
	},

    // dialogPixConfirm
    titlePixConfirm: {
        fontSize: "16px",
        fontWeight: 500,
        margin: 'auto',
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "-0.27px",
        color: "rgba(71, 71, 71, 83)",
    },

    inforBooking: {
        margin: "4px 0 1px 55px",
        fontSize: "12px",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "-0.27px",
        color: "#1c18188f",
    },

    // dialogScheduleConfirm
    titleScheduleConfirm: {
        fontSize: "16px",
        fontWeight: 500,
        margin: '0 20px',
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "-0.27px",
        color: "rgba(71, 71, 71, 83)",
        maxWidth: 150 
    }
}));