import React from "react";
import _ from "lodash";
import { Helmet } from "react-helmet";

const SEO = ({ business, page }) => {
	
	const webAppName = "Prit";
	const thumbnailUrl = business?.thumbnailUrl ?? "";
	const businessUrl = `${process.env.REACT_APP_URL}/business`;
	const canonicalLink = `${businessUrl}/${business?.id ? business.id.trim() : ""}`;
	
	let title = "Business";
	let author = "";
	let businessName = "Seu Estabelecimento"
	
	if (!_.isNull(business?.name) && !_.isEmpty(business?.name)) {
		businessName = business.name.trim();
	}
	
	if (!_.isNull(page) && !_.isEmpty(page)) {
		title = `${businessName} - ${page} - ${webAppName}`;
	} else {
		title = `${businessName} - ${webAppName}`;
	}
	
	let description = `${businessName}, seu lugar para agendar online com praticidade. Reserve seu horário e aproveite um atendimento de qualidade.`;

	if (!_.isNull(business?.description) && !_.isEmpty(business?.description)) {
		description = business.description.trim();
	}

	if (!_.isNull(business?.professionals) && !_.isEmpty(business?.professionals) && !_.isEmpty(business?.professionals[0]?.name)) {
		author = business.professionals[0].name.trim();
	}

	const ldJson = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [
			{
				"@type": "ListItem",
				"position": 1,
				"item": {
					"@id": process.env.REACT_APP_URL,
					"name": webAppName
				}
			},
			{
				"@type": "ListItem",
				"position": 2,
				"item": {
					"@id": businessUrl,
					"name": "Business"
				}
			},
			{
				"@type": "ListItem",
				"position": 3,
				"item": {
					"@id": canonicalLink,
					"name": businessName
				}
			}
		]
	};

	return (
		<Helmet>
			{ /* Standard metadata tags */ }
			<title>{title}</title>
			<link rel="canonical" href={canonicalLink} />
			<meta name='description' content={description} />
			<meta name="author" content={author} />
			<meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
			<meta name="robots" content="noai, noimageai" />
			{ /* End standard metadata tags */ }
			{ /* Facebook tags */ }
			<meta property="og:url" content={canonicalLink} />
			<meta property="og:title" content={title} />
			<meta property="og:type" content="business" />
			<meta property="og:image" content={thumbnailUrl} />
			<meta property="og:image:width" content="152" />
			<meta property="og:image:height" content="190" />
			<meta property="og:image:type" content="image/jpeg" />
			<meta property="og:site_name" content="Prit"></meta>
			<meta property="og:description" content={description} />
			{ /* End Facebook tags */ }
			<script type="application/ld+json">
				{JSON.stringify(ldJson)}
			</script>
		</Helmet>
	);
};

export default SEO;
