import { createMuiTheme } from "@material-ui/core";

const MuiThemeCustomCardHeader = (theme) =>
	createMuiTheme({
		...theme,
		overrides: {
			MuiIconButton: {
				root: {
					"&:hover": {
						backgroundColor: "#ffffff",
					},
				},
			},
			MuiCardHeader: {
				content: {
					height: "-webkit-fill-available",
					display: "flex",
					alignItems: "flex-end",
				},
			},
		},
	});

export default MuiThemeCustomCardHeader;
