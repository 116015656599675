import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import CustomCarousel from "~/components/common/customCarousel/CustomCarousel";
import FacebookButton from "~/components/common/facebookButton/FacebookButton";
import InstagramButton from "~/components/common/instagramButton/InstagramButton";
import ProfessionalDiv from "~/components/common/professionalDiv/ProfessionalDiv";
import SectionDiv from "~/components/common/sectionDiv/SectionDiv";
import SiteButton from "~/components/common/siteButton/SiteButton";
import TwitterButton from "~/components/common/twitterButton/TwitterButton";
import MuiStylesVendorDescription from "~/components/vendorPage/vendorDescription/MuiStylesVendorDescription";
import Utils from "~/helpers/Utils";

const VendorDescription = ({ business }) => {
	const { t } = useTranslation();
	const [photos, setPhotos] = useState([]);
	const classes = MuiStylesVendorDescription();
	const businessProfessionals = business?.professionals;

	useEffect(() => {
		if (business !== null) {
			setPhotos(business?.photos);
		}
	}, [business]);

	return (
		<Grid container direction="column">
			
			<SectionDiv
				title={t("Sobre nós")}
				counter={photos?.length}
				className={classes.slider}
				titleStyle={{ fontSize: "15px", fontWeight: "500" }}>
				<Grid item>
				<ShowMoreText
					lines={7}
					more="ler mais"
					less="ler menos"
					className={classes.descriptionText}
					anchorClass={classes.readMore}>
					<p>{business?.description ? business.description : t("Without description")}</p>
				</ShowMoreText>
			</Grid>
			</SectionDiv>
			<SectionDiv
				title={t("Portfólio")}
				counter={photos?.length}
				className={classes.slider}
				titleStyle={{ fontSize: "15px", fontWeight: "500" }}>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<CustomCarousel>
						{photos &&
							photos
								.sort((a, b) => a.order - b.order)
								.map((item, index) => {
									return (
										<div key={item.id} style={{ marginRight: "5px", backgroundColor: "transparent" }}>
											<img src={item.url} alt={`${index}`} className={classes.image} />
										</div>
									);
								})}
					</CustomCarousel>
				</div>
			</SectionDiv>
			<SectionDiv title={t("Social networks")} titleStyle={{ fontSize: "15px", fontWeight: "500" }}>
				<Grid container direction="row">
					{business?.social?.desktop && (
						<Grid item>
							<SiteButton link={`${business.social.desktop}`} />
						</Grid>
					)}
					{business?.social?.facebook && (
						<Grid item>
							<FacebookButton link={`https://www.facebook.com/${Utils.trimString(business.social.facebook)}`} />
						</Grid>
					)}
					{business?.social?.instagram && (
						<Grid item>
							<InstagramButton link={`https://www.instagram.com/${Utils.trimString(business.social.instagram)}/`} />
						</Grid>
					)}
					{business?.social?.twitter && (
						<Grid item>
							<TwitterButton link={`https://www.twitter.com/${Utils.trimString(business.social.twitter)}/`} />
						</Grid>
					)}
				</Grid>
			</SectionDiv>
			<SectionDiv title={t("Professionals")} titleStyle={{ fontSize: "15px", fontWeight: "500" }}>
				{businessProfessionals ? (
					businessProfessionals.map((item, index) => (
						<ProfessionalDiv professional={item} index={index} key={`professional-${index}`} />
					))
				) : (
					<p>Não existem profissionais</p>
				)}
			</SectionDiv>
		</Grid>
	);
};

export default withRouter(VendorDescription);
