import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	textName: {
		width: "-webkit-fill-available",
		paddingTop: 30,
		fontFamily: "CircularStd",
		fontSize: "16px",
		fontStyle: "normal",
		color: " #00000071",
		float: "left",
		fontWeight: "bold",
	},
}));
