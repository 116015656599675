import { signInAnonymously, PhoneAuthProvider, signInWithCredential } from "firebase/auth";
const { Firebase } = require("./firebase");


export const authenticateAnonymousUser = () => {
	const auth = Firebase;
	const anonymousUser = signInAnonymously(auth);
	return anonymousUser;
};

export const getCredential = (verificationId, code) => {
	return PhoneAuthProvider.credential(verificationId, code);
};

export const authenticateUser = async (credential) => {
	const auth = Firebase;
	return await signInWithCredential(auth, credential);
};

export default { authenticateAnonymousUser, getCredential, authenticateUser };
