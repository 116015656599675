import axios from "axios";
import { store } from "~/store";

const api = axios.create({
	baseURL: process.env.REACT_APP_URL_API,
	headers: {
		"content-type": "application/json",
	},
});

api.interceptors.request.use(async (config) => {
	let accessToken = null;
	const authUser  = store.getState().auth?.user;
	
	if (authUser) {
		accessToken = authUser.accessToken;

		localStorage.setItem("@prit-web/accessToken", accessToken);
		localStorage.setItem("@prit-web/accessTokenExpirationTime", authUser.expirationTime);

		config.headers.Authorization = `Bearer ${accessToken}`;

	} else {
		accessToken = localStorage.getItem("@prit-web/accessToken");
		
		const expirationTime = localStorage.getItem("@prit-web/accessTokenExpirationTime");
		const isTokenExpired = Date.now() > new Date(expirationTime)
		
		if (accessToken && !isTokenExpired) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}
	}

	return config;
});

export default api;
