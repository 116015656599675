import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";
import TitleDialog from "~/components/modalBookingScreen/TitleDialog";
import useStyles from "./styles";


const DialogCancel = ({ message, onOpen, onClose, img, text }) => {
	const classes = useStyles();

	return (
		<Dialog
			onClose={onClose}
			open={onOpen}
			PaperProps={{
				style: { borderRadius: 20, maxWidth: 312 },
			}}>
			<DialogTitle>{<TitleDialog onClose={onClose} />}</DialogTitle>
			<div className={classes.container}>
				<div className={classes.imgContainerCancel}>
					<img className={classes.img} src={img} alt="" />
				</div>
			</div>
			<DialogContent style={{ padding: "0 0" }}>
				<DialogContentText className={classes.messageCancel}>{message}</DialogContentText>
				<DialogContentText style={{
					fontSize: 12,
					color: '#888888',
					padding: '15px',
					textAlign: 'center'
				}}>
					{text}
				</DialogContentText>
			</DialogContent>
			{/* <DialogActions className={classes.actions}>
				<Button className={classes.button} onClick={onOk}>
					Confirmar Cancelar
				</Button>
			</DialogActions> */}
		</Dialog>
	);
};

export default DialogCancel;
