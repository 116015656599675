import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		background: "rgba(255,255,255,0.7)",
	},
}));

const SpinnerLoading = (props) => {
	const { loading } = props;
	const classes = useStyles();
	return (
		<Backdrop className={classes.backdrop} open={loading || false}>
			<CircularProgress />
		</Backdrop>
	);
};

export default SpinnerLoading;
