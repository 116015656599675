import Constants from "~/helpers/enums/Constants";

const initialState = {
	cancellationPolicy: null,
};

const cancellationPolicyReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.GET_CANCELLATION_POLICY:
			return {
				...state,
				cancellationPolicy: action.payload.cancellationPolicy,
			};
		default:
			return state;
	}
};

export default cancellationPolicyReducer;
