import React, { Fragment, useContext, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Button, Grid, TextField, Typography } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { DialogBuilder } from "~/utils/DialogProvider";
import filteredFeatureItemService from "~/services/filteredFeatureItemService";
import ErrorDialog from "~/components/common/dialog/index";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import PurpleButton from "~/components/common/purpleButton/PurpleButton";

const VendorAddPet = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const [namePet, setNamePet] = useState("");
	const [idBreed, setIdBreed] = useState();
	const [idType, setIdType] = useState("");
	const [nameType, setNameType] = useState("");
	const [sex, setSex] = useState("");

	const { business } = useSelector((store) => store.business);

	const dialog = useContext(DialogBuilder);
	const [openError, setOpenError] = useState(false);
	const [openErrorNamePet, setOpenErrorNamePet] = useState(false);

	const sectors = JSON.parse(localStorage.getItem("@prit-web/sectors"));

	const servicesCoupon = props?.servicesCoupon;
	const goToPetSelection = props?.goToPetSelection;

	const alphabeticalOrder = (a, b) => {
		if (a.name < b.name) {
			return -1;
		}
		return 1;
	};

	const filterCategorieList = () => {
		return sectors[0].categories.filter((category) =>
			servicesCoupon?.category_ids.some((categoryId) => categoryId === category.id)
		);
	};
	const filterOrder = filterCategorieList().sort(alphabeticalOrder)[0];

	const breed = () => {
		var arr = [];
		let categorie = sectors[0].categories;
		let features = servicesCoupon ? filterOrder.features : categorie[0].features;
		features.map((featureI) => {
			if (featureI.name === "Raça") {
				arr = featureI.featureItems;
			}
		});
		return arr;
	};
	const species = () => {
		var arr = [];
		let categorie = sectors[0].categories;
		let features = servicesCoupon ? filterOrder.features : categorie[0].features;
		features.map((featureI) => {
			if (featureI.name.replaceAll(" ", "") === "Espécie") {
				arr = featureI.featureItems;
			}
		});
		return arr;
	};

	const petSex = [
		{ value: "M", label: "Macho" },
		{ value: "F", label: "Fêmea" },
	];

	const breedOptions = breed().map((breed) => {
		return { value: breed.id, label: breed.name };
	});

	const speciesOptions = species().map((species) => {
		return { value: species.id, label: species.name };
	});

	const handleChangeType = (e) => {
		setIdType(e.value);
		setNameType(e.label);
	};
	const handleChangeRaca = (e) => {
		setIdBreed(e.value);
	};
	const handleChangeSex = (e) => {
		setSex(e.value);
	};

	const handleOk = () => {
		setOpenError(false);
		setOpenErrorNamePet(false);
	};

	const handleClick = async () => {
		if (!namePet?.length>0 || !idType?.length>0 || !sex?.length>0 || ((nameType.split(" ").join("") === "Cachorro") && (!idBreed || idBreed?.length==0)) || business?.categories.length  == 0) {
			setOpenErrorNamePet(!openErrorNamePet);
		} else {
			try {
				await filteredFeatureItemService.postFilteredFeatureItem(business?.categories[0].id, namePet, sex, [idType, idBreed]);
				goToPetSelection();
			} catch (err) {
				setOpenError(true);
				goToPetSelection();
			}
		}
	};

	return (
		<Fragment>
			<div style={{ padding: "20px" }}>
				<TextField
					fullWidth
					id="outlined-basic"
					label={t("Qual o nome do seu Pet?")}
					variant="outlined"
					onChange={(e) => setNamePet(e.target.value)}
				/>
				<Typography className={classes.textName} style={{ marginTop: "20px" }}>
					{t("Sexo do Pet")}
				</Typography>
				<Select options={petSex} onChange={handleChangeSex} />
				<Typography className={classes.textName} style={{ marginTop: "20px" }}>
					{t("Tipo do Pet")}
				</Typography>
				<Select options={speciesOptions} onChange={handleChangeType} />
				{nameType.split(" ").join("") === "Cachorro" ? (
					<Fragment>
						<Typography className={classes.textName} style={{ marginTop: "20px" }}>
							{t("Raça")}
						</Typography>
						<Select options={breedOptions} onChange={handleChangeRaca} />
					</Fragment>
				) : (
					[]
				)}
			</div>
			<div style={{ padding: "20px", width: "auto" }}>
				<Grid container justify="center">
					<Grid item xs={12} container justify="center">
						<PurpleButton
							style={{
								backgroundColor: "#630094",
								cursor: "unset",
								margin: "10px",
								padding: "7px",
								color: "#ffffff",
								height: 44,
								fontSize: "15px",
								display: "flex",
								flex: 1,
								width: "100%",
								maxWidth: "400px",
							}}
							variant="contained"
							onClick={handleClick}>
							{t("Next")}
						</PurpleButton>
					</Grid>
				</Grid>
			</div>
			<ErrorDialog
				onOpen={openError}
				message={t("The pet ") + namePet + t(" has already registred!")}
				onOk={handleOk}
			/>
			<ErrorDialog
				onOpen={openErrorNamePet}
				message={t("You do not fill informations about the Pet")}
				onOk={handleOk}
			/>
		</Fragment>
	);
};

export default withRouter(VendorAddPet);
