import { makeStyles } from "@material-ui/core";

const MuiStylesVendorPage = makeStyles((theme) => ({
	mainGrid: {
		width: "-webkit-fill-available",
		height: "-webkit-fill-available",
	},
	background: {
		height: "100%",
		width: "100%",
		top: 0,
		background: "##96C4A8",
	},
	descriptionTextAddress: {
		color: "#BDBDBD",
		fontSize: "14px",
		fontWeight: 400,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.57,
		letterSpacing: "-0.31px",
	},
	padding: {
		padding: 10,
	},
}));

export default MuiStylesVendorPage;
