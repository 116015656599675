import { Avatar, Divider, IconButton } from "@material-ui/core";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import TitleContainer from "../common/titleContainer/TitleContainer";
import DogCat from "assets/icons/dogCat.png";
import Del from "assets/icons/del.png";
import AddPet from "assets/icons/addPet.png";
import useStyles from "./styles";
import SpinnerLoading from "~/components/common/spinnerLoading/SpinnerLoading";
import filteredFeatureItem from "~/services/filteredFeatureItemService";
import { DialogBuilder } from "~/utils/DialogProvider";
import { useTranslation } from "react-i18next";

const RegisteredPet = (props) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const dialog = useContext(DialogBuilder);
	const { business } = useSelector((store) => store.business);

	const [verification, setVerification] = useState(true);
	const [listPets, setListPets] = useState([]);
	const [loading, setLoading] = useState(true);

	const servicesCoupon = props?.location?.state?.servicesCoupon;

	const handleScheduleClick = () => {
		props.history.push(`/adicionar-pet/${business?.id}`, { servicesCoupon: servicesCoupon });
	};

	const handleScheduleClickServices = (item) => {
		props.history.push(`/selecionar-categoria/${business.id}`, { feature_items: item, servicesCoupon: servicesCoupon });
	};

	const handleDeleteClick = async (item) => {
		try {
			await filteredFeatureItem.delFilteredFeatureItem(item.id);
			setVerification(true);
			setListPets(listPets.filter((pet)=>item.id!==pet.id))
		} catch (error) {
			dialog.withTitle(t("Erro tentar deletar o Pet")).show();
		}
	};
	const filteredItem = async () => {
		try {
			const sectors = JSON.parse(localStorage.getItem("@prit-web/sectors"));
			const res = await filteredFeatureItem.getFilteredFeatureItemList(sectors[0].categories[0]?.id);
			setListPets(res.data);
			setLoading(false);
			setVerification(false);
		} catch (error) {
			dialog.withTitle(t("Erro tentar ao carregar os Pets")).withAction(() => props.history.goBack()).show();
		}
	};

	useEffect(() => {
		if (verification) {
			filteredItem();
		}
	}, []);

	return (
		<Fragment>
			<SpinnerLoading loading={loading} />
			<TitleContainer
				styles={"80%"}
				title={"Pets"}
				subtitle={t("Selecionar Pets Cadastrados")}
				handleClick={() => props.history.push(`/business/${business.id}`)}>
				<div style={{ padding: 20 }}>
					{listPets?.map((item) => {
						return (
							<Fragment key={item.name}>
								<div>
									<div style={{ display: "flex", alignItems: "center", height: "90px" }}>
										<Avatar style={{ display: "inline-flex", width: 80, height: 80 }} alt={item.name} src={DogCat} />
										<div className={classes.namePet} onClick={() => handleScheduleClickServices(item)}>
											{item.name}
										</div>
										<IconButton
											style={{ cursor: "pointer" }}
											color="primary"
											aria-label="upload picture"
											component="span"
											onClick={() => handleDeleteClick(item)}>
											<img alt={"Delete"} src={Del} />
										</IconButton>
									</div>
									<Divider />
								</div>
							</Fragment>
						);
					})}
				</div>
				{!loading && (
					<div className={classes.containerAddPet}>
						<IconButton
							style={{ cursor: "pointer" }}
							color="primary"
							aria-label="upload picture"
							onClick={() => handleScheduleClick()}>
							<img alt={"Add"} src={AddPet} />
						</IconButton>
						<div className={classes.newPet}>{t('New Pet')}</div>
					</div>
				)}
			</TitleContainer>
		</Fragment>
	);
};

export default withRouter(RegisteredPet);
