import axios from "axios";

const apiTwilio = axios.create({
	baseURL: process.env.REACT_APP_URL_API,
	headers: {
		"content-type": "application/json",
	},
});

apiTwilio.interceptors.request.use(async (config) => {
	config.headers.Authorization = process.env.REACT_APP_TOKEN_SMS_TWILIO;
	return config;
});

export default {
    apiTwilio,
};
