import { makeStyles } from "@material-ui/core";

const MuiStylesHeaderContainer = makeStyles((theme) => ({
	header: {
		height: "4px",
		margin: "0 0 35px",
		padding: "22px 0 0",
		backgroundColor: "#ffffff",
	},

	logo: {
		filter: "brightness(0.5)",
		width: "2.6em",
		margin: "0 20px 16px 20px",
	},

	dividerLogo: {
		backgroundColor: "#64b3f4",
		width: "80px",
		margin: "-5px 304px 0 0",
	},

	bookEPayContainer: {
		width: " 137px",
		height: "31px",
		margin: "-17px 20px 0 0",
		padding: "10px 18px 8px 16px",
		border: " solid 1.3px #f0f0f0",
		borderRadius: "inherit",
		backgroundColor: "#ffffff",
		float: "right",
	},

	datePickeIcon: {
		color: "#474747",
		width: "1em",
		display: "flex",
		margin: "-2px 0 0 -6px",
	},

	creditIcon: {
		color: "#474747",
		width: "1em",
		display: "flex",
		margin: "-14px 0 0 95px",
	},

	nameBookPay: {
		fontSize: "14px",
		fontFamily: "SFUIText",
		fontWeight: "600",
		color: "#474747",
		margin: "-18px 0 0 16px",
		float: "left",
	},
}));

export default MuiStylesHeaderContainer;
