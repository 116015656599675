import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React, { createContext } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const DialogBuilder = createContext();
export class DialogManager {
	constructor(setDialog) {
		this.setDialog = setDialog;
	}
	setDialog;
	open = false;
	title = "Ops...";
	message = useTranslation().t("Please try again later");
	buttonText = "ok";
	content;
	props = {};
	onClose = () => {};
	action = () => {};
	withTitle = (title) => {
		this.title = title;
		return this;
	};
	withMessage = (message) => {
		this.message = message;
		return this;
	};
	withButtonText = (buttonText) => {
		this.buttonText = buttonText;
		return this;
	};
	withAction = (action) => {
		this.action = action;
		return this;
	};
	withContent = (content) => {
		this.content = content;
		return this;
	};
	withProps = (props) => {
		this.props = props;
		return this;
	};
	withOnClose = (onClose) => {
		this.onClose = onClose;
		return this;
	};
	show = () => {
		this.open = true;
		this.setDialog(this);
	};
	close = () => {
		this.open = false;
		this.setDialog(this);
	};
	toggle = () => {
		this.open = !this.open;
		this.setDialog(this);
	};
}
export const DialogProvider = ({ Component }) => {
	const [dialogManager, setDialogManager] = useState(new DialogManager());
	return (
		<>
			<DialogBuilder.Provider value={new DialogManager(setDialogManager)}>
				<Component />
			</DialogBuilder.Provider>
			<BuildedDialog dialog={dialogManager} setDialog={setDialogManager} />
		</>
	);
};
export const BuildedDialog = ({ dialog, setDialog }) => {
	if (dialog) {
		dialog.withOnClose(() => setDialog(dialog.close()));
	}else{
		return <></>
	}
	return (
		<Dialog
			// onClose={() => setDialog(dialog.close())}
			open={dialog?.open}
			PaperProps={{
				style: { borderRadius: 30, maxHeight: "60vh", maxWidth: 312 },
			}}>
			{dialog?.content ? (
				dialog.content({ onClose: () => setDialog(dialog.close()), ...dialog?.props })
			) : (
				<>
					<DialogTitle
						onClose={() => setDialog(dialog.close())}
						style={{
							textAlign: "center",
						}}>
						{dialog?.title}
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							style={{
								textAlign: "center",
							}}>
							{dialog?.message}
						</DialogContentText>
					</DialogContent>
					<DialogActions style={{ justifyContent: "center" }}>
						<Button
							style={{ color: "#3689ea", textTransform: "none" }}
							onClick={() => {
								setDialog(dialog.close());
								dialog.action();
							}}>
							{dialog?.buttonText}
						</Button>
					</DialogActions>
				</>
			)}
		</Dialog>
	);
};
