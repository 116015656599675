import Constants from "~/helpers/enums/Constants";

const initialState = {
	checkoutInformation: null,
};

const checkoutReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.GET_AMOUNT_CHECKOUT:
			return {
				...state,
				checkoutInformation: action.payload.checkoutInformation,
			};
		default:
			return state;
	}
};

export default checkoutReducer;
