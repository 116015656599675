import Constants from "~/helpers/enums/Constants";

const initialState = {
  features: null,
};

const servicesReducer = (state = initialState, action = null) => {
  switch (action.type) {
    case Constants.GET_FEATURE:
      return {
        ...state,
        features: action.payload.features,
      };
    default:
      return state;
  }
};

export default servicesReducer;
