import React, { useState, useContext } from "react";
import { Button, Grid, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import InputMask from "react-input-mask";
import { withRouter } from "react-router-dom";
import useStyles from "./styles";
import { DialogBuilder } from "~/utils/DialogProvider";
import userUpdateService from "~/services/userUpdateService";
import checkoutServices from "~/services/checkoutServices";
import Utils from "~/helpers/Utils";
import { useHistory, useLocation } from "react-router-dom";

function PersonalInformationNumber(props) {
    const classes = useStyles();
    const dialog = useContext(DialogBuilder);
    const history = useHistory();
    const location = useLocation(); 

    const [phone, setPhone] = useState("");
    const [loading, setLoading] = useState(false);
    const [ddiIndex, setPhoneDdiIndex] = useState(0);

    const returnRoute = location.state?.goBackRoute;

    const next = () => {
        history.replace(returnRoute);
    };

    const handleChangeType = (e) => {
        setPhoneDdiIndex(e.target.value);
    };

    const handleChange = (e) => {
        Utils.telephoneMask(e, Utils.ddis[ddiIndex].prefix);
		setPhone(e.target.value)
    };

    const submitForm = async () => {
        setLoading(true);

        let unmaskedPhone = phone.replace(/\D/g, "");
        unmaskedPhone = `+55${unmaskedPhone}`;

        try {
            await userUpdateService.pachUserUpdate(localStorage.getItem("@prit-web/userId"), {
                phoneUnverified: unmaskedPhone,
            });
        
            const res = await checkoutServices.me();
        
            if (res?.data?.id) {
                localStorage.setItem("@prit-web/meData", JSON.stringify(res.data));
                localStorage.setItem("@prit-web/userId", res.data.id);
            }
        
            next();
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message;
            dialog.withMessage(`Houve um erro ao cadastrar seu número de telefone. ${errorMessage}`).show();
        } finally {
            setLoading(false);
        }
    };

    return (
        <Grid container direction="column" spacing={3} style={{ width: "auto" }}>
            <Grid item xs={10}>
                <Typography style={{ margin: '5%' }} className={classes.title}>
                    <strong>Informe seu número</strong>
                </Typography>
            </Grid>

            <Grid container item spacing={2} alignItems="center" style={{ paddingLeft: 20, paddingTop: 30, width: "inherit" }}>
                <Grid item>
                    <Select
                        style={{
                            width: "100px",
                            color: "#8f8e8e"
                        }}
                        value={ddiIndex}
                        onChange={handleChangeType}>
                        {Utils.ddis.map((e, i) => (
                            <MenuItem key={i} value={i}>
                                <img style={{ width: "20px", paddingRight: "8px" }} src={e.img} alt={"Bandeira" + e.prefix} />
                                {e.ddi}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs>
                    <InputMask
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    >
                        {(inputProps) => (
                            <TextField
                                {...inputProps}
                                inputProps={{
                                    onChange: (e) => {
                                        handleChange(e);
                                    },
                                }}
                                style={{paddingBottom: '0%'}}
                                className={classes.field}
                                placeholder="Número de telefone"
                                fullWidth
                            />
                        )}
                    </InputMask>
                </Grid>
            </Grid>

            <Grid item container style={{ paddingTop: 10, justifyContent: "center" }}>
                <Button
                    className={classes.button}
                    onClick={submitForm}
                    disabled={loading}
                >
                    Avançar
                </Button>
            </Grid>
        </Grid>
    );
}

export default withRouter(PersonalInformationNumber);
