import React, { Fragment, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { TextField, Typography } from "@material-ui/core";
import TitleContainer from "../common/titleContainer/TitleContainer";
import Footer from "~/components/footer";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

const AddPet = (props) => {
	const classes = useStyles();

	const { t } = useTranslation();

	const [namePet, setNamePet] = useState("");
	const [idBreed, setIdBreed] = useState();
	const [idType, setIdType] = useState("");
	const [nameType, setNameType] = useState("");
	const [sex, setSex] = useState("");

	const { business } = useSelector((store) => store.business);

	const sectors = JSON.parse(localStorage.getItem("@prit-web/sectors"));
	const servicesCoupon = props?.location?.state?.servicesCoupon;

	const alphabeticalOrder = (a, b) => {
		if (a.name < b.name) {
			return -1;
		}
		return 1;
	};

	const filterCategorieList = () => {
		return sectors[0].categories.filter((category) =>
			servicesCoupon?.category_ids.some((categoryId) => categoryId === category.id)
		);
	};
	const filterOrder = filterCategorieList().sort(alphabeticalOrder)[0];

	const breed = () => {
		var arr = [];
		let categorie = sectors[0].categories;
		let features = servicesCoupon ? filterOrder.features : categorie[0].features;
		features.map((featureI) => {
			if (featureI.name === "Raça") {
				arr = featureI.featureItems;
			}
		});
		return arr;
	};
	const species = () => {
		var arr = [];
		let categorie = sectors[0].categories;
		let features = servicesCoupon ? filterOrder.features : categorie[0].features;
		features.map((featureI) => {
			if (featureI.name.replaceAll(" ","") === "Espécie") {
				arr = featureI.featureItems;
			}
		});
		return arr;
	};

	const petSex = [
		{ value: "M", label: "Macho" },
		{ value: "F", label: "Fêmea" },
	];

	const breedOptions = breed().map((breed) => {
		return { value: breed.id, label: breed.name };
	});

	const speciesOptions = species().map((species) => {
		return { value: species.id, label: species.name };
	});

	const handleChangeType = (e) => {
		setIdType(e.value);
		setNameType(e.label);
	};
	const handleChangeRaca = (e) => {
		setIdBreed(e.value);
	};
	const handleChangeSex = (e) => {
		setSex(e.value);
	};

	return (
		<Fragment>
			<TitleContainer
				styles={"70%"}
				title={business.name}
				subtitle={t("Precisamos conhecer melhor o seu Pet")}
				handleClick={() => props.history.goBack()}
				footer={
					<Footer
						categoryId={servicesCoupon ? filterOrder.id : sectors[0].categories[0].id}
						namePet={namePet}
						type={idType}
						breed={idBreed}
						verifyBreed={nameType.split(" ").join("") === "Cachorro"}
						sex={sex}
						varificationSave={true}
						servicesCoupon={servicesCoupon}
						nameButton={t("Salvar")}
					/>
				}>
				<div style={{ padding: 20 }}>
					<TextField
						fullWidth
						id="outlined-basic"
						label={t("Qual o nome do seu Pet?")}
						variant="outlined"
						onChange={(e) => setNamePet(e.target.value)}
					/>
					<Typography className={classes.textName}>{t('Sexo do Pet')}</Typography>
					<Select options={petSex} onChange={handleChangeSex} />
					<Typography className={classes.textName}>{t('Tipo do Pet')}</Typography>
					<Select options={speciesOptions} onChange={handleChangeType} />
					{nameType.split(" ").join("") === "Cachorro" ? (
						<Fragment>
							<Typography className={classes.textName}>{t('Raça')}</Typography>
							<Select options={breedOptions} onChange={handleChangeRaca} />
						</Fragment>
					) : (
						[]
					)}
				</div>
			</TitleContainer>
		</Fragment>
	);
};

export default withRouter(AddPet);
