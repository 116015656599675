import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    title: {
        width: "auto",
        height: "108px",
        margin: "52px 35px 56px 35px",
        fontFamily: "CircularStd",
        fontSize: "28px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.29",
        letterSpacing: " -0.46px",
        textAlign: "center",
        color: " #393762",
    },

    containerSmart: {
        textAlign: "center",
    },

    smartPhone: {

        margin: "22px 33px",
        padding: "15px 0 0",
    },

    containerItems: {
        marginTop: "-382px",
    },

    logoPrit: {
        padding: "51px 0 16px 0",
    },

    containerName: {
        margin: "0 116px",
    },

    textApp: {
        fontFamily: "SFUIText",
        fontSize: "20px",
        fontWeight: "500",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: " -0.44px",
        textAlign: "center",
        color: " #474747",
    },

    posicao: {
        margin: "0 0 0 -135px",
    },

    footer: {
        textAlign: "center",
        height: "142px",
        width: "-webkit-fill-available",
        marginTop: "38px",
        justifyContent: "center",
        padding: " 44px 88px",
        display: "inline-flex",
        border: " solid 1px #eaeaea",
        backgroundColor: " #fafafa",
    },

    linkplayStore: {
        width: "135px",
        height: "48px",
        margin: "0 10px 0 0",
        backgroundColor: "#474747",
        display: "inline",
        float: "left",
        borderRadius: "4px",
        textDecoration: "none",
    },

    linkAppStore: {
        width: "135px",
        height: "48px",
        backgroundColor: "#474747",
        borderRadius: "4px",
        textDecoration: "none",
        position: "absolute",
    },

    linkIcon: {
        color: " #64b3f4",
        padding: "1px",
        margin: "10px  0 0 15px",
        float: "left",
    },

    nameAppLink: {
        fontSize: "13px",
        textDecoration: "none",
        margin: "7px",
        color: " #ffffff",
    },
}));
