import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	rectangle: {
		width: "335px",
		height: "88px",
		margin: "20px 20px 12px",
		padding: "8px 16px",
		borderRadius: "5px",
		backgroundColor: "#39376208",
		display: "inline-flex",
	},
	nameService: {
		paddingRight: "32px",
		fontWeight: "bolder",
		color: "#00000047",
	},
	expiration: {
		paddingRight: "22px",
		color: "#00000047",
	},
	infor: {
		margin: "9px",
		width: "100%",
	},
}));
