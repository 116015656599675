import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";

// const sectors = JSON.parse(localStorage.getItem("@prit-web/sectors"));

const styles = makeStyles((theme) => ({
	selectedCategoryRectangle: {
		height: "28px",
		backgroundColor: "rgb(57 55 98 / 7%)",
		display: "flex",
		margin: "0 4px 15px 0",
		borderRadius: "14px",
		alignItems: "center",
		justifyContent: "space-around",
		flexDirection: "row",
	},
	selectedCategory: {
		fontSize: "14px",
		fontWeight: 300,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.33,
		letterSpacing: -0.4,
		borderBottom: " none",
		display: "flex",
		margin: 0,
	},
	selectedColor: {
		backgroundColor: "#450067",
		color: "#fff",
	},
	unselectedColor: {
		color: "#450067",
		// backgroundColor: "#fff",
	},
	selectedColorSvg: {
		fill: "#fff",
	},
	unselectedColorSvg: {
		fill: "#450067",
	},
}));

const FeaturesSelected = (props) => {
	const { features, currentIndex, setCurrentIndex } = props;
	const classes = styles();

	return (
		<div style={{ display: "flex", flexWrap: "wrap" }}>
			{features.map((item, index) => {
				return (
					<div
						key={`nameSelected-${index}`}
						className={clsx({
							[classes.selectedCategoryRectangle]: true,
							[classes.selectedColor]: currentIndex == index,
							[classes.unselectedColor]: currentIndex != index,
						})}
						onClick={() => setCurrentIndex(index)}
						id="featuresDiv"
						name="featuresDiv">
						<div style={{ margin: "0 10px" }} id="featureName" name="featureName">
							<h1
								className={clsx({
									[classes.selectedCategory]: true,
									[classes.selectedColor]: currentIndex == index,
									[classes.unselectedColor]: currentIndex != index,
								})}
								id="featureName"
								name="featureName">
								{item.serviceName !== undefined ? item.serviceName : ''}
							</h1>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default FeaturesSelected;
