import { makeStyles } from "@material-ui/core";

const MuiStylesVendorDescription = makeStyles((theme) => ({
	descriptionText: {
		color: "rgba(71, 71, 71, 0.5)",
		fontSize: "14px",
		fontWeight: 400,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.57,
		letterSpacing: "-0.31px",
		whiteSpace: "break-spaces",
	},
	readMore: {
		color: "#3689ea",
		textDecoration: "none",
	},
	image: {
		borderRadius: "14px",
		width:'auto', /* you can use % */
    	height: 'auto',
		boxShadow: "0 4px 12px -10px rgba(0,0,0,0.2)",
	},

	slider:{
		position:'relative',
		height:'auto',
		display:'flex',
		justifyContent: 'center',
	}
}));

export default MuiStylesVendorDescription;
