import { Button, Grid } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import ErrorDialog from "~/components/common/dialog/index";
import filteredFeatureItem from "~/services/filteredFeatureItemService";
import useStyles from "./styles";
import { useContext } from "react";
import { DialogBuilder } from "~/utils/DialogProvider";

const Footer = (props) => {
	const classes = useStyles();

	const { categoryId, namePet, breed, nameButton, varificationSave, type, sex, servicesCoupon, verifyBreed } = props;

	const { business } = useSelector((store) => store.business);
	const { t } = useTranslation();
	const dialog = useContext(DialogBuilder);
	const [openError, setOpenError] = useState(false);
	const [openErrorNamePet, setOpenErrorNamePet] = useState(false);

	const feature_items = [type, breed];

	const handleOk = () => {
		setOpenError(false);
		setOpenErrorNamePet(false);
	};

	

	const handleClick = async () => {
	
		if (namePet === "" || type === "" || sex === "" || (verifyBreed && breed === "")) {
			setOpenErrorNamePet(!openErrorNamePet);
		} else {
			if (varificationSave) {
				try {
					await filteredFeatureItem.postFilteredFeatureItem(categoryId, namePet, sex, feature_items);
					props.history.push(`/pets-cadastrados/${business.id}`, { servicesCoupon: servicesCoupon });
				} catch (err) {
					setOpenError(true);
				}
			}
		}
	};

	return (
		<Fragment>
			<div style={{ padding: "0 20px 20px 20px", width: "auto" }}>
				<Grid container justify="center">
					<Grid item xs={12} container justify="center">
						<Button onClick={handleClick} className={classes.root}>
							{nameButton ? nameButton : t("Next")}
						</Button>
					</Grid>
				</Grid>
			</div>
			<ErrorDialog onOpen={openError} message={t("The pet ")+namePet+t(" has already registred!")} onOk={handleOk} />
			<ErrorDialog
				onOpen={openErrorNamePet}
				message={t("You do not fill informations about the Pet")}
				onOk={handleOk}
			/>
		</Fragment>
	);
};

export default withRouter(Footer);
