import Constants from "~/helpers/enums/Constants";

const initialState = {
	paymentList: null,
};

const paymentListReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.GET_PAYMENT_LIST:
			return {
				...state,
				paymentList: action.payload.paymentList,
			};
		default:
			return state;
	}
};

export default paymentListReducer;
