import Constants from "~/helpers/enums/Constants";

const initialState = {
	featureItemList: null,
};

const filteredFeatureItemReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.GET_FILTERED_FEATURE_ITEM:
			return {
				...state,
				featureItemList: action.payload.featureItemList,
			};
		default:
			return state;
	}
};

export default filteredFeatureItemReducer;
