import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	you: {
		fontWeight: 800,
		fontSize: "150%",
		width: "50%",
		textAlign: "center",
		borderStyle: "none none solid none",
		borderWidth: "2px",
	},
	header: {
		display: "flex",
		flexDirection: "row",
		padding: "5px",
	},
	news: {
		display: "flex",
		flexDirection: "row",
		padding: "5px",
		margin: "10px 0",
	},
	newsLabel: {
		marginLeft: "70px",
		fontWeight: "bold",
	},
	count: {
		color: "#3689ea",
		backgroundColor: "#D6EAF8",
		fontWeight: "bold",
		borderRadius: "30px",
		textAlign: "center",
		padding: "0 5px",
		marginLeft: "10px",
	},
	button: {
		marginRight: 0,
		marginLeft: "auto",
		color: "#3689ea",
		textTransform: "none",
	},
	button: {
		marginRight: 0,
		marginLeft: "auto",
		color: "#3689ea",
		textTransform: "none",
	},
	reminder: {
		display: "flex",
		flexDirection: "row",
		padding: "5px",
	},
	icon: {
		margin: "auto 30px auto 20px",
		transform: "scale(1.5)",
	},
	noNotifications: {
		paddingTop: "50px",
		color: "lightgray",
		textAlign: "center",
		fontSize: "150%",
	},
	title: {
		fontWeight: "bold",
		fontSize: "100%",
	},
	message: {
		fontSize: "90%",
		fontWeight: 400,
	},
	time: {
		color: "lightgray",
		fontSize: "100%",
		fontWeight: 100,
	},
	notify: {
		marginRight: 0,
		marginLeft: "auto",
		color: "#3689ea",
		textTransform: "none",
	},
	modalTitle: {
		color: "#3689ea",
		fontSize: 20,
		fontWeight: " bold",
		padding: "25px 15px"
	},
	closeModal: {
		marginTop: "25px",
		marginRight: "20px",
		marginLeft: "auto",
		transform: "scale(1.5)",
		color: "lightgray"
	},
	success:{
		padding: "30px",
		textAlign: "center",
	},
	messageError:{
		color:"red",
		padding: "30px",
		textAlign: "center",
	},
	messageButton:{
		color:"#3689ea",
		width: "100%",
		textAlign: "center",
		padding: "20px 0",
		fontSize: 16,
	},
	duration: {
		fontSize: 12,
		width: "100%",
		color: "rgba(71, 71, 71, 0.5)",
	},
	price: {
		fontSize: 16,
		color: "#3689ea",
		fontWeight: "bold",
		padding:"0 20px",
	},
	priceDetailBlue: {
		color: "#3689ea",
		fontSize: 14,
		fontWeight: " bold",
		padding:"0 20px",
	},
	priceDetail: {
		color: "rgba(71, 71, 71, 0.5)",
		fontSize: 14,
		fontWeight: " bold",
		padding:"0 20px",
	},
	paymentDetail: {
		color: "#393762",
		fontSize: 14,
		fontWeight: 'bold',
		padding:"0 20px",
	},
	root: {
		marginTop:"10px",
		backgroundColor: "#3689ea",
		color: "#ffffff",
		height: 44,
		width: 335,
		fontSize: 12,
		fontWeight: "normal",
		textTransform: "none",
		borderRadius: 0,
		"&:hover": {
			backgroundColor: "#106ba3",
		},
	},
	titlePayConfirm:{
		color: "#3689ea",
		fontSize: 18,
		fontWeight: " bold",
		padding: "25px 15px"
	},
	titlePoli: {
        textAlign: "center",
        fontSize: "15px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "-0.27px",
        color: '#f94040d9',
		padding: "10px",
    },
	infoPoli: {
        textAlign: "center",
        fontSize: "10px",
        fontWeight: 500,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "1.33",
        letterSpacing: "-0.27px",
        color: '#f94040d9',
		padding: "0 20px 20px 20px",
    },
	information: {
        height: "12px",
        margin: "20px 34px 0 0",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "rgba(71, 71, 71, 0.5)",
        float: "left",
    },
    value: {
        height: "12px",
        margin: "20px 20px 0 57px",
        fontSize: "12px",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "right",
        color: "rgba(71, 71, 71, 0.5)",
    },
	copyButton: {
		borderRadius: 20,
		backgroundColor: "#3689ea",
		color: "#ffffff",
		fontSize: 12,
		fontWeight: "normal",
		textTransform: "none",
		"&:hover": {
			backgroundColor: "#106ba3",
		},
	},
	pixCode: {
		textAlign: "center",
		padding: "10px 30px",
	}
}));
