import Constants from "~/helpers/enums/Constants";

const initialState = {
	bookingTransaction: null,
};

const bookingTransactionReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.GET_BOOKING_TRANSACTION:
			return {
				...state,
				bookingTransaction: action.payload.bookingTransaction,
			};
		default:
			return state;
	}
};

export default bookingTransactionReducer;
