import { Avatar, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import MuiStylesCardBusiness from "./MuiStylesCardBusiness";
import businessService from "~/services/businessService";
import React, { useState, useEffect } from "react";

const CardBusiness = () => {
	const { business } = useSelector((store) => store.business);
	const classes = MuiStylesCardBusiness();

	const [businessToShow, setBusinessToShow] = useState(null);

	useEffect(() => {
		if (business) {
			businessService.getBusiness(business.id).then(({ data }) => {
				setBusinessToShow(data);
			});
		} else {
			setBusinessToShow(business);
		}
	}, [business]);

	return (
		<div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
			<Avatar
				variant="rounded"
				style={{
					height: "50px",
					width: "50px",
					alignSelf: "center",
				}}
				src={business?.thumbnailUrl ? business.thumbnailUrl : ""}
			/>
			<div style={{padding: "10px"}}>
				<Typography className={classes.name}>{businessToShow ? businessToShow.name : ""}</Typography>
				<Typography className={classes.street}>{businessToShow ? businessToShow.address?.street : ""}</Typography>
			</div>
		</div>
	);
};

export default CardBusiness;
