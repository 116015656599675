import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import TitleDialog from "~/components/modalBookingScreen/TitleDialog";
import { PritmanCheck } from "../icons/Icons";
import useStyles from "./styles";


const DialogText = ({ message, onOpen, onClose, img, title,pritman, onClick, footer }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Dialog
			onClose={onClose}
			open={onOpen} 
			PaperProps={{
				style: { borderRadius: 20, maxHeight: 244, maxWidth: 312 },
			}}>
			<DialogTitle style={{ padding: "16px 24px 0" }}>{<TitleDialog onClose={onClose} message={title || t("Appointment details")} />}</DialogTitle>
			{img&&<div className={classes.container}>
				<div className={classes.imgContainer}>
					<img className={classes.img} src={img} alt="" />
				</div>
			</div>}
			{pritman&&<div className={classes.container}>	
					<PritmanCheck></PritmanCheck>
			</div>}
			<DialogContent>
				<DialogContentText className={classes.message}>{message || ""}</DialogContentText>
				{footer && footer()}
			</DialogContent>

		</Dialog>
	);
};

export default DialogText;
