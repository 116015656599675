import { makeStyles } from "@material-ui/core";

const MuiStylesIndicationsPage = makeStyles((theme) => ({
	mainGrid: {
		width: "-webkit-fill-available",
		height: "-webkit-fill-available",
		padding: "0.5rem",
		"& .gift-icon": {
			height: "40px",
			width: "40px",
			marginBottom: "1rem",
		},
	},
	background: {
		height: "100%",
		width: "100%",
		top: 0,
		background: "##96C4A8",
	},
	buttonList: {
		padding: "0.5rem 0",
		margin: "0.25rem 0",
		borderBottom: "1px solid #B5B5B5",
	},
	iconList: {
		color: "#000",
		display: "flex",
		justifyContent: "end",
	},

	copyLinkButton: {
		width: "180px",
		height: "50px",
		padding: "10px",
		borderRadius: "10px",
		alignItems: "end",
		// backgroundColor: "#FFFFFF",
		// color: "#3688EB",

		backgroundColor: "#3688EB",
		color: "#FFFFFF",
		textTransform: "none",
		transition: "opacity 0.3s ease",

		fontWeight: "unset",
	},
	shareLinkButton: {
		width: "180px",
		fontWeight: "unset",
		height: "50px",
		padding: "10px",
		borderRadius: "10px",
		alignItems: "end",

		textTransform: "none",
		backgroundColor: "#3688EB",
		color: "#FFFFFF",
		transition: "opacity 1s ease !important",
	},
	title: {
		color: "#4C4C4C",
		fontSize: "1.8rem",
		lineHeight: "1.2",
		letterSpacing: "-0.66px",
		fontWeight: "bold",
		marginBottom: "0.5rem",
	},
	subtitle: {
		color: "#949494",
		fontSize: "1rem",
		lineHeight: "1.3",
		marginBottom: "0.5rem",
		fontWeight: "normal",
	},

	yourEarningsTitle: {
		color: "#4C4C4C",
		fontSize: "20px",
		fontWeight: 700,
		marginBottom: "2rem",
		marginTop: "rem",
	},
	bankDataText: {
		color: "#4C4C4C",
		fontSize: "20px",
		fontWeight: 700,
	},
	earningBlock: {
		color: "#949494",
		fontSize: "14px",
		borderBottom: "1px solid #B5B5B5",
		paddingBottom: "10px",
		marginBottom: "10px",
	},
	bankDetailsCard: {
		width: "100%",
		height: "84px",
		padding: "12px",
		borderRadius: "15px",
		flexDirection: "row",
		boxShadow: "0px 0px 6px 0px #00000040",
	},
	editLink: {
		color: "#3688EB",
		fontSize: "14px",
		fontWeight: 500,
		textDecoration: "none",
		cursor: "pointer",
		marginTop: "5px",
	},

	searchField: {
		width: "100%",
		marginTop: "0.5rem",

		borderRadius: "100px",
		boxShadow: "1px 0px 6px 2px #00000024",
		"& .MuiInputBase-root": {
			borderRadius: "100px",
		},

		marginBottom: "2rem",
	},
	indicationCard: {
		width: "100%",
		boxShadow: "0px 0px 6px 0px #00000040",
		marginBottom: "1rem",
		padding: "10px 14px",
		borderRadius: "10px",
		display: "flex",
		flexDirection: "column",
	},
	processing: {
		color: "#EC6F25",
		fontWeight: "bold",
	},
	approved: {
		color: "#56B35A",
		fontWeight: "bold",
	},

	sectionTitle: {
		color: "#4C4C4C",
		fontWeight: "bold",
		marginBottom: "10px",
	},
	sectionContent: {
		color: "#949494",
		marginBottom: "14px",
	},
	tips: {
		color: "#F06D6C",

		marginTop: "4px",
	},
	numberCircle: {
		width: "30px",
		height: "30px",
		borderRadius: "50%",
		backgroundColor: "#fff",
		color: "#000",
		border: "1px solid black",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		fontSize: "1rem",
		fontWeight: "bold",
		marginBottom: "1rem",
		marginTop: "1.5rem",
	},

	digitBeforeInput: {
		"& .MuiInputLabel-root": {
			color: "black",
			fontWeight: "bold",
			fontSize: "0.85rem",
			minWidth: "180px",
		},
		"& .MuiInputBase-input": {
			width: "108px",
		},
	},

	digitInput: {
		"& .MuiInputLabel-root": {
			color: "black",
			fontWeight: "bold",
			fontSize: "0.85rem",
			minWidth: "180px",
			marginLeft: "16px",
		},
		"& .MuiInputBase-input": {
			width: "20px",
			textAlign: "center",
		},
	},
}));

export default MuiStylesIndicationsPage;
