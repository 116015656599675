import Constants from "~/helpers/enums/Constants";
import businessService from "~/services/businessService";

const getContract = (businessId) => (dispatch) => {
	businessService
		.getContract(businessId)
		.then(({ data }) => {
			dispatch({ type: Constants.GET_CONTRACT, payload: { contract: data } });
		})
		.catch((err) => {});
};
export default { getContract };
