import Constants from "~/helpers/enums/Constants";

const initialState = {
	coupons: null,
	availableDatesCoupon: null,
};

const couponsBusinessReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.GET_COUPON:
			return {
				...state,
				coupons: action.payload.coupons,
			};
		case Constants.GET_AVAILABLE_DATES_COUPON:
			return {
				...state,
				availableDatesCoupon: action.payload.availableDatesCoupon,
			};
		default:
			return state;
	}
};

export default couponsBusinessReducer;
