import { makeStyles } from "@material-ui/core";

const MuiThemeSelectScheduleTime = makeStyles((theme) => ({
	
	scrollSelectedArea: {
		marginTop: "20px",
		display: "flex",
		overflowX: "auto",
		"&::-webkit-scrollbar": {
			width: 0,
		},
	},

	countSelected: {
		width: "20px",
		height: "20px",
		margin: "12px 0 12px 0",
		padding: "0 2px 2px 2px",
		borderRadius: "5px",
		textAlign: "center",
		color: "#fff",
		backgroundColor: " #630094",
	},

	nameCountServices: {
		width: "122px",
		height: "32px",
		margin: " 6px 35px 6px 8px",
		fontSize: "14px",
		fontWeight: " 300",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.14,
		letterSpacing: "-0.47px",
		color: " #474747",
		padding: "1px",
		borderBottom: " none",
		display: "flex",
	},

	noneServices: {
		marginTop: "50px",
		color: " #474747",
		textAlign: "center",
		width: "100%",
	},
	
	info: {
		color: " #474747",
		marginTop: "50px",
		width: "100%",
		textAlign: "center",
	},

	selectDateLabel: { 
		fontSize: "18px", 
		fontWeight: "bold" 
	},
}));

export default MuiThemeSelectScheduleTime;
