import api from "~/services/api";

export const stripeKey = process.env.REACT_APP_STRIPE_KEY;

export const postPaymentPay = (body) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}payment/pay`, body);
};

export const postAdvancedPaymentPay = (body) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}payment/pay/single-advancedpay`, body);
};

export const postPaymentRefund = (data) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}payment/refund`, data);
};

//stripe

export const postAdvancedPayIntent = (body) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}payment/stripe/pay/single-advancedpay`, body);
};

export const postPayIntent = (body) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}payment/stripe/pay-intent`, body);
};

export default {
	postPaymentPay,
	postPaymentRefund,
	postAdvancedPaymentPay,
	postPayIntent,
	postAdvancedPayIntent,
};
