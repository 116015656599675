import dateLib from "dayjs";
import "dayjs/locale/pt-br";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dateLib.locale("pt-br");
dateLib.extend(AdvancedFormat);
dateLib.extend(CustomParseFormat);
dateLib.extend(isBetween);
dateLib.extend(isSameOrAfter);
dateLib.extend(isSameOrBefore);
dateLib.extend(relativeTime);
dateLib.extend(utc);
dateLib.extend(timezone);

const dayjs = dateLib;

export default dayjs;
