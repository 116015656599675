import { makeStyles } from "@material-ui/core";

const MuiStylesTitleContainer = makeStyles((theme) => ({
	header: {
		// boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.08)",
		padding: "4%",
	},

	title: {
		fontFamily: "SFUIText",
		fontSize: "14px",
		fontWeight: 300,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		textAlign: "center",
		color: "#474747",
	},

	subtitle: {
		marginTop: "2%",
		width: "60%",
		fontFamily: "SFUIText",
		fontSize: "17px",
		fontWeight: 900,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		color: "rgba(71, 71, 71, 0.82)",
	},
	container: {
		height: "auto",
		minHeight: "100vh",
		maxWidth: "425px",
		margin: "auto",
		padding: 0,
		display: "flex",
		flexDirection: "column",
	},
}));

export default MuiStylesTitleContainer;
