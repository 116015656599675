import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";


const DialogError = ({ message, onOpen, onClose, onOk, title }) => {
	const classes = useStyles();
	return (
		<Dialog
			onClose={onClose}
			open={onOpen}
			PaperProps={{
				style: { borderRadius: 30, maxHeight: 244, maxWidth: 312 },
			}}>
			<DialogTitle className={classes.text}>{title || "Ocorreu um erro"}</DialogTitle>
			<DialogContent>
				<DialogContentText className={classes.text}>{message}</DialogContentText>
			</DialogContent>
			<DialogActions className={classes.actions}>
				<Button className={classes.button} onClick={onOk}>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DialogError;
