import Constants from "~/helpers/enums/Constants";

const initialState = {
	me: null,
};

const meReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.ME:
			return {
				...state,
				me: action.payload.me,
			};
		default:
			return state;
	}
};

export default meReducer;
