import { makeStyles } from "@material-ui/core";

const MuiStylesFooterContainer = makeStyles((theme) => ({
	footer: {
		width: " -webkit-fill-available",
		height: "55px",
		objectFit: "contain",
		borderTop: "1px solid #0000000d",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	nameContainer: {
		fontSize: 10,
		margin: "-4px -10px",
		// position: "absolute",
	},
	button: {
		width: "25%",
		display: "initial",
	},
	buttonWithCoupon: {
		width: "20%",
		display: "initial",
	},
}));

export default MuiStylesFooterContainer;
