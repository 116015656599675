import React from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import MuiStylesIndicationsPage from "~/components/indicationsPage/MuiStylesIndicationsPage";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import Footer from "~/components/common/footerContainer/FooterContainer";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const HowIndicationsWork = (props) => {
	const classes = MuiStylesIndicationsPage();
	const footer = () => {
		return <Footer section="indicacoes" />;
	};

	const handleBackClick = () => {
		props.history.push("/indicacoes");
	};
	return (
		<>
			<TitleContainer loading={false} headerStyle={{ boxShadow: "0", padding: "0" }} footer={footer()}>
				<IconButton onClick={handleBackClick} style={{ marginTop: "0rem", padding: "0", color: "#000" }}>
					<ArrowBackIosIcon />
				</IconButton>
				<Typography variant="h5" className={classes.sectionTitle} style={{ marginTop: "1.5rem", fontSize: "1.3rem" }}>
					Como funcionam as indicações
				</Typography>

				<Box marginBottom="1rem">
					<div className={classes.numberCircle}>1</div>
					<Typography className={classes.sectionTitle}>Indique seus amigos</Typography>
					<Typography className={classes.sectionContent}>
						Você compartilha o seu link com outros profissionais para que eles baixem o aplicativo.
					</Typography>
					<Typography className={classes.tips}>DICAS:</Typography>
					<Typography className={classes.tips}>- Envie mensagens de incentivo;</Typography>
					<Typography className={classes.tips}>- Poste nos seus stories frequentemente;</Typography>
					<Typography className={classes.tips}>- Deixe o link fixado na sua bio do Instagram;</Typography>
					<Typography className={classes.tips}>- Crie publicações falando sobre o Indique e Ganhe</Typography>
				</Box>

				<Box marginBottom="1rem">
					<div className={classes.numberCircle}>2</div>
					<Typography className={classes.sectionTitle}>Ganhe dinheiro</Typography>
					<Typography className={classes.sectionContent}>
						Para cada nova assinatura vinda da sua indicação, você ganha uma bonificação de R$30,00.
					</Typography>
				</Box>

				<Box marginBottom="1rem">
					<div className={classes.numberCircle}>3</div>
					<Typography className={classes.sectionTitle}>Acompanhe</Typography>
					<Typography className={classes.sectionContent}>
						Acompanhe os seus ganhos e continue incentivando seus amigos!
					</Typography>
				</Box>
			</TitleContainer>
		</>
	);
};

export default withRouter(HowIndicationsWork);
