import React from "react";
import { Typography } from "@material-ui/core";
import { ExitIcon } from "~/components/common/icons/Icons";
import MuiStylesModalReservations from "./MuiStylesModalReservations";

const TitleDialog = ({ onClose, message }) => {
	const classes = MuiStylesModalReservations();

	return (
		<Typography className={classes.title}>
			{message || ""}
			<ExitIcon
				style={{ cursor: "pointer", float: "right" }}
				onClick={() => {
					if (onClose) {
						onClose();
					}
				}}
			/>
		</Typography>
	);
};

export default TitleDialog;
