import api from "~/services/api";

const CONTROLLER_NAME = "/booking_transaction";
const CONTROLLER_NAME_BOOKING_STATUS = "booking";

export const postBookingTransaction = (body) => {
	return api.post(CONTROLLER_NAME, body);
};
export const getBookingTransaction = (id) => {
	return api.get(`${CONTROLLER_NAME}/?user_id=${id}`);
};
export const getBusinessBookingTransaction = (userId, businessId, page, per_page = 10) => {
	return api.get(`${CONTROLLER_NAME}/?user_id=${userId}&business_id=${businessId}&page=${page}&per_page=${per_page}`);
};
export const putBookingStatus = (id, reason, status, cancellationReason, confirmReservation) => {
	return api.put(`${CONTROLLER_NAME_BOOKING_STATUS}/${id || ""}`, {
		booking: {
			status,
			reason,
			cancellationReason,
			confirmReservation,
		},
	});
};

export const putBookingTransaction = (id, payment_status, cancellationReason) => {
	return api.put(`${CONTROLLER_NAME}/${id || ""}`, {
		payment_status: payment_status || "cancelled",
		cancellation_reason: cancellationReason || "payment_error",
	});
};

export const getBookingRescheduleValidByBookingId = (id, config) => {
	return api.get(
		"booking/reschedule_valid/",
		{
			params: { booking_id: id },
		},
		config
	);
};

export const putBookingUpdateSchedule = ({ bookingId, data }) => {
	return api.put(`booking/${bookingId}/update_schedule`, data);
};

export const getOneBookingTransaction = (id) => {
	return api.get(`booking_transaction/${id}`);
};
export const publishBooktranscation = (id) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}bookingtransaction/publish/prit-pay/${id}`);
};
export default {
	postBookingTransaction,
	getBookingTransaction,
	putBookingStatus,
	getBookingRescheduleValidByBookingId,
	putBookingUpdateSchedule,
	getOneBookingTransaction,
	publishBooktranscation,
	putBookingTransaction,
};
