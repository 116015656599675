import Constants from "~/helpers/enums/Constants";

const setUser = (user) => (dispatch) => {
	dispatch({ type: Constants.LOGIN, payload: { user: user, isAuthenticated: !user.isAnonymous } });
};

const logoutUser = () => (dispatch) => {
	dispatch({ type: Constants.LOGOUT });
};

export default { setUser, logoutUser };
