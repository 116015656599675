import Constants from "~/helpers/enums/Constants";

const initialState = {
	bankAccount: null,
};

const bankAccountReducer = (state = initialState, action = null) => {
	switch (action.type) {
		case Constants.GET_BANK_ACCOUNT:
			return {
				...state,
				bankAccount: action.payload.bankAccount,
			};
		default:
			return state;
	}
};

export default bankAccountReducer;
