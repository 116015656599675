import { Avatar, CardHeader, ThemeProvider, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import MuiStylesCustomCardHeader from "~/components/common/customCardHeader/MuiStylesCustomCardHeader";
import MuiThemeCustomCardHeader from "~/components/common/customCardHeader/MuiThemeCustomCardHeader";

const CustomCardHeader = ({business}) => {
	const classes = MuiStylesCustomCardHeader();
	const photos = business?.photos;

	const coverPhoto = () => {
		if (!business?.photos[0]) return false;
		const found = photos.find((photo) => photo.order === 1) || photos[0];
		return found.url;
	};

	return (
		<ThemeProvider theme={MuiThemeCustomCardHeader}>
			<div style={{display:"flex", justifyContent:"center",flexDirection:"column"}}>
				<CardHeader
					className={classes.header}
					style={{
						background: `url(${coverPhoto()}) center/100% no-repeat`,
						display: "flex",
						justifyContent: "center",
						height: "100px",
					}}
				/>
				<Avatar
					variant="circle"
					style={{
						height: "100px",
						width: "100px",
						alignSelf: "center",
						marginTop:"-70px"
					}}
					src={business?.thumbnailUrl ? business.thumbnailUrl : ""}
				/>
				<Typography
					className={clsx({ [classes.title]: true, [classes.titleWithoutBackground]: !!!business?.photos[0]?.url })}
					variant="h5"
					component="h1">
					{business?.name ? business.name : ""}
				</Typography>
			</div>
		</ThemeProvider>
	);
};

export default CustomCardHeader;
