import api from "~/services/api";

const buildServiceTree = (services) => {
	let tree = [];
	for (let i = 0; i < services?.length; i++) {
		let levels = services[i]?.featureItems;
		const nameService = services[i].serviceName;

		let levelsName = String(nameService).split(" - ");
		let branch = tree;
		for (let j = 0; j < levels?.length; j++) {
			let levelIndex = -1;
			for (let k = 0; k < branch.length; k++) {
				if (branch[k].id === levels[j]) {
					levelIndex = k;
					break;
				}
			}
			if (levelIndex === -1) {
				if(levelsName[j] !== '*'){
					branch.push({
						name: levelsName[j],
						branchs: [],
						service: j === levels.length - 1 ? services[i] : null,
						id: services[i].featureItems[j],
					});
					branch = branch[branch.length - 1].branchs;
				}
			} else {
				branch = branch[levelIndex].branchs;
			}
		}
	}
	return tree;
};

const petPreselection = (tree, pet) => {
	let level = tree;
	for (let i = 0; i < pet.featureItems.length; i++) {
		for (let j = 0; j < level.length; j++) {
			if (pet.featureItems[i] === level[j].id) {
				level = level[j].branchs;
			}
		}
	}
	return level;
};

const servicePreselection = (tree, service) => {
	let level = tree;
	for (let i = 0; i < service.featureItems.length-1; i++) {
		for (let j = 0; j < level.length; j++) {
			if (service.featureItems[i] === level[j].id) {
				level = level[j].branchs;
			}
		}
	}
	return level;
};

export default {
	buildServiceTree,
	petPreselection,
	servicePreselection,
};
