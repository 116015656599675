import { makeStyles } from "@material-ui/core";

const MuiStylesCardBusiness = makeStyles((theme) => ({
	img: {
		width: "45px",
		height: "45px",
		margin: "12px 10px 0 20px",
		borderRadius: "5px",
		boxShadow: "0 2px 2px -10px rgba(0, 0, 0, 0.05)",
		float: "left",
	},
	name: {
		fontSize: "16px",
		fontWeight: 500,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1,
		letterSpacing: "-0.36px",
		color: "#474747",
	},
	street: {
		fontSize: "12px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.27px",
		color: "#888888",
	},
}));

export default MuiStylesCardBusiness;
