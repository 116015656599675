import { combineReducers } from "redux";
// Application Reducers
import auth from "~/store/reducers/authReducer";
import business from "~/store/reducers/businessReducer";
// /businessService
import businessServices from "~/store/reducers/businessServiceReducer";
import cancellation from "~/store/reducers/cancellationPolicyReducer";
import checkout from "~/store/reducers/checkoutReducer";
import confirmation from "~/store/reducers/confirmationReducer";
import login from "~/store/reducers/loginReducer";
import schedule from "~/store/reducers/scheduleReducer";
import sectors from "~/store/reducers/sectorsReducer";
import services from "~/store/reducers/servicesReducer";
import account from "~/store/reducers/bankAccountReducer";
import bookingTransaction from "~/store/reducers/bookingTransactionReducer";
import paymentList from "~/store/reducers/paymentListReducer";
import search from "~/store/reducers/searchReducer";
import userUpdate from "~/store/reducers/userUpdateReducer";
import hoursServices from "~/store/reducers/hoursReducer";
import filteredServices from "~/store/reducers/filteredFeatureItemReducer";
import contract from "~/store/reducers/contractReducer";
import coupons from "~/store/reducers/couponsReducer";
import couponService from "~/store/reducers/couponServicesReducer"
import me from "~/store/reducers/meReducer"

const appReducer = combineReducers({
	auth,
	business,
	services,
	sectors,
	couponService,
	businessServices,
	confirmation,
	checkout,
	login,
	cancellation,
	schedule,
	account,
	bookingTransaction,
	paymentList,
	search,
	userUpdate,
	hoursServices,
	filteredServices,
	contract,
	coupons,
	me,
});

const reducers = (state, action) => {
	return appReducer(state, action);
};

export default reducers;
