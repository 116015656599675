import { Button, Grid, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import ErrorDialog from "~/components/common/dialog/index";
import FormError from "~/components/common/formError";
import { TextFieldComponent } from "~/components/common/formItem";
import TitleContainer from "~/components/common/titleContainer/TitleContainer";
import loginActions from "~/store/actions/loginAction";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

function EmailSignUp(props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { loading, open, message, title, openError, messageError, titleError, telephoneWithCountryCode } = useSelector((store) => store.login);
	const { t } = useTranslation();

	const onClickGoBack = () => {
		props.history.goBack();
	};

	const handleOkError = () => {
		dispatch(loginActions.setOpenDialogError(false));
	};

	const handleOk = () => {
		dispatch(loginActions.setOpenDialog(false));
		onClickGoBack();
	};

	const footer = (submitForm) => {
		return (
			<>
				<Grid item container justify="center">
					<div className={classes.divMessage}>
						<Typography className={classes.message}>
							<strong>Enviaremos um código para validar seu e-mail.</strong>
						</Typography>
					</div>
				</Grid>
				<Grid item style={{ paddingTop: 10, textAlign: "center" }}>
					<Button id="send-number" type="submit" className={classes.button} onClick={submitForm}>
						{t("go")}
					</Button>
				</Grid>
			</>
		);
	};

	return (
		<Formik
			initialValues={{ email: "", password: "", name: "" }}
			validate={(values) => {
				const errors = {};
				if (!values.email) {
					errors.email = "Preencha o e-mail";
				}
				if (!values.password) {
					errors.password = "Preencha a senha.";
				}
				if (!values.name) {
					errors.email = "Preencha o nome";
				}
				return errors;
			}}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(false);
				dispatch(loginActions.sendEmailToCreateAuthentication(values.email, values.password, values.name, telephoneWithCountryCode));
			}}>
			{({ submitForm, errors, touched, setFieldValue }) => (
				<TitleContainer
					loading={loading}
					handleClick={onClickGoBack}
					headerStyle={{ boxShadow: "none", paddingBottom: "0" }}
					footer={footer(submitForm)}>
					<Grid container direction="column" spacing={3}>
						<Grid item xs={8}>
							<Typography className={classes.title}>
								<strong>{t("Create Account with Email")}</strong>
							</Typography>
						</Grid>

						<>
							<Grid item style={{ margin: "10,0, 0,10", width: "100%", textAlign: "center" }}>
								<TextFieldComponent
									style={{ width: "70%", paddingLeft: "5px" }}
									name="name"
									placeholder="Digite seu nome"
									onChange={(e) => {
										dispatch(loginActions.setName(e.target.value));
										setFieldValue("name", e.target.value);
									}}
								/>
								<FormError errors={errors.email} touched={touched.email} />
							</Grid>
							<Grid item style={{ margin: "10,0, 0,10", width: "100%", textAlign: "center" }}>
								<TextFieldComponent
									style={{ width: "70%", paddingLeft: "5px" }}
									name="email"
									placeholder="Digite seu email"
									onChange={(e) => {
										dispatch(loginActions.setEmail(e.target.value));
										setFieldValue("email", e.target.value);
									}}
								/>
								<FormError errors={errors.email} touched={touched.email} />
							</Grid>
							<Grid item style={{ width: "100%", textAlign: "center" }}>
								<TextFieldComponent
									style={{ width: "70%", paddingLeft: "5px" }}
									name="password"
									type="password"
									placeholder="Senha de 6 dígitos"
									onChange={(e) => {
										setFieldValue("password", e.target.value);
									}}
								/>
								<FormError errors={errors.password} touched={touched.password} />
							</Grid>
						</>
					</Grid>
					<ErrorDialog title={titleError} onOpen={openError} message={messageError} onOk={handleOkError} />
					<ErrorDialog title={title} onOpen={open} message={message} onOk={handleOk} />
				</TitleContainer>
			)}
		</Formik>
	);
}

export default withRouter(EmailSignUp);
