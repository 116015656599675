import api from "~/services/api";

export const getAnticipatedAmountCheckout = (data) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}payment/anticipated-amount-checkout`, data);
};
export const fetchPaymentAnticipatedAmountByBookTransaction = (data) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}payment/anticipated-amount`, data);
};
export const fetchCancellation = (data) => {
	return api.post(`${process.env.REACT_APP_URL_API_PAYOUT}payment/cancellation/values`, data);
};
export const fetchPaymentRequest = (id) => {
	return api.get(`${process.env.REACT_APP_URL_API_PAYOUT}payment/request/client/${id}`);
};
export const me = () => {
	return api.get(`/me`);
};

export default {
	getAnticipatedAmountCheckout,
	fetchPaymentAnticipatedAmountByBookTransaction,
	fetchCancellation,
	fetchPaymentRequest,
	me
};
