import { createMuiTheme, IconButton, ThemeProvider } from "@material-ui/core";
import React from "react";
import { SiteIcon } from "~/components/common/icons/Icons";

const theme = (theme) =>
	createMuiTheme({
		...theme,
		overrides: {
			MuiIconButton: {
				root: {
					padding: 0,
					borderRadius: "14px",
					backgroundColor: "rgba(57,55,98,0.05)",
					marginRight: "10px",
				},
				label: {
					padding: "20px",
				},
			},
		},
	});

const SiteButton = (props) => {
	const { link } = props;

	const handleClick = () => {
		window.open(`${link}`);
	};
	return (
		<ThemeProvider theme={theme}>
			<IconButton onClick={() => handleClick()}>
				<SiteIcon />
			</IconButton>
		</ThemeProvider>
	);
};

export default SiteButton;
