import { Box, makeStyles } from "@material-ui/core";
import React from "react";

const styles = makeStyles((theme) => ({
	box: {
		padding: theme.spacing(2, 0, 0, 0),
	},
}));

const TabPanel = (props) => {
	const { children, value, index, ...other } = props;
	const classes = styles();

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}>
			{value === index && <Box className={classes.box}>{children}</Box>}
		</div>
	);
};
export default TabPanel;
