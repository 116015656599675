import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "~/config/routes";
import { DialogProvider } from "~/utils/DialogProvider";

const Routers = () => {
	return (
		<Switch>
			{routes.map((route, index) => {
				const Component = route.view;
				if (route.path === "/robots.txt" || route.path === "/sitemap.xml"){
					
				}else{
					return (
						<Route key={index} path={route.path} exact={route.exact}>
							<DialogProvider Component={Component}/>
						</Route>
					);
				}
			})}
			<Redirect to="/not-found" />
		</Switch>
	);
};

export default Routers;
