import React, { Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import HeaderContainer from "../common/headerContainer/HeaderContainer";

import { Typography } from "@material-ui/core";
import GooglePlayWhiteIcon from "assets/icons/googlePlayWhite.png";
import AppleIcon from "@material-ui/icons/Apple";
import Logo from "assets/icons/logo100.png";
import SmartPhone from "assets/icons/smartphone-shape.png";

import useStyles from "./styles";

const AppAccess = () => {
	const classes = useStyles();

	return (
		<Fragment>
			<HeaderContainer />
			<Typography className={classes.title}>
				O agendamento deste estabelecimento é feito pelo aplicativo PRIT.
			</Typography>

			<div className={classes.containerSmart}>
				<div className={classes.smartPhone}>
					<img src={SmartPhone} alt="SmartPhone" />
				</div>
				<div className={classes.containerItems}>
					<div className={classes.logoPrit}>
						<img style={{ width: " 4em", borderRadius: "10px" }} src={Logo} alt="logoPrit" />
					</div>
					<div className={classes.containerName}>
						<Typography className={classes.textApp}> Baixe o app e agende agora!</Typography>
					</div>
				</div>
			</div>

			<footer className={classes.footer}>
				<div className={classes.posicao}>
					<Link
						className={classes.linkplayStore}
						to={{ pathname: "https://play.google.com/store/apps/details?id=app.prit" }}
						target="_blank">
						<img
							style={{ width: "1.8em", marginTop: "13px" }}
							src={GooglePlayWhiteIcon}
							alt="play store"
							className={classes.linkIcon}
						/>
						<div className={classes.nameAppLink}>
							Baixe na <br />
							<strong>Play Store</strong>
						</div>
					</Link>
					<Link
						className={classes.linkAppStore}
						to={{ pathname: "https://apps.apple.com/br/app/prit/id1466577543" }}
						target="_blank">
						<AppleIcon style={{ color: "#ffffff" }} className={classes.linkIcon} />
						<div className={classes.nameAppLink}>
							Baixe na <br />
							<strong>App Store</strong>
						</div>
					</Link>
				</div>
			</footer>
		</Fragment>
	);
};
export default withRouter(AppAccess);
