import { makeStyles } from "@material-ui/core";

const MuiStylesApp = makeStyles((theme) => ({
	mainPaper: {
		maxWidth: "425px",
		margin: "auto",
		height: "auto",
		minHeight: "100vh",
	},
}));
export default MuiStylesApp;
