import React from "react";
import PurpleButton from "~/components/common/purpleButton/PurpleButton";
import { useTranslation } from "react-i18next";

const ScheduleDiv = (props) => {
	const { label, handleClick, business } = props;
	const { t } = useTranslation();

	return (
		<PurpleButton
			style={{
				backgroundColor: business ? "rgb(156 150 150)" : "rgb(69, 0, 103)",
				cursor: "unset",
				margin: "16px",
				padding: "7px",
				color: business ? "rgb(0 0 0 / 45%)" : "#ffffff",
				height: 44,
				fontSize: "15px",
				display: "flex",
				flex: 1,
				width: "100%",
				maxWidth: "400px",
				borderRadius: "25.5px"
			}}
			disabled={business}
			variant="contained"
			onClick={handleClick ? handleClick : null}>
			{label ? label : t("Book")}
		</PurpleButton>
	);
};
export default ScheduleDiv;
