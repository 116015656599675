import React from "react";
import { withRouter } from "react-router-dom";
import { Paper } from "@material-ui/core";
import AppAccess from "~/components/appAccess";
import MuiStylesVendorPage from "~/components/vendorPage/MuiStylesVendorPage";

function SchedulingApp() {
    const classes = MuiStylesVendorPage();
    return (
        <Paper className={classes.mainPaper}>
            <AppAccess />
        </Paper>
    );
}

export default withRouter(SchedulingApp);
