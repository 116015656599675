import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	field: { width: (props) => (props.codeInput ? 38 : "100%") },
	codeInput: {
		height: "70px",
		fontSize: "34px",
		color: "#393762",
		width: "48px",
		padding: "5px",
	},
}));
