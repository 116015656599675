import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	title: {
		width: "270px",
		height: " 19px",
		margin: "20px 100px 16px 20px",
		fontSize: "16px",
		fontWeight: " 500",
		fontStretch: " normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.36px",
		color: " rgba(71, 71, 71, 0.82)",
	},

	containerLink: {
		height: "144px",
		margin: " 16px 0 0",
		padding: " 30px 62px 16px 27px",
		border: "solid 1px #eaeaea",
		backgroundColor: " #64b3f4",
	},

	linkAppUp: {
		height: " 40px",
		margin: " 0 0 21px 4px",
	},

	titleLinkUp: {
		width: "300px",
		color: "#f2f2f2",
		fontSize: "18px",
	},

	linkplayStore: {
		width: "135px",
		height: "40px",
		margin: "8px 11px 0 0",
		backgroundColor: "#ffffff",
		display: "inline",
		float: "left",
		borderRadius: "4px",
		textDecoration: "none",
	},

	linkAppStore: {
		width: "135px",
		height: "40px",
		margin: "8px 39px 0 11px",
		backgroundColor: "#ffffff",
		float: "left",
		borderRadius: "4px",
		textDecoration: "none",
		position: "absolute",
	},

	linkIcon: {
		color: " #64b3f4",
		padding: "1px",
		margin: "7px  7px 0 15px",
		float: "left",
	},

	nameAppLink: {
		fontSize: "11px",
		textDecoration: "none",
		margin: "4px",
		color: " #64b3f4",
	},

	scrollCredits: {
		display: "flex",
		overflowX: "auto",
		"&::-webkit-scrollbar": {
			width: 0,
		},
		margin: "20px 0px 20px 30px",
	},

	pritCredits: {
		minWidth: "170px",
		maxWidth: "49.3%",
		height: " 48px",
		fontSize: "14px",
		fontWeight: "500",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.31px",
		color: "rgba(71, 71, 71, 0.82)",
	},

	pritCreditsValue: {
		minWidth: "180px",
		height: "64px",
		// margin: "28px 5px 43px 33px",
		// padding: "10px 0 11px 11px",
		borderRadius: "5px",
		boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.03)",
		backgroundColor: " #fafafa",
	},

	ticketIcon: {
		float: "left",
		background: "#ffffff",
		borderRadius: "2em",
	},

	valueCredits: {
		width: "auto",
		height: "13px",
		margin: " 8px 0 3px 11px",
		fontFamily: "CircularStd",
		fontSize: " 10px",
		fontWeight: 500,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.22px",
	},

	validDate: {
		width: "auto",
		height: "13px",
		margin: "3px 0 6px 8px",
		fontFamily: "CircularStd",
		fontSize: " 10px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: " -0.22px",
	},

	timeStart: {
		width: "31px",
		height: "8px",
		margin: "0 7px 11px 27px",
		color: " rgba(71, 71, 71, 0.5)",
	},

	nameControl1: {
		fontWeight: " 500",
		color: " rgba(71, 71, 71, 0.82)",
		textAlign: "right",
		margin: "0px 35px 0 0",
	},

	nameControl2: {
		width: "auto",
		height: "32px",
		margin: "0 34px 0 76px",
		fontSize: "14px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.31px",
		textAlign: "right",
		color: "#c2e59c",
	},

	inforUser: {
		color: "#686d71",
		fontSize: "11px",
		height: "19px",
		textAlign: "right",
		margin: "0px 35px 0 0",
	},

	rectangleServices: {
		width: "auto",
		height: "auto",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		margin: "0 34px 35px 45px",
		padding: " 9px 20px 8px 16px",
		borderRadius: " 5px",
		border: "solid 1px #eaeaea",
		backgroundColor: " #fafafa",
	},

	imageService: {
		float: "left",
		width: " 30px",
		height: " 30px",
		margin: "8px 8px 10px 0",
		borderRadius: " 5px",
		border: "solid 1px var(--white)",
	},

	containerTimesService: {
		margin: " 0 0 5px 8px",
	},

	times: {
		width: "70px",
		height: "10px",
		margin: " 8px 0 10px",
		fontSize: "12px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.18px",
		color: "#888888",
	},

	nameServices: {
		width: " 115px",
		height: "16px",
		fontFamily: "CircularStd",
		fontSize: "12px",
		fontWeight: "500",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "1.33",
		letterSpacing: "-0.27px",
		color: " #474747",
		float: "left",
	},

	buttons: {
		textAlign: "center",
		width: "71px",
		padding: "inherit",
		float: "right",
		// cursor: "pointer",
	},

	reschedule: {
		width: "71px",
		height: "20px",
		fontSize: "10px",
		borderRadius: "5px",
		border: " solid 1px #eaeaea",
		color: "#adadada8",
		padding: "2px",
		backgroundColor: " #ffffff",
		float: "left",
	},

	cancel: {
		width: "71px",
		height: "20px",
		fontSize: "10px",
		borderRadius: "5px",
		border: " solid 1px #eaeaea",
		backgroundColor: " #ffffff",
		color: "#adadada8",
		padding: "2px",
		float: "left",
		marginTop: "8px",
	},

	messageContainer: {
		width: "auto",
		height: " 64px",
		textAlign: "center",
		margin: " 45px 0 38px 33px",
		padding: "16px 52px 16px 46px",
		borderRadius: "5px",
		border: "solid 1px #eaeaea",
		backgroundColor: " #fafafa",
	},

	notificationIcon: {
		width: "2.5em",
		backgroundColor: "#ffffff",
		float: "left",
		marginLeft: "-36px",
		marginTop: "-30px",
		borderRadius: "2em",
	},

	nameMessage: {
		width: "244px",
		height: "32px",
		fontSize: "15px",
		fontWeight: "600",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.31px",
		color: "#64b3f4",
	},

	footer: {
		display: "inline-block",
		padding: " 0px 0 25px 60px",
	},
}));
