import { makeStyles } from "@material-ui/core";

const MuiStylesModalReservations = makeStyles((theme) => ({
	modal: {
		padding: "20px 10px 10px 10px",
		boxShadow: "0 10px 16px 0 'rgba(0, 0, 0, 0.2)'",
		backgroundColor: "#ffffff",
	},
	title: {
		height: "18px",
		margin: "0 0 5px 0",
		fontSize: "14px",
		fontWeight: 500,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.31px",
		color: "#393762",
	},
	hours: {
		height: "15px",
		margin: "9px 50px 4px 0",
		fontSize: "12px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.27px",
		color: "#888888",
	},
	nameService: {
		height: "20px",
		// margin: "4px 97px 8px 0",
		fontSize: "16px",
		fontWeight: 500,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.36px",
		color: "#474747",
	},
	type: {
		height: "18px",
		margin: "2px 101px 0 0",
		padding: "0 11px 0 0",
		fontSize: "14px",
		fontWeight: 500,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.31px",
		color: "#3689ea",
	},
	professional: {
		fontSize: "12px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "-0.27px",
		color: "#474747",
	},
	avatar: {
		margin: "0 55px 0 165px",
	},
	button: {
		// width: "30%",
		height: "36px",
		margin: "15px 5px 15px 0",
		padding: "9px 13.5px 5px 14.5px",
		borderRadius: "18px",
		border: "solid 1px #3689ea",
	},
	buttonName: {
		height: "18px",
		fontSize: "12px",
		fontWeight: "bold",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "normal",
		letterSpacing: "normal",
		textAlign: "center",
		color: "#3689ea",
	},
	infor: {
		padding: "12px",
		// margin: "0 0 0 12px",
	},
}));

export default MuiStylesModalReservations;
