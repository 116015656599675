import { Checkbox } from "@material-ui/core";
import MuiContainer from "@material-ui/core/Container";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React from "react";

// const sectors = JSON.parse(localStorage.getItem("@prit-web/sectors"));

export const Container = withStyles({
	root: {
		width: "425px",
		backgroundColor: "#ffffff",
	},
})(MuiContainer);

export const CheckboxColor = withStyles({
	root: {
		color: "#ffffff",
		"&$checked": {
			color: "#450067",
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default makeStyles((theme) => ({
	nameList: {
		maxHeight: "40px",
		margin: "12 10px 0 0",
		fontFamily: "CircularStd",
		fontSize: "13px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal",
		letterSpacing: "-0.47px",
		color: "#474747",
	},

	listCategoriesArea: {
		height: "80%",
		width: "100%",
		display: "flex",
	},

	listCategories: {
		paddingBottom: "55px",
		height: "fit-content",
		overflowX: "auto",
		"&::-webkit-scrollbar": {
			width: 0,
		},
	},

	selectedCategory: {
		width: "max-content",
		marginBottom: "10px",
		fontSize: "14px",
		fontWeight: 300,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.33,
		letterSpacing: -0.4,
		color: "#630094",
		borderBottom: " none",
		display: "inline-flex",
	},

	RectangleImage: {
		width: "100%",
		height: "70%",
		maxHeight: "80px",
		maxWidth: "100px",
		borderRadius: "20% 20% 0 0",
	},

	selectedCategoryRectangle: {
		height: "28px",
		backgroundColor: "rgb(57 55 98 / 3%)",
		display: "inline-block",
		margin: "0 4px 7px 0",
		borderRadius: "14px",
	},

	nameCategorieSelection: {
		fontSize: "11px",
		fontWeight: "normal",
		fontStretch: "normal",
		fontStyle: "normal,",
		lineHeight: "11px",
		letterSpacing: "-0.16px",
		textAlign: "center",
		color: "#474747",
	},

	scrollSelectedArea: {
		display: "flex",
		overflowX: "auto",
		"&::-webkit-scrollbar": {
			width: 0,
		},
	},

	selectedCategoryDelete: {
		width: "2.8em",
		margin: "-25px -8px",
		padding: "15px",
	},

	nameSector: {
		height: "21px",
		paddingBottom: 50,
		fontFamily: "CircularStd",
		fontSize: "15px",
		margin: "13px",
		fontWeight: 500,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: "3.33",
		letterSpacing: "-0.5px",
		color: " #474747",
	},

	root: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		height: " 36px",
		borderRadius: " 14px",
		boxShadow: "none",
		backgroundColor: "rgba(57, 55, 98, 0.04)",
	},

	inputSearch: {
		width: 335,
		marginLeft: theme.spacing(1),
		flex: 1,
	},

	countSelected: {
		width: "20px",
		height: "20px",
		margin: "12px 0 12px 0",
		padding: "0 2px 2px 2px",
		borderRadius: "5px",
		textAlign: "center",
		color: "#fff",
		backgroundColor: " #630094",
	},

	table: {
		minWidth: "100%",
	},

	tableFooter: {
		paddingTop: "7px",
		marginBottom: "0px",
		minWidth: "425px",
	},
	footer: {
		alignItems: "center",
		width: "20px",
		height: "20px",
		display: "flex",
		margin: "12px 0 12px 0",
		borderRadius: "5px",
		backgroundColor: " #630094",
	},

	nameCountServices: {
		width: "122px",
		height: "32px",
		margin: " 6px 35px 6px 8px",
		fontSize: "14px",
		fontWeight: " 300",
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.14,
		letterSpacing: "-0.47px",
		color: " #474747",
		padding: "1px",
		borderBottom: " none",
		display: "flex",
		alignItems: "center",
	},

	radioBtn: {
		height: "50%",
		margin: "0 5px",
		padding: "0 0 7px",
		borderRadius: "14px",
		transitions: "all 2s ease",
		width: "50%",
		boder: "3px solid transparent",
		display: "inline-block",
		position: "relative",
		textAlign: "center",
		boxShadow: " 0 0 3px #c3c3c367",
		cursor: "pointer",
	},

	colorClickBtn: {
		backgroundColor: "rgba(99, 0, 148, 0.8)",
		position: "absolute",
		borderRadius: "20% 20% 0 0",
		opacity: 0,
	},

	costumRadio: {
		display: "none",
	},

	clickList: {
		color: "#630094",
		opacity: 0,
		position: "absolute",
		top: "20px",
		borderRadius: "20% 20% 0 0",
	},

	centerDiv: { 
		display: "flex", 
		justifyContent: "space-between", 
		alignItems: "center" 
	},

	selectServicesLabel: { 
		fontSize: "18px", 
		fontWeight: "bold", 
		textAlign: "center", 
		flex: "1" 
	},

	footerAlign: { 
		display: "flex", 
		justifyContent: "space-around", 
		marginBottom: "20px" 
	},

	noServicesAvailble: { 
		textAlign: "center", 
		margin: "55px" 
	},

	levelTitle: { 
		fontWeight: "bold" 
	},

	levelTileDiv: { 
		//display: "flex", 
		flexDirection: "column", 
		//height: "58px" 
	},

	levelTileAlign: { 
		display: "flex", 
		justifyContent: "space-between", 
		alignItems: "center" 
	}
}));

export const Services = withStyles({});
