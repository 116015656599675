import { Typography, Divider } from "@material-ui/core";
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";

import Logo from "assets/icons/logo.png";
import CreditCardIcon from "assets/icons/creditCard.png";
import DatePickeIcon from "assets/icons/datePicker.png";

import MuiStylesHeaderContainer from "~/components/common/headerContainer/MuiStylesHeaderContainer";

const HeaderContainer = () => {
	const classes = MuiStylesHeaderContainer();

	return (
		<Fragment>
			<header className={classes.header}>
				<img className={classes.logo} src={Logo} alt="logo" />
				<Divider className={classes.dividerLogo} />
			</header>
			<Divider />
			{/* <div className={classes.bookEPayContainer}>
				<img className={classes.datePickeIcon} src={DatePickeIcon} alt="date" />
				<Typography className={classes.nameBookPay}>book & pay</Typography>
				<img className={classes.creditIcon} src={CreditCardIcon} alt="credit" />
			</div> */}
		</Fragment>
	);
};
export default withRouter(HeaderContainer);
