import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";

const NotFound = (props) => {
	const classes = useStyles();

	return (
		<div className={classes.background}>
			<Typography className={classes.text404}>404</Typography>
			<Typography className={classes.adviser}>Não era pra você estar aqui!</Typography>
			<Typography className={classes.adviserSubtitle}>
				Solicite o link de agendamento ao estabelecimento desejado.
			</Typography>
		</div>
	);
};

export default NotFound;
