import React from "react";

const StickyFooter = (props) => {
	return (
		<div style={{ bottom: "0", position: "sticky", backgroundColor: "white", paddingBottom: "1%" }}>
			{props.children}
		</div>
	);
};

export default StickyFooter;
