import { makeStyles } from "@material-ui/core";

const MuiStylesBusinessDiv = makeStyles((theme) => ({
	avatar: {
		height: "60px",
		width: "60px",
	},
	header: {
		flex: 1,
		height: "123px",
	},
	title: {
		fontWeight: 400,
		color: "#474747",
		fontSize: "16px",
		lineHeight: 1,
		letterSpacing: "-0.36px",
	},
	subtitle: {
		fontWeight: 400,
		color: "rgba(71,71,71,0.5)",
		fontSize: "14px",
		lineHeight: 1,
		letterSpacing: "-0.31px",
		marginTop: "7px",
	},
	price: {
		fontWeight: 400,
		color: "rgb(54,137,234)",
		fontSize: "14px",
		lineHeight: 1,
		letterSpacing: "-0.31px",
		marginTop: "7px",
	},
	titleWithoutBackground: {
		color: "#474747",
	},
	iconButton: {
		backgroundColor: "#ffffff",
	},
	iconButtonSvg: {
		fill: "#630094",
	},
	time: {
		opacity: 0.4,
		fontSize: 12,
		fontWeight: "bold",
		alignSelf: "center",
		margin: "7px",
	},
}));

export default MuiStylesBusinessDiv;
