import { Button, DialogTitle, DialogActions } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, { useMemo, useState } from "react";
import TitleDialog from "~/components/modalBookingScreen/TitleDialog";
import { useSelector } from "react-redux";
import useStyles from "./styles";
import { withRouter } from "react-router";
import Utils from "~/helpers/Utils";

const DialogPix = ({ onClose, setPix }) => {
	const classes = useStyles();

	const [cpf, setCPF] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const handleScheduleClick = () => {
		if (cpf && Utils.validateCPF(cpf.replace(/[^0-9]/g, ""))) {
			if (onClose) {
				onClose();
			}
			if (setPix) {
				setPix(cpf.replace(/[^0-9]/g, ""));
			}
		} else {
			setErrorMessage("CPF inválido");
		}
	};

	const cpfMask = (value) => {
		return value
			.replace(/\D/g, "")
			.replace(/(\d{3})(\d)/, "$1.$2")
			.replace(/(\d{3})(\d)/, "$1.$2")
			.replace(/(\d{3})(\d{1,2})/, "$1-$2")
			.replace(/(-\d{2})\d+?$/, "$1");
	};

	const handleChange = (e) => {
		let cpf = cpfMask(e.target.value);
		setCPF(cpf);
	};
	return (
		<>
			<DialogTitle>{<TitleDialog onClose={onClose} message={"Adicionar Pix"} />}</DialogTitle>
			<div className={classes.container} style={{ display: "block", padding: "0 20px" }}>
				<div>
					<TextField
						label="Insira seu CPF"
						name="cpf"
						fullWidth
						margin="normal"
						value={cpf}
						onChange={handleChange}
						onKeyPress={(event) => {
							if (event.key === "Enter") {
								handleScheduleClick();
							}
						}}
						inputProps={{ maxLength: 14 }}
						error={errorMessage}						
					/>
					{errorMessage && <h5 style={{color: 'red', margin: 0}}>{errorMessage}</h5>}
				</div>
			</div>

			<DialogActions className={classes.actions}>
				<Button onClick={handleScheduleClick} className={classes.root}>
					Adicionar Pix
				</Button>
			</DialogActions>
		</>
	);
};

export default withRouter(DialogPix);
