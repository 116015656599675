import { Button, FormControl, DialogTitle, DialogActions } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import React, { useMemo, useState } from "react";
import TitleDialog from "~/components/modalBookingScreen/TitleDialog";
import { CreditCardIcon } from "~/components/common/icons/Icons";
import { useSelector } from "react-redux";
import useStyles from "./styles";
import { withRouter } from "react-router";
import valid from "card-validator";
import ErrorDialog from "~/components/common/dialog";
import Utils from "~/helpers/Utils";

const DialogAddCard = ({ onClose, openNext, setCardData }) => {
	const classes = useStyles();
	const [number, setNumber] = useState("");
	const [expiry, setExpiry] = useState("");
	const [cvc, setCVC] = useState("");
	const [name, setName] = useState("");
	const [cpf, setCPF] = useState("");
	const [email, setEmail] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [openError, setOpenError] = useState(false);
	const [cpfError, setCpfError] = useState("");

	const cardIsValid = () => {
		if (number !== "" && expiry !== "" && cvc !== "" && name !== "" && cpf !== "" && email!=="") {
			let validationNumber = valid.number(number).isValid;
			let validationCVC = valid.cvv(cvc).isValid;
			let validationDate = valid.expirationDate(expiry).isValid;
			return validationNumber && validationCVC && validationDate;
		}
		return false;
	};

	const handleScheduleClick = () => {
		let emptyFields = [];

		if(!Utils.validateCPF(cpf.replace(/[^0-9]/g, ""))){
			setCpfError("CPF inválido");
			return;
		}

		if (number === "") {
		  emptyFields.push("Número do cartão");
		}
	
		if (expiry === "") {
		  emptyFields.push("Validade");
		}
	
		if (cvc === "") {
		  emptyFields.push("CVC");
		}
	
		if (name === "") {
		  emptyFields.push("Nome");
		}
	
		if (cpf === "") {
		  emptyFields.push("CPF");
		}
	
		if (email === "") {
		  emptyFields.push("E-mail");
		}
	
		if (emptyFields.length > 0) {
			const errorMessage = `Os seguintes campos estão vazios: ${emptyFields.join(", ")}. Por favor, preencha todos os campos.`;
			setOpenError(true);
			setErrorMessage(errorMessage);
			return;
		}
	
		if (cardIsValid()) {
		  if (onClose) {
			onClose();
		  }
		  if (setCardData) {
			setCardData({
			  number: number,
			  name: name,
			  expiry: expiry.replace(/[^0-9]/g, ""),
			  cvc: cvc,
			  cpf: cpf.replace(/[^0-9]/g, ""),
			  email: email,
			});
		  }
		  if (openNext) {
			openNext();
		  }
		} else {
			const errorMessage = "Os dados de cartão informados não são válidos, por favor, verifique e tente novamente.";
			setOpenError(true);
			setErrorMessage(errorMessage);
		}
	  };

	const cpfMask = (value) => {
		return value
			.replace(/\D/g, "")
			.replace(/(\d{3})(\d)/, "$1.$2")
			.replace(/(\d{3})(\d)/, "$1.$2")
			.replace(/(\d{3})(\d{1,2})/, "$1-$2")
			.replace(/(-\d{2})\d+?$/, "$1");
	};

	const handleChange = (e) => {
		let cpf = cpfMask(e.target.value);
		setCPF(cpf);
	};

	const dateMask = (value) => {
		return value.replace(/\D/g, "").replace(/(\d{2})(\d)/, "$1/$2");
	};

	const handleChangeDate = (e) => {
		let date = dateMask(e.target.value);
		setExpiry(date);
	};

	return (
		<>
			<ErrorDialog
				onOpen={openError}
				message={errorMessage}
				onOk={() => {
					setOpenError(false);
				}}
				title={"Atenção!"}
			/>
			<DialogTitle>
				{
					<TitleDialog
						onClose={() => {
							onClose();
						}}
						message={"Adicionar Cartão"}
					/>
				}
			</DialogTitle>
			<div className={classes.container}>
				<div>
					<div style={{ padding: " 0 20px" }}>
						<FormControl noValidate autoComplete="off">
							<TextField
								name="number"
								label="Número do cartão"
								fullWidth
								margin="normal"
								onChange={(e) => setNumber(e.target.value)}
								inputProps={{ maxLength: 16 }}
							/>
							<div style={{ display: "flex" }}>
								<TextField
									name="expiry"
									label="Validade"
									value={expiry}
									onChange={handleChangeDate}
									inputProps={{ maxLength: 5 }}
								/>
								<div style={{ padding: "0 8px 0" }}>
									<TextField
										name="cvc"
										label="cvc"
										onChange={(e) => setCVC(e.target.value)}
										inputProps={{ maxLength: 3 }}
									/>
								</div>
							</div>
							<TextField
								id="standard-full-width"
								label="Insira seu nome"
								name="name"
								fullWidth
								margin="normal"
								onChange={(e) => setName(e.target.value)}
							/>
							<TextField
								label="Insira seu CPF"
								name="cpf"
								fullWidth
								margin="normal"
								value={cpf}
								onChange={handleChange}
								inputProps={{ maxLength: 14 }}
								error={cpfError}	
							/>
					{cpfError && <h5 style={{color: 'red', margin: 0}}>{cpfError}</h5>}
							<TextField
								label="Insira seu Email"
								name="email"
								fullWidth
								margin="normal"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								onKeyPress={(event) => {
									if (event.key === "Enter") {
										handleScheduleClick();
									}
								}}
							/>
						</FormControl>
					</div>
				</div>
			</div>

			<DialogActions className={classes.actions}>
				<Button onClick={handleScheduleClick} className={classes.root}>
					<CreditCardIcon style={{ marginRight: 15 }} />
					Adicionar Cartão
				</Button>
			</DialogActions>
		</>
	);
};

export default withRouter(DialogAddCard);
