import Constants from "~/helpers/enums/Constants";
import businessService from "~/services/businessService";
import servicesService from "~/services/servicesService";
// import servicesActions from "~/store/actions/servicesActions";

const getBusiness = (id) => (dispatch) => {
	dispatch({ type: Constants.GET_AVAILABLE_DATES, payload: { availableDates: undefined } });
	businessService.getBusiness(id).then(({ data }) => {
		// const categoryId = data.categories.map((item) => item.id);
		// dispatch(servicesActions.getFeatures(categoryId));

		dispatch({ type: Constants.GET_BUSINESS, payload: { business: data } });
		dispatch({
			type: Constants.GET_FEATURE_ITEMS,
			payload: {
				businessService: [],
			},
		});
		// dispatch(getProfessionalService(data.professionals.map((item) => item.id)));
	});
};

// const getProfessionalService = (ids) => (dispatch) => {
// 	servicesService.getFeatureItems(ids).then(({ data }) => {
// 		let businessService = servicesService.buildSubCategories(
// 			data.map((item) => {
// 				return {
// 					...item.service,
// 					price: item.price,
// 					duration: item.duration,
// 				};
// 			})
// 		);
// 		dispatch({
// 			type: Constants.GET_FEATURE_ITEMS,
// 			payload: {
// 				businessService,
// 			},
// 		});
// 	});
// };

const getBusinessService = (id, categoryId, vendorsPageService, feature_items) => (dispatch) => {
	businessService.getBusinessService(id, categoryId, vendorsPageService, feature_items).then(({ data }) => {
		dispatch({
			type: Constants.GET_BUSINESS_SERVICE,
			payload: {
				// businessServices: servicesService.buildSubCategories(data.map((item) => item)),
				businessServices: data,
			},
		});
	});
};

const getSectors = (id) => (dispatch) => {
	businessService.getSectors(id).then(({ data }) => {
		dispatch({ type: Constants.GET_SECTORS, payload: { sectors: data } });
	});
};

const getAvailableDates = (businessId, services) => async (dispatch) => {
	dispatch({ type: Constants.GET_AVAILABLE_DATES, payload: { availableDates: undefined } });

	try {
		const response = await businessService.getAvailableDates(businessId, services);

		if (response.data && response.data.error) {
			throw new Error(response.data.message);
		}

		dispatch({ type: Constants.GET_AVAILABLE_DATES, payload: { availableDates: response.data } });
	} catch (error) {
		throw error;
	}
};

const getBusinessNotifications = (page) => (dispatch) => {
	businessService.getBusinessNotifications(page).then(({ data }) => {
		dispatch({ type: Constants.GET_NOTIFICATIONS, payload: { notifications: data } });
	});
};

const getBusinessId = (id) => (dispatch) => {
	dispatch({ type: Constants.GET_BUSINESS_ID, payload: { businessId: id } });
};

const setBusiness = (business) => (dispatch) => {
	dispatch({ type: Constants.GET_AVAILABLE_DATES, payload: { availableDates: undefined } });
	dispatch({ type: Constants.GET_BUSINESS, payload: { business: business } });
	dispatch({
		type: Constants.GET_FEATURE_ITEMS,
		payload: {
			businessService: [],
		},
	});
};

export default {
	getBusiness,
	getSectors,
	getAvailableDates,
	getBusinessService,
	getBusinessNotifications,
	getBusinessId,
	setBusiness,
};
