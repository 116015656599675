import { Button, withStyles } from "@material-ui/core";
import React from "react";

const CustomButton = withStyles((theme) => ({
	root: {
		color: theme.palette.getContrastText("#630094"),
		backgroundColor: "#630094",
		"&:hover": {
			backgroundColor: "#630094",
		},
		borderRadius: "14px",
		boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15), 0 2px 4px 0 rgba(0, 0, 0, 0.15)",
		textTransform: "capitalize",
		fontSize: "10px",
	},
}))(Button);

const PurpleButton = (props) => {
	return <CustomButton {...props} />;
};

export default PurpleButton;
