import React, { useState, Fragment } from "react";
import _ from "lodash";
import { Button, Divider, Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import CardBusiness from "~/components/bookingScreen/CardBusiness";
import MuiStylesModalReservations from "./MuiStylesModalReservations";
import bookingTransactionService from "~/services/bookingTransactionService";
import DialogSucess from "~/components/common/dialog/dialogSucess";
import TitleDialog from "./TitleDialog";
import Sucess from "~/assets/icons/sucess-booking-confirmation.png";
import DialogCancelConfirm from "~/components/common/dialog/dialogCancelConfirm";
import ErrorDialog from "~/components/common/dialog/index";
import moment from "moment";
import DialogCancel from "../common/dialog/dialogCancel";
import Cancel from "~/assets/icons/success-boooking-cancelation.png";
import checkoutServices from "~/services/checkoutServices";
import Telephone from "~/assets/icons/cell-phone.png";
import Location from "~/assets/icons/location.png";
import { useTranslation } from "react-i18next";
import SpinnerLoading from "../common/spinnerLoading/SpinnerLoading";

const ModalReservations = ({
	onClose,
	dateEnd,
	date,
	serviceName,
	price,
	professional,
	avatarProfessional,
	bookingId,
	business,
	props,
	idGfi,
	categoryIds,
	status,
	confirmReservation,
	startTime,
	createdAt,
	pricingType,
	bookingTransactionId,
	priceWithDiscount,
	serviceId,
	professionalId,
}) => {
	const classes = MuiStylesModalReservations();
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [openError, setOpenError] = useState(false);
	const [openCancel, setOpenCancel] = useState(false);
	const [openNoRescheduling, setOpenNoRescheduling] = useState(false);
	const [openExpiration, setOpenExpiration] = useState(false);
	const [paymentCancellation, setPaymentCancellation] = useState(false);
	const [messageError, setMessageError] = useState("");
	const [names, setNames] = useState([]);
	const scheduleDay = moment(startTime);
	const confirmFlag = moment.duration(scheduleDay.diff(moment(new Date()))).asDays();
	const { t } = useTranslation();

	const currency = business?.currency;

	if (names.length === 0) {
		let last = categoryIds[categoryIds?.length - 1];
		setNames([{ id: last, name: serviceName, idGfi, categoryIds }]);
	}

	const onConfirmBooking = async () => {
		const dadosInvalidos = !bookingId || !status;
		if (dadosInvalidos) {
			throw new Error("Dados de reserva inválidos");
		}
		try {
			const bookingCheck = await bookingTransactionService.putBookingStatus(bookingId, "", status, null, true);
			if (bookingCheck) {
				setOpen(!open);
			}
		} catch (data) {
			if (data && data.response && data.response.status === 400) {
				setMessageError(
					"Dados de reserva inválidos. Por favor, verifique as informações fornecidas e tente novamente!"
				);
			} else {
				setMessageError(`${data.message} Tente novamente!`);
			}
			setOpenError(!openError);
		}
	};

	const handleOk = () => {
		setOpenError(!openError);
		props.history.push(`/reservas/${business.id}`);
	};

	const startTimeFormat = `${moment(startTime).format("YYYY-MM-DDTHH:mm:ss")}Z`;

	const cancel = async () => {
		setLoading(true);
		let obj = {
			recipient_id: business.id,
			booking_transaction_id: bookingTransactionId,
			date_booking_created: createdAt,
			service_id: idGfi,
		};
		try {
			const { data } = await checkoutServices.fetchCancellation(obj);
			if (data) {
				setLoading(false);
				setOpenCancel(true);
				setPaymentCancellation(data);
				return;
			}
		} catch (error) {
			setMessageError(`${error.response.data.message} Tente novamente!`);
			setOpenError(!openError);
			setLoading(false);
		}
	};

	const onBookingReschedule = async () => {
		setLoading(true);
		if (!business.reschedule) {
			setOpenNoRescheduling(!openNoRescheduling);
			return;
		}
		try {
			const { data } = await bookingTransactionService.getBookingRescheduleValidByBookingId(bookingId);
			if (!data) {
				setOpenExpiration(!openExpiration);
				return;
			}
		} catch (error) {
			setMessageError(`${error.response.data.message} Tente novamente!`);
			setOpenError(!openError);
			setLoading(false);
			return;
		}
		let serviceIds = [];
		names.forEach((service) => {
			serviceIds.push({ featureItems: service.categoryIds, id: serviceId, name: service.name });
		});
		props.history.push(`/selecionar-data/${business.id}`, {
			services: serviceIds,
			bookingId: bookingTransactionId,
			reservationUpdate: true,
			reschedule: {
				bookingId: bookingId,
				businessId: business.id,
				professionalId: professionalId,
				serviceId: serviceId,
			},
		});
		setLoading(false);
	};

	const openMap = () => {
		let adressBusiness = !_.isNil(business.addressStreet) ? business.addressStreet : "";
		adressBusiness = !_.isNil(business.addressNumber) ? adressBusiness + ", " + business.addressNumber : "";
		adressBusiness = !_.isNil(business.addressNeighborhood) ? adressBusiness + ", " + business.addressNeighborhood : "";
		adressBusiness = !_.isNil(business.addressPostalCode) ? adressBusiness + ", " + business.addressPostalCode : "";
		adressBusiness = !_.isNil(business.addressCity) ? adressBusiness + ", " + business.addressCity : "";
		adressBusiness = !_.isNil(business.addressCountry) ? adressBusiness + ", " + business.addressCountry : "";
		window.open(`http://maps.apple.com/?address=${encodeURIComponent(adressBusiness)}`);
	};

	const call = () => {
		window.location = "tel://" + business?.phone;
	};

	return (
		<div className={classes.modal}>
			<SpinnerLoading loading={loading} />
			<TitleDialog onClose={onClose} message={t("Appointment details")} />
			<Divider />
			<div style={{ display: "flex", flexDirection: "column" }}>
				<Typography className={classes.hours}>
					{date.format("HH:mm")}-{dateEnd.format("HH:mm")}
				</Typography>
				<Typography style={{ float: "left" }} className={classes.nameService}>
					{serviceName}
				</Typography>
				<Typography className={classes.professional} style={{ padding: "8px" }}>
					{t("with")} {professional}
				</Typography>
				<Typography className={classes.type}>
					{`${
						pricingType && /o mostrar/gi.test(pricingType.name)
							? "Sob consulta"
							: `${pricingType.name !== "Fixo" ? pricingType.name : ""} ${
									pricingType.name !== "Gratuito"
										? parseFloat(priceWithDiscount / 100).toLocaleString("pt-BR", {
												style: "currency",
												currency: currency,
										  })
										: ""
							  }`
					}`}
				</Typography>
			</div>
			<Divider />
			{confirmFlag < 1 &&
			confirmFlag > 0 &&
			status !== "cancelled" &&
			status !== "refuse" &&
			status !== "waiting" &&
			!confirmReservation ? (
				<Fragment>
					{business.schedulingType !== "schedule_chat" ? (
						<Fragment>
							<div style={{ justifyContent: "center", display: "flex" }}>
								<Button className={classes.button} onClick={() => onConfirmBooking()}>
									<Typography className={classes.buttonName}>{t("Confirmar")}</Typography>
								</Button>
								{business.reschedule && (
									<Button
										className={classes.button}
										style={{ border: "solid 1px #393762" }}
										onClick={() => onBookingReschedule()}>
										<Typography className={classes.buttonName} style={{ color: "#393762" }}>
											{t("Reagendar")}
										</Typography>
									</Button>
								)}
								<Button className={classes.button} style={{ border: "solid 1px #ff3b30" }} onClick={cancel}>
									<Typography className={classes.buttonName} style={{ color: "#ff3b30" }}>
										{t("Cancelar")}
									</Typography>
								</Button>
							</div>
						</Fragment>
					) : null}
					{status === "active" && status !== "waiting" && business.schedulingType === "schedule_chat" ? (
						<Fragment>
							<div style={{ justifyContent: "center", display: "flex" }}>
								<Button className={classes.button} onClick={() => onConfirmBooking()}>
									<Typography className={classes.buttonName}>{t("Confirmar")}</Typography>
								</Button>
								{business.reschedule && (
									<Button
										className={classes.button}
										style={{ border: "solid 1px #393762" }}
										onClick={() => onBookingReschedule()}>
										<Typography className={classes.buttonName} style={{ color: "#393762" }}>
											{t("Reagendar")}
										</Typography>
									</Button>
								)}
								<Button className={classes.button} style={{ border: "solid 1px #ff3b30" }} onClick={cancel}>
									<Typography className={classes.buttonName} style={{ color: "#ff3b30" }}>
										{t("Cancelar")}
									</Typography>
								</Button>
							</div>
						</Fragment>
					) : null}
					{status !== "active" &&
					business.schedulingType === "schedule_chat" &&
					status !== "cancelled" &&
					status !== "refuse" ? (
						<Fragment>
							<div style={{ justifyContent: "center", display: "flex" }}>
								{business.reschedule && (
									<Button
										className={classes.button}
										style={{ flex: 1, border: "solid 1px #393762" }}
										onClick={() => onBookingReschedule()}>
										<Typography className={classes.buttonName} style={{ color: "#393762" }}>
											{t("Reagendar")}
										</Typography>
									</Button>
								)}
								<Button
									className={classes.button}
									style={{ flex: 1, border: "solid 1px #ff3b30" }}
									onClick={cancel}>
									<Typography className={classes.buttonName} style={{ color: "#ff3b30" }}>
										{t("Cancelar")}
									</Typography>
								</Button>
							</div>
						</Fragment>
					) : null}
				</Fragment>
			) : (
				<Fragment>
					<div style={{ justifyContent: "center", display: "flex" }}>
						{status !== "cancelled" && status !== "refuse" && confirmFlag > 0 && business.reschedule && (
							<Button
								className={classes.button}
								style={{ flex: 1, border: "solid 1px #393762" }}
								onClick={() => onBookingReschedule()}>
								<Typography className={classes.buttonName} style={{ color: "#393762" }}>
									{t("Reagendar")}
								</Typography>
							</Button>
						)}
						{status !== "cancelled" && status !== "refuse" && confirmFlag > 0 && (
							<Button className={classes.button} style={{ flex: 1, border: "solid 1px #ff3b30" }} onClick={cancel}>
								<Typography className={classes.buttonName} style={{ color: "#ff3b30" }}>
									{t("Cancelar")}
								</Typography>
							</Button>
						)}
					</div>
				</Fragment>
			)}
			<Divider />
			<CardBusiness />
			<Divider style={{ marginTop: "8px" }} />
			<div style={{ textAlign: "center", display: "flex", paddingTop: "8px" }}>
				<Button
					className={classes.infor}
					style={{ width: "50%" }}
					onClick={() => {
						openMap();
					}}>
					<img
						style={{
							width: "40px",
							padding: "0 6px 6px",
						}}
						src={Location}
						alt=""
					/>
					<Typography className={classes.buttonName}>{t("see on map")}</Typography>
				</Button>
				<Divider orientation="vertical" flexItem />
				<Button className={classes.infor} style={{ width: "50%" }} onClick={() => call()}>
					<img
						style={{
							width: "30px",
							padding: "0 6px 6px",
						}}
						src={Telephone}
						alt=""
					/>
					<Typography className={classes.buttonName}>{t("To connect")}</Typography>
				</Button>
			</div>
			<ErrorDialog onOpen={openError} message={messageError} onOk={handleOk} />
			<DialogSucess
				onOpen={open}
				onClose={() => {
					setOpen(false);
					window.location = "/reservas/" + business.id;
				}}
				img={Sucess}
				message={t("Appointment Confirmed!")}
				PaperProps={{
					style: { borderRadius: 20, maxHeight: 270, minWidth: 270 },
				}}
			/>
			<DialogCancelConfirm
				onOpen={openCancel}
				onClose={onClose}
				setOpenCancel={setOpenCancel}
				bookingId={bookingId}
				date={date}
				dateEnd={dateEnd}
				serviceName={serviceName}
				avatarProfessional={avatarProfessional}
				price={price}
				createdAt={createdAt}
				date_booking_created={startTime}
				paymentCancellation={paymentCancellation}
				businessId={business.id}
				bookingTransactionId={bookingTransactionId}
				startTimeFormat={startTimeFormat}
				idGfi={idGfi}
			/>
			<DialogCancel
				onOpen={openNoRescheduling}
				onClose={() => {
					setLoading(false);
					setOpenNoRescheduling(false);
				}}
				img={Cancel}
				message={t("message rescheduling")}
				text={t("text rescheduling")}
			/>
			<DialogCancel
				onOpen={openExpiration}
				onClose={() => {
					setLoading(false);
					setOpenExpiration(false);
				}}
				img={Cancel}
				message={t("message expired")}
			/>
		</div>
	);
};

export default withRouter(ModalReservations);
