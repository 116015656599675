import { updateProfile } from "firebase/auth";
import _ from "lodash";
import { putUserUpdate } from "../../services/userUpdateService";
import { getAuth } from "firebase/auth";
import Constants from "~/helpers/enums/Constants";
import Utils from "~/helpers/Utils";
import {
	sendNumber,
	verifyCode,
	getUser,
	forgotPassword,
	sendEmailCreate,
	sendEmailVerify,
	loginEmail,
	sendSmsTwilio,
	sendSMSCodeTwilio,
	sendTokenFirebase,
	sendMsgWhatsApp,
	sendMessageCodeWhatsApp,
	sendMsgWavy,
	sendMessageWavy,
} from "~/services/loginService";

const setTelephone = (value) => (dispatch) => {
	dispatch({ type: Constants.SET_TELEPHONE, payload: { telephone: value } });
};

const setEmail = (value) => (dispatch) => {
	dispatch({ type: Constants.SET_EMAIL, payload: { email: value } });
};

const setName = (value) => (dispatch) => {
	dispatch({ type: Constants.SET_NAME, payload: { name: value } });
};

const sendNumberToAuthentication = (number, history, resend = false) => (dispatch) => {
	dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: true } });
	sendNumber(number)
		.then(function (confirmationResult) {
			dispatch({ type: Constants.GET_CONFIRMATION, payload: { confirmation: confirmationResult } });
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			if (confirmationResult && !resend) {
				history.push("/login/code");
			}
		})
		.catch(function (error) {
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			dispatch({
				type: Constants.LOGIN_DIALOG_ERROR,
				payload: {
					openError: true,
					messageError: Utils.fireBaseErrors(error.code),
					titleError: "Ocorreu um erro",
				},
			});
		});
};

const verifyCodeAuthentication = (confirmationResult, code, history, business, mixpanel, businessId) => (dispatch) => {
	dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: true } });
	verifyCode(confirmationResult, code)
		.then(function (result) {
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			getUser().then(function (data) {
				if (data.data) {
					localStorage.setItem("@prit-web/userId", data.data.id);
				}

				const payl = localStorage.getItem("@prit-web/payload");
				var payload = JSON.parse(payl);

				let checkoutTrue, couponId, bookingTransactionId;

				try {
					checkoutTrue = JSON.parse(localStorage.getItem("@prit-web/checkoutTrue"));
				} catch {
					checkoutTrue = false;
					localStorage.setItem("@prit-web/checkoutTrue", false);
				}

				try {
					couponId = JSON.parse(localStorage.getItem("@prit-web/coupon"));
				} catch {
					couponId = false;
					localStorage.setItem("@prit-web/coupon", false);
				}

				try {
					bookingTransactionId = JSON.parse(localStorage.getItem("@prit-web/bookingTransactionId"));
				} catch {
					bookingTransactionId = false;
					localStorage.setItem("@prit-web/bookingTransactionId", false);
				}

				if (_.isNil(business)) {
					history.push(`/business/${businessId}`);
					return;
				} else if (result && !result.user.displayName) {
					history.push("/login/personal-information", { bookingTransactionId });
					mixpanel.track("Dados pessoais-web", {
						my_custom_prop: "foo",
					});
					return;
				} else if (result && result.user.displayName && business) {
					if (bookingTransactionId) {
						history.push(`/business/${business?.id}/bookingTransactionId/${bookingTransactionId}`);
						return;
					}

					if (checkoutTrue) {
						history.push(`/checkout/${business?.id}`, { payload, verification: true, valueUTC: true });
						mixpanel.track("Tela de checkout-web", {
							my_custom_prop: "foo",
						});
						return;
					}

					if (couponId) {
						history.push(`/notificacoes/${business?.id}/bookingTransactionId/${couponId}`);
						return;
					}
				} else {
					history.push(`/reservas/${business?.id}`, { payload: payload, noEmail: true });
					return;
				}
			});
		})
		.catch(function (error) {
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			dispatch({
				type: Constants.LOGIN_DIALOG_ERROR,
				payload: {
					openError: true,
					messageError: Utils.fireBaseErrors(error.code),
					titleError: "Ocorreu um erro",
				},
			});
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
		});
};

const cleanLoginReducer = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_LOGIN_REDUCER });
};

const cleanLoginEmail = () => (dispatch) => {
	dispatch({ type: Constants.CLEAN_LOGIN_EMAIL_REDUCER });
};

const setOpenDialog = (value) => (dispatch) => {
	dispatch({ type: Constants.LOGIN_DIALOG, payload: { open: value } });
};

const setOpenDialogError = (value) => (dispatch) => {
	dispatch({ type: Constants.LOGIN_DIALOG_ERROR, payload: { openError: value } });
};

const setTelephoneWithCountryCode = (value) => (dispatch) => {
	dispatch({ type: Constants.TELEPHONE_COUNTRY_CODE, payload: { telephoneWithCountryCode: value } });
};

const sendEmailToCreateAuthentication = (email, password, name, phone) => (dispatch) => {
	dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: true } });
	sendEmailCreate(email, password)
		.then((user) => {
			updateProfile(user.user, { displayName: name });
			sendEmailVerify(user.user)
				.then((result) => {
					dispatch({
						type: Constants.LOGIN_DIALOG,
						payload: {
							open: true,
							title: "Atenção",
							message: "E-mail enviado para confirmação de login, valide antes de tentar entrar.",
						},
					});
				})
				.catch(function (error) {
					dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
					dispatch({
						type: Constants.LOGIN_DIALOG_ERROR,
						payload: {
							openError: true,
							messageError: Utils.fireBaseErrors(error.code),
							titleError: "Ocorreu um erro",
						},
					});
				});
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
		})
		.catch(function (error) {
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			dispatch({
				type: Constants.LOGIN_DIALOG_ERROR,
				payload: {
					openError: true,
					messageError: Utils.fireBaseErrors(error.code),
					titleError: "Ocorreu um erro",
				},
			});
		});
};

const loginEmailToAuthentication = (email, password, business, history) => (dispatch) => {
	dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: true } });
	loginEmail(email, password)
		.then((user) => {
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			if (!user.user.emailVerified) {
				dispatch({
					type: Constants.LOGIN_DIALOG_ERROR,
					payload: {
						openError: true,
						messageError: "E-mail não verificado, enviamos uma validação ao seu e-mail.",
						titleError: "Ocorreu um erro",
					},
				});
				sendEmailVerify(user.user)
					.then((result) => {})
					.catch(function (error) {
						dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
						dispatch({
							type: Constants.LOGIN_DIALOG_ERROR,
							payload: {
								openError: true,
								messageError: Utils.fireBaseErrors(error.code),
								titleError: "Ocorreu um erro",
							},
						});
					});
			} else {
				getUser().then(function (data) {
					if (data.data) {
						localStorage.setItem("@prit-web/userId", data.data.id);
					}

					const checkoutTrue = localStorage.getItem("@prit-web/checkoutTrue");
					const bookingTransactionId = localStorage.getItem("@prit-web/bookingTransactionId");

					const payl = localStorage.getItem("@prit-web/payload");
					var payload = JSON.parse(payl);

					if (bookingTransactionId && business && checkoutTrue === "undefined") {
						localStorage.setItem("@prit-web/bookingTransactionId", undefined);
						history.push(`/business/${business.id}/bookingTransactionId/${bookingTransactionId}`);
						return;
					} else if (checkoutTrue === "true" && business) {
						history.push(`/checkout/${business?.id}`, { payload, verification: true, valueUTC: true });
					}
				});
			}
		})
		.catch(function (error) {
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			dispatch({
				type: Constants.LOGIN_DIALOG_ERROR,
				payload: {
					openError: true,
					messageError: Utils.fireBaseErrors(error.code),
					titleError: "Ocorreu um erro",
				},
			});
		});
};

const forgotLoginPassword = (email) => (dispatch) => {
	dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: true } });
	forgotPassword(email)
		.then((result) => {
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			dispatch({
				type: Constants.LOGIN_DIALOG,
				payload: {
					open: true,
					message: "E-mail enviado para troca de senha",
					title: "Atenção",
				},
			});
		})
		.catch(function (error) {
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			dispatch({
				type: Constants.LOGIN_DIALOG_ERROR,
				payload: {
					openError: true,
					messageError: Utils.fireBaseErrors(error.code),
					titleError: "Ocorreu um erro",
				},
			});
		});
};

// Login com SMS Twilio

const smsTwilio = (celphone, isWhatsApp = false) => (dispatch) => {
	dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: true } });
	sendSmsTwilio(celphone, isWhatsApp)
		.then((result) => {
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
		})
		.catch(function (error) {
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			dispatch({
				type: Constants.LOGIN_DIALOG_ERROR,
				payload: {
					openError: true,
					messageError: error?.response?.data?.message || error?.message,
					titleError: "Ocorreu um erro",
				},
			});
		});
};

const smsCodeTwilio = (codeTwilio, celphone, history, business, mixpanel, businessId) => (dispatch) => {
	dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: true } });
	sendSMSCodeTwilio(celphone, codeTwilio)
		.then((result) => {
			sendTokenFirebase(result.data.message).then((response) => {
				getUser().then(function (data) {
					if (data.data) {
						localStorage.setItem("@prit-web/userId", data.data.id);
					}
					const payl = localStorage.getItem("@prit-web/payload");
					var payload = JSON.parse(payl);

					const checkoutTrue = localStorage.getItem("@prit-web/checkoutTrue");
					const couponId = localStorage.getItem("@prit-web/coupon");
					const bookingTransactionId = localStorage.getItem("@prit-web/bookingTransactionId");

					if (_.isNil(business)) {
						history.push(`/business/${businessId}`);
						return;
					} else if (data && data.data && !data.data.name) {
						history.push("/login/personal-information", { bookingTransactionId });
						mixpanel.track("Dados pessoais-web", {
							my_custom_prop: "foo",
						});
						return;
					} else if (data && data.data && data.data.name && business) {
						if (bookingTransactionId) {
							history.push(`/business/${business?.id}/bookingTransactionId/${bookingTransactionId}`);
							localStorage.setItem("@prit-web/bookingTransactionId", undefined);
							return;
						}

						if (checkoutTrue) {
							history.push(`/checkout/${business?.id}`, { payload, verification: true, valueUTC: true });
							localStorage.setItem("@prit-web/checkoutTrue", undefined);
							mixpanel.track("Tela de checkout-web", {
								my_custom_prop: "foo",
							});
							return;
						}

						if (couponId) {
							history.push(`/notificacoes/${business?.id}/bookingTransactionId/${couponId}`);
							localStorage.setItem("@prit-web/coupon", undefined);
							return;
						}

						history.push(`/confirmation/${business?.id}`, { payload: payload, noEmail: true });
						return;
					} else {
						history.push(`/reservas/${business?.id}`, { payload: payload, noEmail: true });
						return;
					}
				});
			});
		})
		.catch(function (error) {
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			dispatch({
				type: Constants.LOGIN_DIALOG_ERROR,
				payload: {
					openError: true,
					messageError: "Código inválido, verifique o SMS e tente novamente!",
					titleError: "Ocorreu um erro",
				},
			});
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
		})
		.catch(function (err) {
			let message = err?.response?.data?.message;
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			dispatch({
				type: Constants.LOGIN_DIALOG_ERROR,
				payload: {
					openError: true,
					messageError: "Código inválido, verifique o SMS e tente novamente!",
					titleError: "Ocorreu um erro",
				},
			});
		});
};

// WhatsApp Actions

const messageWhatsApp = (number, history, isFirebase) => (dispatch) => {
	/*dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: true } });
	sendMsgWhatsApp(number)
		.then(function (confirmationResult) {
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			history.push("/login/code");
		})
		.catch(function (err) {
			let { message } = err;
			if (err.response) {
				if (err.response.data && err.response.data.message) {
					({ message } = err.response.data);
				}
			}
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			dispatch(sendNumberToAuthentication(number, history));
			/*dispatch({
				type: Constants.LOGIN_DIALOG_ERROR, payload: {
					openError: true, messageError: message, titleError: "Ocorreu um erro",
				}
			});*/
	//});*/
	dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
	if (isFirebase) {
		dispatch(sendNumberToAuthentication(number, history));
	} else {
		dispatch(messageWavy(number, history));
	}
};

const messageCodeWhatsApp = (codeWhatsApp, celphone, history, business, mixpanel) => (dispatch) => {
	dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: true } });
	sendMessageCodeWhatsApp(celphone, codeWhatsApp)
		.then((result) => {
			sendTokenFirebase(result.data.message).then((response) => {
				getUser().then(function (data) {
					if (data.data) {
						localStorage.setItem("@prit-web/userId", data.data.id);
					}
					const payl = localStorage.getItem("@prit-web/payload");
					var payload = JSON.parse(payl);

					const checkoutTrue = JSON.parse(localStorage.getItem("@prit-web/checkoutTrue"));
					const couponId = JSON.parse(localStorage.getItem("@prit-web/coupon"));
					const bookingTransactionId = JSON.parse(localStorage.getItem("@prit-web/bookingTransactionId"));

					if (data && data.data && !data.data.name) {
						history.push("/login/personal-information", { bookingTransactionId });
						mixpanel.track("Dados pessoais-web", {
							my_custom_prop: "foo",
						});
						return;
					} else if (data && data.data && data.data.name && business) {
						if (bookingTransactionId) {
							history.push(`/business/${business?.id}/bookingTransactionId/${bookingTransactionId}`);
							localStorage.setItem("@prit-web/bookingTransactionId", undefined);
							return;
						}

						if (checkoutTrue) {
							history.push(`/checkout/${business?.id}`, { payload, verification: true, valueUTC: true });
							localStorage.setItem("@prit-web/checkoutTrue", undefined);
							mixpanel.track("Tela de checkout-web", {
								my_custom_prop: "foo",
							});
							return;
						}

						if (couponId) {
							history.push(`/notificacoes/${business?.id}/bookingTransactionId/${couponId}`);
							localStorage.setItem("@prit-web/coupon", undefined);
							return;
						}

						history.push(`/confirmation/${business?.id}`, { payload: payload, noEmail: true });
						return;
					}

					history.push(`/reservas/${business?.id}`, { payload: payload, noEmail: true });
					return;
				});
			});
		})
		.catch(function (error) {
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			dispatch({
				type: Constants.LOGIN_DIALOG_ERROR,
				payload: {
					openError: true,
					messageError: error?.response?.data?.message || error?.message,
					titleError: "Ocorreu um erro",
				},
			});
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
		})
		.catch(function (err) {
			let message = err?.response?.data?.message;
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			dispatch({
				type: Constants.LOGIN_DIALOG_ERROR,
				payload: {
					openError: true,
					messageError: message,
					titleError: "Ocorreu um erro",
				},
			});
		});
};

const messageWavy = (number, history, resend = false) => (dispatch) => {
	dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: true } });
	sendMsgWavy(number)
		.then(function (confirmationResult) {
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			history.push("/login/code");
		})
		.catch(function (err) {
			dispatch(smsTwilio(number, true));
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			history.push("/login/code");
			/*dispatch(sendNumberToAuthentication(number, history));
			dispatch({
				type: Constants.LOGIN_DIALOG_ERROR, payload: {
					openError: true, messageError: message, titleError: "Ocorreu um erro",
				}
			});*/
		});
	dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
	//dispatch(sendNumberToAuthentication(number, history));
};

const messageCodeWavy = (code, celphone, history, business, mixpanel, businessId) => (dispatch) => {
	dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: true } });
	sendMessageWavy(celphone, code)
		.then((result) => {
			sendTokenFirebase(result.data.message).then(async (response) => {
				await getUser().then(function (data) {
					if (data.data) {
						localStorage.setItem("@prit-web/userId", data.data.id);
					}
					const payl = localStorage.getItem("@prit-web/payload");
					var payload = JSON.parse(payl);

					const checkoutTrue = JSON.parse(localStorage.getItem("@prit-web/checkoutTrue"));
					const couponId = JSON.parse(localStorage.getItem("@prit-web/coupon"));
					const bookingTransactionId = localStorage.getItem("@prit-web/bookingTransactionId");
					const vendorPage = localStorage.getItem("@prit-web/vendorPage");
					const isComingFromIndications = localStorage.getItem("isCommingFromIndications");

					if (isComingFromIndications === "true") {
						localStorage.removeItem("isCommingFromIndications");
						history.push("/indicacoes");
						return;
					}

					if (_.isNil(business)) {
						history.push(`/business/${businessId}`, { id: businessId });
						return;
					} else if (data && data.data && !data.data.name) {
						history.push("/login/personal-information", { bookingTransactionId });
						mixpanel.track("Dados pessoais-web", {
							my_custom_prop: "foo",
						});
						return;
					} else if (data && data.data && data.data.name && business) {
						if (bookingTransactionId) {
							history.push(`/business/${business?.id}/bookingTransactionId/${bookingTransactionId}`);
							localStorage.removeItem("@prit-web/bookingTransactionId");
							return;
						}

						if (checkoutTrue) {
							history.push(`/checkout/${business?.id}`, { payload, verification: true, valueUTC: true });
							localStorage.removeItem("@prit-web/checkoutTrue");
							mixpanel.track("Tela de checkout-web", {
								my_custom_prop: "foo",
							});
							return;
						}

						if (couponId) {
							history.push(`/notificacoes/${business?.id}/bookingTransactionId/${couponId}`);
							localStorage.removeItem("@prit-web/coupon");
							return;
						}
						if (vendorPage) {
							history.push(`/business/${business?.id}`);
							localStorage.removeItem("@prit-web/vendorPage");
							return;
						}

						history.push(`/confirmation/${business?.id}`, { payload: payload, noEmail: true });
						return;
					} else {
						history.push(`/reservas/${business?.id}`, { payload: payload, noEmail: true });
						return;
					}
				});
			});
		})
		.catch(function (err) {
			dispatch(smsCodeTwilio(code, celphone, history, business, mixpanel, businessId));
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
		})
		.catch(function (err) {
			let message = err?.response?.data?.message;
			dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
			dispatch({
				type: Constants.LOGIN_DIALOG_ERROR,
				payload: {
					openError: true,
					messageError: "Código inválido, verifique o SMS e tente novamente!",
					titleError: "Ocorreu um erro",
				},
			});
		});
};

export const handleGoogleLogin = (firebaseToken, businessId, business) => async (dispatch) => {
	dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: true } });
  
	try {
	  const userInfo = await getUser();
	  localStorage.setItem("@prit-web/meData", JSON.stringify(userInfo?.data));
  
	  dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } }); 
	} catch (error) {
	  dispatch({ type: Constants.LOADING_AUTHENTICATION, payload: { loading: false } });
	  console.error('Error fetching user information:', error);
	}
};

export default {
	setTelephone,
	setEmail,
	setName,
	sendNumberToAuthentication,
	verifyCodeAuthentication,
	cleanLoginReducer,
	cleanLoginEmail,
	setOpenDialog,
	setOpenDialogError,
	setTelephoneWithCountryCode,
	sendEmailToCreateAuthentication,
	loginEmailToAuthentication,
	forgotLoginPassword,
	smsTwilio,
	smsCodeTwilio,
	messageWhatsApp,
	messageCodeWhatsApp,
	messageWavy,
	messageCodeWavy,
	handleGoogleLogin,
};
